import React from "react";
import Spikes from "../services/Spikes";
import BaseApi from "../services/BaseApi";

class PrePopulateFrame extends React.Component {
    componentDidMount() {
        let q = Spikes.queue();
        q.add("users", cb => BaseApi.general.listUsers(rx => cb(rx)));
        q.add("configs", cb => BaseApi.general.configs(rx => cb(rx)));
        q.execute(rx => {
            if (rx.users.success) {BaseApi.data.users = rx.users.records;}
            if (rx.configs.success) {BaseApi.data.configs = rx.configs.records;}
            if (typeof(this.props.onComplete) === "function") {this.props.onComplete();}
        });
    }
    render() {return <img src="/images/loading_rs2.gif" className="center-screen" alt="Loading..." />;}
}
export default PrePopulateFrame;