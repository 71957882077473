/* eslint eqeqeq: 0 */
import Spikes from "./Spikes";

const CsvHandler = {
    upload: callback => {
        if (window.FileReader) {
            let uldr = document.createElement("input");
            uldr.type = "file";
            uldr.accept = "text/csv";
            uldr.onchange = event => {
                Spikes.first(Object.values(event.target.files))(
                    file => {
                        const readFile = new FileReader();
                        readFile.onload = fData => {
                            callback(true, {
                                name: file.name,
                                size: file.size,
                                type: file.type,
                                lastModified: file.lastModified,
                                dateUploaded: new Date().getTime(),
                                data: fData.target.result
                            });
                            setTimeout(() => uldr.remove());
                        };
                        readFile.readAsText(file);
                    }, 
                    () => {
                        callback(false);
                        setTimeout(() => uldr.remove());
                    }
                );
            };
            document.body.appendChild(uldr);
            setTimeout(() => {
                uldr.click();
                uldr.style.display = "none";
            });
        } else {
            callback(false);
        }
    },
    fromCSV: text => {
        text = text.split("\r\n").join("\n");
        text = text.split("\r").join("\n");
        let rows = [];
        let row = [];
        let v = "";
        let s = false;
        let q = false;
        text.split("").forEach(c => {
            if (s) {
                v += c;
                s = false;
            } else {
                switch (c) {
                    case ",":
                        if (q) {
                            v += c;
                        } else {
                            row.push(v.trim());
                            v = "";
                        }
                        break;
                    case "\"":
                        q = !q;
                        break;
                    case "\\":
                        s = true;
                        break;
                    case "\n":
                        if (q) {
                            v += "\r\n";
                        } else {
                            row.push(v.trim());
                            rows.push(row);
                            row = [];
                            v = "";
                        }
                        break;
                    default: 
                        v += c;
                }
            }
        });
        row.push(v.trim());
        if (row.length > 1 || v.trim() !== "") {rows.push(row);}
        rows = rows.filter(iRow => iRow.map(iCol => typeof(iCol) === "string" ? iCol : "").join("").trim() !== "");
        if (rows.length > 0) {
            let headers = rows[0];
            let content = [];
            if (rows.length > 1) {
                rows.slice(1, rows.length).forEach(iRow => {
                    let sRow = {};
                    iRow.forEach((iCol, index) => {
                        sRow[headers[index].toLowerCase()] = iCol;
                    });
                    content.push(sRow);
                });
            }
            return content;
        }
        return [];
    }
};
export default CsvHandler;