import React from "react";
import Spikes from "../services/Spikes";
import siteState from "../services/siteState";
import Icon from "./Icon";

const ModalFrame = props => {
    return <div
        style={{
            position: "fixed",
            top: props.top || "50%",
            left: props.left || "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "var(--color-1)",
            borderStyle: "solid",
            borderWidth: "2px",
            borderColor: "var(--color-1)",
            borderRadius: "10px 10px 4px 4px",
            zIndex: ++siteState.topIndex,
            boxShadow: "3px 3px 3px var(--theme-lt-shadow-color)"
        }}
    >
        <div
            onMouseDown={e => Spikes.move(e, e.target.parentNode, props.contain || {top: 41, bottom: 25})}
            style={{
                padding: "3px",
                cursor: "move",
                color: "var(--color-6)",
                borderRadius: "9px 9px 0px 0px",
                backgroundImage: "linear-gradient(var(--color-1), var(--theme-lt-border-color))",
                width: props.width || "400px",
            }}
        ><span style={{pointerEvents: "none"}}>{props.children[0]?.props.children}</span><Icon name="cancel" size="20px" style={{float: "right", cursor: "pointer"}} onClick={() => typeof(props.onClose) === "function" ? props.onClose() : undefined} /></div>
        <div style={{backgroundColor: "var(--color-6)"}}>{props.children.slice(1)}</div>
    </div>;
};
export default ModalFrame;