import React from "react";
import BaseApi from "../../services/BaseApi";
import BaseData from "../../services/BaseData";
import Spikes from "../../services/Spikes";

class FooterFrame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: BaseApi.user.info().firstname,
            lastname: BaseApi.user.info().lastname,
            aliveCount: 0
        };
    }

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    componentDidMount() {
        this.wHolder(BaseData.alives(alives => this.setVal({aliveCount: alives.length})));
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    render() {
        return (
            <div>
                <div className="footer-frame-left">
                    <span style={{display: "inline-block", verticalAlign: "middle"}}>{this.state.firstname} {this.state.lastname}</span>
                </div>
                <div className="footer-frame-right">
                    <span style={{display: "inline-block", verticalAlign: "middle"}}>Active Site Users: {this.state.aliveCount}</span>
                </div>
            </div>
        );
    }
}

export default FooterFrame;