import React from "react";
import Icon from "../../directives/Icon";
import Spikes from "../../services/Spikes";
import BaseData from "../../services/BaseData";
import TableView from "../../directives/TableView";

export default class VehiclePanelElement extends React.Component {
    state = {
        vehicle: false,
        isMobile: this.props.isMobile
    };

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    routines = {
        asBoolean: value => (value === true && "True") || (value === false && "False") || "N/A"
    };

    componentDidMount() {
        this.wHolder(Spikes.watch("isMobile")(isMobile => this.setVal({isMobile: isMobile})));
        this.wHolder(BaseData.vehicle(this.props.vehicleId, vehicle => this.setVal({vehicle: vehicle})));
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    render() {
        let { vehicle } = this.state;
        if (!vehicle) {return null;}
        return <div>
            <TableView.Header><Icon name="car" />{this.props.title || "Vehicle"}</TableView.Header>
            <TableView.Options>
                <div className="h3">{([vehicle.features?.year, vehicle.features?.make, vehicle.features?.model, vehicle.features?.trim]).filter(vf => vf).join(" ") || "Please populate vehicle features"}{vehicle.features?.body_type ? ` (${vehicle.features.body_type})` : ""}</div>
            </TableView.Options>
            <TableView.Body>
                {this.state.isMobile ? <TableView.Table>
                    <TableView.Column colSpan="6">
                        <TableView.FieldView title="VIN" content={vehicle.details?.vin || "N/A"} />
                        <TableView.FieldView title="Kilometers" content={Spikes.numberCS(vehicle.details?.kilometers, 0) || "N/A"} />
                        <TableView.FieldView title="Price" content={vehicle.details?.price ? "$" + Spikes.numberCS(vehicle.details?.price, 2) : "N/A"} />
                        <TableView.FieldView title="Drive Type" content={vehicle.features?.drive_type || "N/A"} />
                        <TableView.FieldView title="# Of Doors" content={vehicle.features?.doors || "N/A"} />
                        <TableView.FieldView title="Air Conditioning" content={this.routines.asBoolean(vehicle.features?.air_conditioning)} />
                    </TableView.Column>
                    <TableView.Column colSpan="6">
                        <TableView.FieldView title="Power Windows" content={this.routines.asBoolean(vehicle.features?.power_windows)} />
                        <TableView.FieldView title="Power Doorlocks" content={this.routines.asBoolean(vehicle.features?.power_doorlocks)} />
                        <TableView.FieldView title="Power Mirrors" content={this.routines.asBoolean(vehicle.features?.power_mirrors)} />
                        <TableView.FieldView title="Camera (Rearview)" content={this.routines.asBoolean(vehicle.features?.camera_rearview)} />
                        <TableView.FieldView title="Hill Hold" content={this.routines.asBoolean(vehicle.features?.control_hill_hold)} />
                        <TableView.FieldView title="Traction Control" content={this.routines.asBoolean(vehicle.features?.control_traction)} />
                    </TableView.Column>
                </TableView.Table> : <TableView.Table>
                    <TableView.Column colSpan="3">
                        <TableView.FieldView title="VIN" content={vehicle.details?.vin || "N/A"} />
                        <TableView.FieldView title="Kilometers" content={Spikes.numberCS(vehicle.details?.kilometers, 0) || "N/A"} />
                        <TableView.FieldView title="Price" content={vehicle.details?.price ? "$" + Spikes.numberCS(vehicle.details?.price, 2) : "N/A"} />
                    </TableView.Column>
                    <TableView.Column colSpan="3">
                        <TableView.FieldView title="Drive Type" content={vehicle.features?.drive_type || "N/A"} />
                        <TableView.FieldView title="# Of Doors" content={vehicle.features?.doors || "N/A"} />
                        <TableView.FieldView title="Air Conditioning" content={this.routines.asBoolean(vehicle.features?.air_conditioning)} />
                    </TableView.Column>
                    <TableView.Column colSpan="3">
                        <TableView.FieldView title="Power Windows" content={this.routines.asBoolean(vehicle.features?.power_windows)} />
                        <TableView.FieldView title="Power Doorlocks" content={this.routines.asBoolean(vehicle.features?.power_doorlocks)} />
                        <TableView.FieldView title="Power Mirrors" content={this.routines.asBoolean(vehicle.features?.power_mirrors)} />
                    </TableView.Column>
                    <TableView.Column colSpan="3">
                        <TableView.FieldView title="Camera (Rearview)" content={this.routines.asBoolean(vehicle.features?.camera_rearview)} />
                        <TableView.FieldView title="Hill Hold" content={this.routines.asBoolean(vehicle.features?.control_hill_hold)} />
                        <TableView.FieldView title="Traction Control" content={this.routines.asBoolean(vehicle.features?.control_traction)} />
                    </TableView.Column>
                </TableView.Table>}
                <TableView.FieldContent style={{whiteSpace: "nowrap", overflow: "auto"}}>
                    {(vehicle.images || []).map(a => <img
                        key={a.url}
                        src={a.url}
                        alt={a.filename}
                        title={a.caption}
                        style={{height: "200px", width: "auto", cursor: "pointer"}}
                        onClick={() => Spikes.notify("preview", a.url)}
                        draggable="true"
                        onDragStart={e => Spikes.drag.onDrag(e, "vehicleImagePanel")}
                        onDragEnd={e => Spikes.drag.onEnd(e)}
                    />)}
                </TableView.FieldContent>
            </TableView.Body>
        </div>;
    }
}