import React from "react";
import Spikes from "../services/Spikes";

const styles = {
    panelInfo: {
        margin: "10px 10px 0px 10px",
        padding: "5px",
        borderRadius: "10px 10px 10px 10px",
        borderStyle: "solid",
        borderColor: "#666666",
        color: "#666666",
        borderWidth: "1px 1px 1px 1px",
        backgroundColor: "var(--input-background-color)",
        fontFamily: "federationregular",
        fontSize: "16px"
    },
    panel: {
        header: {
            margin: "10px 10px 0px 10px",
            padding: "5px",
            borderRadius: "10px 10px 0px 0px",
            borderStyle: "solid",
            borderColor: "var(--theme-lt-border-color)",
            borderWidth: "1px 1px 0px 1px",
            backgroundImage: "linear-gradient(var(--theme-lt-active-color), var(--theme-lt-border-color))",
            color: "var(--theme-lt-background-color)",
            fontFamily: "federationregular",
            fontSize: "16px"
        },
        options: {
            margin: "0px 10px 0px 10px",
            padding: "0px 5px 5px 5px",
            borderRadius: "0px",
            borderStyle: "solid",
            borderColor: "var(--theme-lt-border-color)",
            borderWidth: "0px 1px 0px 1px",
            backgroundColor: "var(--theme-lt-border-color)",
            color: "var(--theme-lt-background-color)",
            fontFamily: "federationregular",
            fontSize: "16px"
        },
        body: {
            margin: "0px 10px 10px 10px",
            padding: "5px",
            borderRadius: "0px 0px 10px 10px",
            borderStyle: "solid",
            borderColor: "var(--theme-lt-border-color)",
            borderWidth: "0px 1px 1px 1px",
            minHeight: "10px",
            backgroundColor: "var(--input-background-color)"
        }
    },
    panelTable: {
        display: "table",
        width: "100%",
        tableLayout: "fixed"
    },
    panelCell: {
        display: "inline-block",
        verticalAlign: "top"
    },
    fieldView: {
        title: {
            padding: "5px 5px 0px 5px",
            fontSize: "12px",
            fontFamily: "acari_sansregular",
            color: "#666666"
        },
        content: {
            padding: "0px 15px 5px 15px",
            fontSize: "16px",
            fontFamily: "'Courier New', Courier, monospace",
            overflow: "hidden"
        }
    },
    rowView: {
        container: {
            display: "flex",
            justifyContent: "space-between"
        },
        title: {
            padding: "5px",
            fontSize: "14px",
            fontFamily: "acari_sansregular",
            color: "#666666",
            lineHeight: "30px",
            verticalAlign: "middle"
        },
        content: {
            lineHeight: "30px",
            verticalAlign: "middle",
            padding: "5px",
        }
    },
    cols: {
        col_1: {width: "8.3%"},
        col_2: {width: "16.6%"},
        col_3: {width: "25%"},
        col_4: {width: "33.3%"},
        col_5: {width: "41.6%"},
        col_6: {width: "50%"},
        col_7: {width: "58.3%"},
        col_8: {width: "66.6%"},
        col_9: {width: "75%"},
        col_10: {width: "83.3%"},
        col_11: {width: "91.6%"},
        col_12: {width: "100%"}
    }
};

const TableView = {
    Info: props => <div style={styles.panelInfo}>{props.children}</div>,
    Header: props => <div style={Spikes.fuseObj(styles.panel.header, props.style)}>{props.children}</div>,
    Options: props => <div style={styles.panel.options}>{props.children}</div>,
    Body: React.forwardRef((props, ref) => <div ref={ref} style={Spikes.fuseObj(styles.panel.body, props.style)}>{props.children}</div>),
    Table: props => <div style={Spikes.fuseObj(styles.panelTable, props.style)}>{props.children}</div>,
    Column: props => <div style={Spikes.fuseObj(styles.panelCell, styles.cols["col_" + props.colSpan], props.style)}>{props.children}</div>,
    FieldTitle: props => <div style={styles.fieldView.title} onClick={props.onClick}>{props.title || props.children}</div>,
    FieldContent: props => <div style={Spikes.fuseObj(styles.fieldView.content, props.style)} onClick={props.onClick}>{props.children}</div>,
    FieldView: props => <>
        <div style={styles.fieldView.title}>{props.title}</div>
        <div  style={Spikes.fuseObj(styles.fieldView.content, props.style)}>{props.content || props.children}</div>
    </>,
    RowView: props => <div style={styles.rowView.container}><span style={styles.rowView.title}>{props.title}</span><span style={Spikes.fuseObj(styles.rowView.content, props.style)}>{props.content || props.children}</span></div>
};
export default TableView;