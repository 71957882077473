import React from "react";
import Icon from "./Icon";
import Spikes from "../services/Spikes";

class SwitchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? true : false,
            over: false
        };
    }

    setVal = Spikes.stateStream(this);

    routines = {
        click: () => {
            this.setVal({
                value: !this.state.value
            }, () => {
                if (typeof(this.props.onChange) === "function") {
                    this.props.onChange(this.state.value);
                }
            });
        }
    };

    render() {
        let style= {
            display: "inline-table",
            height: `${this.props.size || 32}px`,
            verticalAlign: "middle",
            cursor: "pointer",
            borderColor: this.props.borderColor || "#CCCCCC"
        };
        Object.keys(this.props.style || {}).forEach(key => {style[key] = this.props.style[key];});
        return (
            <span 
                style={style}
                title={this.props.title}
                onClick={() => this.routines.click()}
                onMouseOver={() => this.setVal({over: true})}
                onMouseOut={() => this.setVal({over: false})}
            >{
                this.props.leftLabel && <span style={{display: "table-cell", paddingRight: "4px", height: `${(this.props.size || 32) - 2}px`, verticalAlign: "middle"}}>{this.props.leftLabel}</span>
            }<span
                style={{display: "flex"}}
            ><span 
                style={{
                    display: "inline-block",
                    minWidth: `${(this.props.size || 32) * 2 - 2}px`,
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: "4px",
                    borderColor: this.props.borderColor || "inherit",
                    backgroundColor: this.state.over ? "#CCCCCC" : "transparent",
                    padding: "0px",
                    color: "#FFFFFF"
                }}
            ><span
                style={{
                    display: "block",
                    width: `${(this.props.size || 32) - 2}px`,
                    height: `${(this.props.size || 32) - 2}px`,
                    textAlign: "center",
                    borderStyle: "solid",
                    borderColor: "inherit",
                    borderWidth: this.state.value ? "0px 0px 0px 1px" : "0px 1px 0px 0px",
                    borderRadius: "4px",
                    marginLeft: this.state.value ? "auto" : "0px",
                    backgroundColor: this.state.value ? "#00CC00" : "#FF0000",
                    padding: "0px",
                    overflow: "hidden",
                    color: "inherit"
                }}
            ><Icon 
                name={this.state.value ? "check" : "cancel"} 
                size={(this.props.size || 32) - 8} 
                margin="0" 
                style={{marginTop: "3px", verticalAlign: "top"}}
            /></span></span></span>{this.props.rightLabel && <span 
                style={{display: "table-cell", paddingLeft: "4px", height: `${(this.props.size || 32) - 2}px`, verticalAlign: "middle"}}
            >{this.props.rightLabel}</span>}</span>
        );
    }
}
export default SwitchBox;