import React from "react";
import TableView from "../../directives/TableView";
import Spikes from "../../services/Spikes";

export default class OverviewPage extends React.Component {
    componentDidMount() {
        setTimeout(() => window.scrollTo(0, 0));
        setTimeout(() => Spikes.notify("title", "chart", "Overview"));
    }
    render = () => <div>
        <TableView.Info><div className="h2">Overview - Statistics service is currently in Offline mode.</div></TableView.Info>
    </div>;
}