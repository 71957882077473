const flatVehicle = s => {    
    let results = {
        flat: true,
        id: s.id,
        tag: s.tag || null,
        likedCount: s.liked ? Object.values(s.liked).length : 0,
        visible: s.visible ? true : false
    };
    if (s.stats) {
        results.views = s.stats.views || 0;
    }
    if (s.dealer) {
        results.dealerId = s.dealer.dealerId;
    }
    if (s.features) {
        results.year = s.features.year || null;
        results.make = s.features.make || null;
        results.model = s.features.model || null;
        results.trim = s.features.trim || null;
        results.body_type = s.features.body_type || null;
    }
    if (s.images) {
        results.images = s.images.slice(0, 3);
        results.imageCount = s.images.length;
    }
    if (s.details) {
        results.exterior_color = s.details.exterior_color || null;
        results.interior_color = s.details.interior_color || null;
        results.vin = s.details.vin || null;
        results.price = s.details.price || null;
        results.kilometers = s.details.kilometers || null;
        results.certification = s.details.certification || null;
    }
    return results;
};
export default flatVehicle;