import React from "react";
import Spikes from "../../services/Spikes";
import BreakSearch from "../../directives/BreakSearch";
import VehiclesTable from "./VehiclesTable";
import SwitchBox from "../../directives/SwitchBox";
import BaseData from "../../services/BaseData";
import flatVehicle from "./flatVehicle";

export default class VehiclesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicles: false,
            listView: false,
            tableUpdate: 0,
            visibleOnly: false,
            search: () => true,
            isMobile: props.isMobile,
            showTags: false
        };
    }

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    routines = {
        search: () => this.setVal({search: this.search}),
        count: arr => {
            setTimeout(() => Spikes.notify("title", "car", "Vehicles Library", `Visible: ${arr.filter(v => v.visible).length} | Total: ${arr.length}`));
            return arr;
        }
    };

    componentDidMount() {
        setTimeout(() => window.scrollTo(0, 0));
        setTimeout(() => Spikes.notify("title", "car", "Vehicles Library", "Loading..."));
        this.wHolder(Spikes.watch("isMobile")(isMobile => this.setVal({isMobile: isMobile})));
        this.wHolder(Spikes.watch("vehicleCount")(count => {
            setTimeout(() => Spikes.notify("title", "car", "Vehicles Library", `Visible: ${count.visible} | Total: ${count.total}`));
        }));
        this.wHolder(BaseData.vehicles(vehicles => {
            this.setVal({vehicles: vehicles.map(flatVehicle)});
        }));
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    desktop = () => <div>
        <div className="optionsbar">
            <BreakSearch
                name="vehicles"
                filters={{
                    "ID": "id",
                    "VIN": "vin",
                    "Year": "year",
                    "Make": "make",
                    "Model": "model",
                    "Trim": "trim",
                    "B. Type": "body_type",
                    "Ext. Color": "exterior_color",
                    "Int. Color": "interior_color",
                    "Tag": "tag",
                    "Visible": "visible",
                    "Certification": "certification",
                    "Dealer ID": "dealerId"
                }}
                searchBack={sb => this.search = sb}
                processSearch={() => this.routines.search()}
            />
            <SwitchBox
                onChange={v => this.setVal({listView: v})}
                value={this.state.listView}
                size={26}
                title="List View"
                style={{borderColor: "#CCCCCC", color: "#FFFFFF"}}
                rightLabel="List View"
            />
            <SwitchBox
                onChange={v => this.setVal({visibleOnly: v})}
                value={this.state.visibleOnly}
                size={26}
                title="Visible Only"
                style={{borderColor: "#CCCCCC", color: "#FFFFFF"}}
                rightLabel="Visible Only"
            />
            <span style={{float: "right"}}>
                
            </span>
        </div>
        {!this.state.listView ? <VehiclesTable
            vehicles={this.state.vehicles}
            search={this.state.search}
            visibleOnly={this.state.visibleOnly}
            isMobile={this.state.isMobile}
        /> : <table className="wide vehicles">
            <thead>
                <tr>
                    <th>Visible</th>
                    <th>Date</th>
                    <th>ID</th>
                    <th>Views</th>
                    <th>Likes</th>
                    <th>Images</th>
                    <th>Ask Price</th>
                    <th>Year</th>
                    <th>Manufacturer</th>
                    <th>Model</th>
                    <th>Trim</th>
                </tr>
            </thead>
            {this.routines.count(this.state.vehicles.filter(v => !this.state.visibleOnly || v.visible).filter(v => this.state.search(v))).map(v => (
                <tbody
                    className="hover"
                    key={v.id}
                    onClick={() => Spikes.notify("navigate", `/vehicle?vehicleId=${v.id}`)}
                >
                    <tr style={{backgroundColor: v.visible ? "#FFFFFF" : "#DDDDDD"}}>
                        <td style={{whiteSpace: "nowrap"}}>{v.visible ? "True" : "False"}</td>
                        <td style={{whiteSpace: "nowrap"}}>{""}</td>
                        <td style={{whiteSpace: "nowrap"}}>{v.id}</td>
                        <td style={{whiteSpace: "nowrap"}}>{v.views || ""}</td>
                        <td style={{whiteSpace: "nowrap"}}>{v.likedCount || ""}</td>
                        <td style={{whiteSpace: "nowrap"}}>{v.imageCount || ""}</td>
                        <td style={{whiteSpace: "nowrap"}}>${Spikes.numberCS(v.listing?.price)}</td>
                        <td style={{whiteSpace: "nowrap"}}>{v.year || ""}</td>
                        <td style={{whiteSpace: "nowrap"}}>{v.make || ""}</td>
                        <td style={{whiteSpace: "nowrap"}}>{v.model || ""}</td>
                        <td style={{whiteSpace: "nowrap"}}>{v.trim || ""}</td>
                    </tr>
                </tbody>
            ))}
        </table>}
    </div>;
    
    mobile = () => <div>
        <div className="optionsbar">
            <BreakSearch
                name="vehicles"
                filters={{
                    "ID": "id",
                    "VIN": "vin",
                    "Year": "year",
                    "Make": "make",
                    "Model": "model",
                    "Trim": "trim",
                    "B. Type": "body_type",
                    "Ext. Color": "exterior_color",
                    "Int. Color": "interior_color",
                    "Tag": "tag",
                    "Visible": "visible",
                    "Certification": "certification",
                    "Dealer ID": "dealerId"
                }}
                searchBack={sb => this.search = sb}
                processSearch={() => this.routines.search()}
            />
        </div>
        <div className="optionsbar">
            <SwitchBox
                onChange={v => this.setVal({visibleOnly: v})}
                value={this.state.visibleOnly}
                size={26}
                title="Visible Only"
                style={{borderColor: "#CCCCCC", color: "#FFFFFF"}}
                rightLabel="Visible Only"
            />
            <SwitchBox
                onChange={v => this.setVal({showTags: v})}
                value={this.state.showTags}
                size={26}
                title="Show Tags"
                style={{borderColor: "#CCCCCC", color: "#FFFFFF"}}
                rightLabel="Show Tags"
            />
        </div>
        <VehiclesTable
            vehicles={this.state.vehicles}
            search={this.state.search}
            visibleOnly={this.state.visibleOnly}
            isMobile={this.state.isMobile}
            showTags={this.state.showTags}
        />
    </div>;
    
    render = () => !this.state.vehicles ? <img src="/images/loading_rs2.gif" className="center-screen" alt="Loading..." /> : (this.state.isMobile ? this.mobile : this.desktop)();
}