import React from "react";
import Icon from "../../directives/Icon";
import Spikes from "../../services/Spikes";

export default class InventoryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navigate: false
        };
    }

    componentDidMount() {
        setTimeout(() => window.scrollTo(0, 0));
        setTimeout(() => Spikes.notify("title", "checklist", "Inventory"));
    }
    
    content = {menu: <></>};
    
    render = () => <div>
        {this.content.menu}
        <div style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
        }}>
            {[
                {icon: "upload", link: "/import", caption: "Import"},
                {icon: "checklist", link: "/validate", caption: "Validate"},
                {icon: "book", link: "/blackbook", caption: "BlackBook"}
            ].map((lnk, index) => <span key={index} onClick={() => Spikes.notify("navigate", lnk.link)} className="link-panel">
                <div style={{textAlign: "center"}}><Icon name={lnk.icon} margin="0" size="44px" /></div>
                <div style={{textAlign: "center"}}>{lnk.caption}</div>
            </span>)}
        </div>
    </div>;    
}