import React from "react";
import styles from "./login.module.css";
import Spikes from "../services/Spikes";
import BaseApi from "../services/BaseApi";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            panel: "login",
            firstname: null,
            lastname: null,
            email: null,
            navigate: null
        };
        this.refEmail = React.createRef();
        this.refPassword = React.createRef();
        this.refNewPassword = React.createRef();
        this.refConfirmPassword = React.createRef();
        this.requestId = Spikes.getQueryParam("requestId"); 
    }

    setVal = Spikes.stateStream(this);

    componentDidMount() {
        setTimeout(() => window.scrollTo(0, 0));
        if (this.requestId) {
            BaseApi.login.resetInfo(this.requestId)(data => {
                if (data.success) {
                    this.setVal({
                        firstname: data.firstname,
                        lastname: data.lastname,
                        email: data.email,
                        panel: "new"
                    });
                }
            });
        }
    }

    errorTimer = null;
    setErrorMessage = msg => {
        this.setVal({
            errorMessage: msg
        });
        clearTimeout(this.errorTimer);
        this.errorTimer = setTimeout(() => {
            this.setVal({
                errorMessage: null
            }); 
        }, 3000);
    };

    componentWillUnmount() {
        clearTimeout(this.errorTimer);
    }

    submitLogin = event => {
        event.preventDefault();
        if (this.state.panel === "login") {
            if (!this.refEmail.current.value) {
                this.setErrorMessage("Please provide a valid E-mail Address");
            } else if (!this.refPassword.current.value || this.refPassword.current.value.length < 8) {
                this.setErrorMessage("Please provide a password of atleast 8 characters");
            } else {
                this.setErrorMessage("Processing...");
                BaseApi.login.login(this.refEmail.current.value, this.refPassword.current.value)(response => {
                    if (!response.success) {
                        this.setErrorMessage("Please verify your E-mail Address & Password");
                    }
                });
            }
        } else if (this.state.panel === "reset") {
            if (!this.refEmail.current.value) {
                this.setErrorMessage("Please provide a valid E-mail Address");
            } else {
                this.setErrorMessage("Processing...");
                BaseApi.login.setPassword(this.refEmail.current.value)(response => {
                    if (response.success) {
                        this.setErrorMessage("Check your E-mail for next steps...");
                    } else {
                        this.setErrorMessage("Please verify your E-mail Address");
                    }
                });
            }
        } else if (this.state.panel === "new") {
            if (!this.refNewPassword.current.value || this.refNewPassword.current.value.length < 8) {
                this.setErrorMessage("Please provide a password of atleast 8 characters");
            } else if (this.refNewPassword.current.value !== this.refConfirmPassword.current.value) {
                this.setErrorMessage("Confirmation Password does not Match Password");
            } else {
                this.setErrorMessage("Processing...");
                BaseApi.login.savePassword(this.requestId, this.refNewPassword.current.value)(data => {
                    if (data.success) {
                        this.setErrorMessage("New Password Saved");
                        setTimeout(() => {
                            this.setVal({panel: "login"});
                        }, 3000);
                    } else {
                        this.setErrorMessage("Failed to set new Password");
                    }
                });
            }
        }
    };

    render() {
        return (
            <div>            
                <div className={styles.header}>CarMonk - Customer Relations Portal</div>
                <form onSubmit={this.submitLogin}>
                    <table className={styles.loginTable}>
                        <tbody>
                            <tr><td style={{textAlign: "center"}} colSpan="2"><img src="/images/favicon.png" className={styles.objectCenter} style={{width: "100px", height: "auto"}} alt="" /></td></tr>
                            {this.state.errorMessage && <tr><td className={[styles.textDanger, styles.envFull, styles.textCenter].join(" ")} colSpan="2">{ this.state.errorMessage }</td></tr>}
                            {(() => {
                                if (this.state.panel === "login") {
                                    return (
                                        <>
                                            <tr><td style={{textAlign: "right", width: "100%"}}>E-mail Address:</td><td><input style={{width: "200px"}} ref={this.refEmail} type="text" placeholder="E-mail Address" /></td></tr>
                                            <tr><td style={{textAlign: "right", width: "100%"}}>Password:</td><td><input style={{width: "200px"}} ref={this.refPassword} type="password" placeholder="Password" /></td></tr>
                                            <tr><td colSpan="2" style={{textAlign: "right"}}><button type="submit">Login</button></td></tr>
                                            <tr><td colSpan="2" style={{textAlign: "center"}}><span onClick={() => {this.setVal({panel: "reset"});}} className={styles.link}>Reset My Password</span></td></tr>
                                        </>
                                    );
                                } else if (this.state.panel === "reset") {
                                    return (
                                        <>
                                            <tr><td style={{textAlign: "right", width: "100%"}}>E-mail Address:</td><td><input style={{width: "200px"}} ref={this.refEmail} type="text" placeholder="E-mail Address" /></td></tr>
                                            <tr><td colSpan="2" style={{textAlign: "right"}}><button type="submit">Reset Password</button></td></tr>
                                            <tr><td colSpan="2" style={{textAlign: "center"}}><span onClick={() => {this.setVal({panel: "login"});}} className={styles.link}>Return To Login</span></td></tr>
                                        </>
                                    );
                                } else if (this.state.panel === "new") {
                                    return (
                                        <>
                                            <tr><td style={{textAlign: "right", width: "100%", height: "32px"}}>Account Holder:</td><td>{this.state.firstname} {this.state.lastname}</td></tr>
                                            <tr><td style={{textAlign: "right", width: "100%", height: "32px"}}>E-mail Address:</td><td>{this.state.email}</td></tr>
                                            <tr><td style={{textAlign: "right", width: "100%"}}>New Password:</td><td><input style={{width: "200px"}} ref={this.refNewPassword} type="password" placeholder="New Password" /></td></tr>
                                            <tr><td style={{textAlign: "right", width: "100%"}}>Confirm Password:</td><td><input style={{width: "200px"}} ref={this.refConfirmPassword} type="password" placeholder="Confirm Password" /></td></tr>
                                            <tr><td colSpan="2" style={{textAlign: "right"}}><button type="submit">Save Password</button></td></tr>
                                            <tr><td colSpan="2" style={{textAlign: "center"}}><span onClick={() => {this.setVal({panel: "login"});}} className={styles.link}>Return To Login</span></td></tr>
                                        </>
                                    );
                                }
                            })()}
                        </tbody>
                    </table>
                </form>
                <div className={styles.footer}>Powered By: ConsumerGenius</div>
            </div>
        );
    }
}

export default LoginPage;