import React from "react";
import Spikes from "../../services/Spikes";
import BaseApi from "../../services/BaseApi";
import ModalFrame from "../../directives/ModalFrame";
import Icon from "../../directives/Icon";

export default class VehicleNoteModal extends React.Component {
    state = {
        vehicleId: false,
        note: false,
        save: false
    };

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    routines = {
        save: () => BaseApi.vehicles.saveNote(this.state.vehicleId, {id: this.state.note.id || false, note: this.state.save})(data => {
            if (data.success) {
                Spikes.notify("pop", {
                    group: "default",
                    message: `Vehicle Note Saved`,
                    icon: "note",
                    timeout: 10000
                });
                this.routines.close();
            }
        }),
        delete: () => {
            let note = this.state.note;
            note.delete = true;
            BaseApi.vehicles.saveNote(this.state.vehicleId, note)(data => {
                if (data.success) {
                    Spikes.notify("pop", {
                        group: "default",
                        message: `Vehicle Note Deleted`,
                        icon: "delete",
                        timeout: 10000
                    });
                    this.routines.close();
                }
            });
        },
        close: () => this.setVal({
            vehicleId: false,
            note: false,
            save: false
        }),
        change: event => this.setVal({save: event.target.value})
    };

    componentDidMount() {
        this.wHolder(Spikes.watch("VehicleNoteModal")((vehicleId, note) => {
            this.setVal({
                vehicleId: vehicleId || false,
                note: note || {
                    note: ""
                },
                save: false
            });
        }));
    }
    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    render = () => !this.state.vehicleId ? null : <ModalFrame onClose={() => this.routines.close()}>
        <span><Icon name="note" size="20px" />Vehicle Note</span>
        <textarea
            style={{
                display: "block",
                margin: "0px",
                width: "100%",
                height: "200px"
            }}
            defaultValue={this.state.note.note || ""}
            placeholder="Enter Note Here:"
            onChange={e => this.routines.change(e)}
        ></textarea>
        <div style={{textAlign: "right", padding: "4px"}}>
            <button onClick={() => this.routines.delete()} disabled={!this.state.note.id}><Icon name="delete" />Delete</button>
            <button onClick={() => this.routines.close()}><Icon name="cancel" />Cancel</button>
            <button onClick={() => this.routines.save()} disabled={this.state.save === false}><Icon name="save" />Save</button>
        </div>
    </ModalFrame>;
}