import React from "react";
import BaseApi from "../../services/BaseApi";
import Spikes from "../../services/Spikes";
import Icon from "../../directives/Icon";
import BaseData from "../../services/BaseData";
import TableView from "../../directives/TableView";

export default class DocumentElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            documents: []
        };
    }

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    ref = {
        documents: React.createRef()
    };

    routines = {
        scroll: () => {
            this.ref.documents.current.scrollTop = this.ref.documents.current.scrollHeight;
        },
        uploadDoc: doc => {
            const showModal = (file, buttons) => {
                Spikes.notify("modal", {
                    title: "Upload Document",
                    content: [
                        {
                            type: "image",
                            src: file.data,
                            style: {maxHeight: "300px", maxWidth: "400px"},
                            alt: file.name,
                            title: file.name
                        },
                        {
                            type: "display",
                            caption: "Filename",
                            value: file.name
                        },
                        {
                            type: "display",
                            caption: "Type",
                            value: file.type
                        },
                        {
                            type: "string",
                            caption: "Description",
                            value: file.description || "",
                            name: "description"
                        },
                        {
                            name: "file",
                            value: file
                        }
                    ],
                    buttons: buttons
                });
            };
            if (!doc) {
                Spikes.upload((success, file) => {
                    showModal(file, [
                        {
                            icon: "cancel",
                            caption: "Cancel",
                            routine: options => {options.close();}
                        },
                        {
                            icon: "save",
                            caption: "Save",
                            routine: options => {
                                console.log("Document:", this.props.profileId, {description: options.valueOf("description"), file: options.valueOf("file")});
                                BaseApi.customers.saveDocument(this.props.profileId, options.valueOf("description"), options.valueOf("file"))(result => {
                                    if (result.success) {
                                        Spikes.notify("pop", {
                                            group: "default",
                                            message: `Document Uploaded`,
                                            icon: "upload",
                                            timeout: 10000
                                        });
                                    } else {
                                        Spikes.notify("pop", {
                                            group: "danger",
                                            message: `Document Failed To Uploaded`,
                                            icon: "upload",
                                            timeout: 10000
                                        });
                                    }
                                });
                                options.close();
                            }
                        }
                    ]);
                });
            } else {
                showModal({
                    id: doc.id,
                    data: doc.url,
                    name: doc.originalFilename,
                    type: doc.type,
                    description: doc.description
                }, [
                    {
                        icon: "remove",
                        caption: "Delete",
                        routine: options => {
                            BaseApi.customers.deleteDocument(this.props.profileId, options.valueOf("file").id)(result => {
                                if (result.success) {
                                    Spikes.notify("pop", {
                                        group: "default",
                                        message: `Document Deleted`,
                                        icon: "remove",
                                        timeout: 10000
                                    });
                                } else {
                                    Spikes.notify("pop", {
                                        group: "danger",
                                        message: `Document Failed To Delete`,
                                        icon: "remove",
                                        timeout: 10000
                                    });
                                }
                            });
                            options.close();
                        }
                    },
                    {
                        icon: "cancel",
                        caption: "Cancel",
                        routine: options => {options.close();}
                    },
                    {
                        icon: "save",
                        caption: "Save",
                        routine: options => {
                            console.log("Document:", this.props.profileId, {description: options.valueOf("description"), file: options.valueOf("file")});
                            BaseApi.customers.editDocument(this.props.profileId, options.valueOf("file").id, options.valueOf("description"))(result => {
                                if (result.success) {
                                    Spikes.notify("pop", {
                                        group: "default",
                                        message: `Document Updated`,
                                        icon: "document",
                                        timeout: 10000
                                    });
                                } else {
                                    Spikes.notify("pop", {
                                        group: "danger",
                                        message: `Document Failed To Update`,
                                        icon: "document",
                                        timeout: 10000
                                    });
                                }
                            });
                            options.close();
                        }
                    }
                ]);
            }
        },
    };

    componentDidMount() {
        this.wHolder(Spikes.drag.watch()((action, drag) => {
            if (action === "drop" && drag.source !== "customerDocs" && drag.dest === "customerDocs") {
                console.log("Customer Docs Drop:", drag);
                Spikes.notify("modal", {
                    title: "Drop Document",
                    content: [
                        {
                            type: "image",
                            src: drag.elem.src,
                            style: {maxHeight: "300px", maxWidth: "400px"},
                            alt: "Dropped Image",
                            title: "Dropped Image"
                        },
                        {
                            type: "display",
                            caption: "Filename",
                            value: "Dropped Image"
                        },
                        {
                            type: "string",
                            caption: "Description",
                            value: "",
                            name: "description"
                        }
                    ],
                    buttons: [
                        {
                            icon: "cancel",
                            caption: "Cancel",
                            routine: options => {options.close();}
                        },
                        {
                            icon: "save",
                            caption: "Save",
                            routine: options => {
                                BaseApi.customers.dropDocument(this.props.profileId, options.valueOf("description"), drag.elem.src)(result => {
                                    if (result.success) {
                                        Spikes.notify("pop", {
                                            group: "default",
                                            message: `Document Saved`,
                                            icon: "document",
                                            timeout: 10000
                                        });
                                    } else {
                                        Spikes.notify("pop", {
                                            group: "danger",
                                            message: `Document Failed To Save`,
                                            icon: "document",
                                            timeout: 10000
                                        });
                                    }
                                });
                                options.close();
                            }   
                        }
                    ]
                });
            }
        }));
        this.wHolder(BaseData.profile(this.props.profileId, profile => {
            this.setVal({documents: Array.isArray(profile.documents) ? profile.documents : []}, () => {
                this.routines.scroll();
            });
        }));
    }
    
    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    render = () => <>
        <TableView.Header><Icon name="document" />Documents<Icon name="upload" className="link" title="Upload Document" style={{float: "right"}} onClick={() => this.routines.uploadDoc()} /></TableView.Header>
        <TableView.Body
            style={{height: "300px", overflow: "auto", padding: "5px"}}
            ref={this.ref.documents}
            onDragOver={e => Spikes.drag.onOver(e)}
            onDrop={e => Spikes.drag.onDrop(e)}
            dropname="customerDocs"
        >
            <table className="wide">{
                this.state.documents.map(doc => <tbody
                    key={doc.id + "a"}
                    className="hover"
                    onClick={() => this.routines.uploadDoc(doc)}
                >
                    <tr key={doc.id + "b"}>
                        <td rowSpan={3}><img
                            src={doc.url}
                            alt={doc.originalFilename}
                            style={{maxHeight: "60px"}}
                            onLoad={() => this.routines.scroll("documents")}
                            draggable="true"
                            onDragStart={e => Spikes.drag.onDrag(e, "customerDocs")}
                            onDragEnd={e => Spikes.drag.onEnd(e)}
                        /></td>
                        <td style={{width: "100%", borderBottomWidth: "0px"}}>{doc.description}</td>
                    </tr>
                    <tr key={doc.id + "c"}><td style={{borderBottomWidth: "0px"}}>{doc.originalFilename}</td></tr>
                    <tr key={doc.id + "d"}><td>{Spikes.date.lhDateTime(doc.dateUploaded)}</td></tr>
                </tbody>)
            }</table>
        </TableView.Body>
    </>;
}