import React from "react";
import Spikes from "../../services/Spikes";

export default class SettingsPage extends React.Component {
    componentDidMount() {
        setTimeout(() => window.scrollTo(0, 0));
        setTimeout(() => Spikes.notify("title", "settings", "System Settings"));
    }
    render = () => <div>
        <div style={{padding: "10px"}}><h3>Pending Update</h3></div>
    </div>;
}