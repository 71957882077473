import React from "react";
import Spikes from "../services/Spikes";
import Icon from "./Icon";

export default class OrderH extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            caret: props.defaultValue || ""
        };
        if (props.defaultValue) {
            this.props.onClick("", caret => this.setVal({caret: caret}));
        }
    }
    setVal = Spikes.stateStream(this);
    componentWillUnmount() {this.setVal("end");}
    render = () => <th colSpan={this.props.colSpan} style={{cursor: "pointer"}} onClick={() => this.props.onClick(this.props.field, caret => this.setVal({caret: caret}))}>{(this.state.caret === "up" && <Icon name="caret-up" />) || (this.state.caret === "down" && <Icon name="caret-down" />)}{this.props.caption}</th>;
}