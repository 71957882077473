import React from "react";
import Spikes from "../../services/Spikes";
import Icon from "../../directives/Icon";
import siteState from "../../services/siteState";

class ModalBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };
        this.maskRef = React.createRef();
    }

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    componentDidMount() {
        this.wHolder(Spikes.watch("modal")(data => this.setVal({modal: data})));
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    options = {
        valueOf: name => Spikes.first(this.state.modal?.content?.filter(c => c && c.name === name) || [])(c => c.value, () => null),
        close: () => this.setVal({modal: false})
    };

    routines = {
        maskClick: () => {
            if (typeof(this.state.modal?.cancel) === "function") {
                this.state.modal.cancel(this.options);
            } else {
                this.options.close();
            }
            this.setVal({preview: false});
        }
    };

    mask = () => <div 
        style={{
            position: "fixed", 
            top: "0px", 
            left: "0px", 
            width: "100%", 
            height: "100%", 
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            zIndex: 5
        }}
        onClick={() => this.routines.maskClick()}
        ref={this.maskRef}
    ></div>;

    modal = () => <div
        style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: this.state.modal.width || "auto",
            minWidth: "300px",
            height: this.state.modal.height || "auto",
            backgroundColor: "var(--theme-lt-background-color)",
            display: "block",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "var(--theme-lt-border-color)",
            borderRadius: "10px 10px 4px 4px",
            zIndex: ++siteState.topIndex
        }}
    ><div
        style={{
            backgroundImage: "linear-gradient(var(--theme-lt-active-color), var(--theme-lt-border-color))",
            display: "block",
            fontFamily: "federationregular",
            fontSize: "14px",
            margin: "0px 0px 0px 0px",
            padding: "4px",
            color: "var(--theme-lt-background-color)",
            borderRadius: "9px 9px 0px 0px",
            cursor: "move"
        }}
        onMouseDown={e => Spikes.move(e, e.target.parentNode, {top: 41, bottom: 25})}
    ><i
        className="fas fa-times"
        style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            cursor: "pointer",
            fontSize: "18px",
            padding: "2px 5px 5px 5px"
        }}
        onClick={() => this.routines.maskClick()}
    ></i>{this.state.modal.title || ""}</div><div
        style={{
            display: "block",
            backgroundColor: "var(--theme-lt-background-color)"
        }}
    ><table
        style={{
            width: "100%",
            borderCollapse: "collapse",
            borderSpacing: "0px"
        }}
    >{(this.state.modal.content || []).map((content, index) => {
        switch (content.type) {
            case "string": return (
                <tbody key={index}>
                    <tr>
                        <td style={{whiteSpace: "nowrap", textAlign: "right", height: "30px"}}>{content.caption || ""}</td>
                        <td><input
                            type="text" 
                            style={{
                                display: "block",
                                margin: "0px",
                                width: content.width || "100%"
                            }}
                            name={content.name}
                            defaultValue={content.value}
                            placeholder={content.placeholder || content.caption || ""}
                            onChange={e => {content.value = e.target.value;}}
                        /></td>
                    </tr>
                </tbody>
            );
            case "number": return (
                <tbody key={index}>
                    <tr>
                        <td style={{whiteSpace: "nowrap", textAlign: "right", height: "30px"}}>{content.caption || ""}</td>
                        <td><input
                            type="number"
                            style={{
                                display: "block",
                                margin: "0px",
                                width: content.width || "100%"
                            }}
                            name={content.name}
                            defaultValue={content.value}
                            placeholder={content.placeholder || content.caption || ""}
                            onChange={e => {content.value = e.target.value;}}
                        /></td>
                    </tr>
                </tbody>
            );
            case "select": return (
                <tbody key={index}>
                    <tr>
                        <td style={{whiteSpace: "nowrap", textAlign: "right", height: "30px"}}>{content.caption || ""}</td>
                        <td>
                            <select 
                                name={content.name}
                                defaultValue={content.value}
                                placeholder={content.placeholder || content.caption || ""}
                                onChange={e => {content.value = e.target.value;}}
                                style={{
                                    display: "block",
                                    margin: "0px",
                                    width: content.width || "100%"
                                }}
                            >{([{label: "", value: null}, ...content.options] || []).map((opt, index) => <option key={index} value={opt.value}>{opt.label}</option>)}</select>
                        </td>
                    </tr>
                </tbody>
            );
            case "note": return (
                <tbody key={index}>
                    <tr>
                        <td style={{whiteSpace: "nowrap", textAlign: "right", height: "30px"}}>{content.caption || ""}</td>
                        <td><textarea
                            style={{
                                display: "block",
                                margin: "0px",
                                width: content.width || "100%",
                                height: content.height || "200px"
                            }}
                            name={content.name}
                            defaultValue={content.value}
                            placeholder={content.placeholder || content.caption || ""}
                            onChange={e => {content.value = e.target.value;}}
                        ></textarea></td>
                    </tr>
                </tbody>
            );
            case "note2": return <tbody key={index}>
                <tr><td colSpan={2}>{content.caption || ""}</td></tr>
                <tr>
                    <td colSpan={2}><textarea
                        style={{
                            display: "block",
                            margin: "0px",
                            width: content.width || "100%",
                            height: content.height || "200px"
                        }}
                        name={content.name}
                        defaultValue={content.value}
                        placeholder={content.placeholder || content.caption || ""}
                        onChange={e => {content.value = e.target.value;}}
                    ></textarea></td>
                </tr>
            </tbody>;
            case "custom": return content.value;
            case "display": return <tbody key={index}>
                <tr>
                    <td style={{whiteSpace: "nowrap", textAlign: "right", height: "30px"}}>{content.caption || ""}</td>
                    <td style={{paddingLeft: "5px"}}>{content.value}</td>
                </tr>
            </tbody>;
            case "image": return <tbody key={index}>
                <tr>
                    <td colSpan={2} style={{textAlign: "center"}}><img src={content.src} alt={content.alt} title={content.title} style={content.style} /></td>
                </tr>
            </tbody>;
            default: return null;
        }
    })}</table></div><div
        style={{
            textAlign: "right",
            padding: "4px"
        }}
    >{(this.state.modal.buttons || []).filter(btn => btn).map((btn, index) => {
        return (
            <button 
                key={index} 
                onClick={() => {if (typeof(btn.routine) === "function") {btn.routine(this.options);}}}
                style={{height: "28px", minWidth: "60px", marginLeft: "4px", cursor: "pointer"}}
            ><Icon name={btn.icon} />{btn.caption}</button>
        );
    })}</div></div>;

    popMsg = () => <div
        style={{
            display: "block",
            position: "fixed",
            right: "30px",
            bottom: "35px",
            textAlign: "right",
            pointerEvents: "none",
            zIndex: 10
        }}
        ref={this.popRef}
    >{this.state.popMsgs.map(content => (
        <div
            key={content.id}
        >
            <span
                className={(() => {
                    switch (content.group) {
                        case "info": return "info-box info-info";
                        case "danger": return "info-box info-danger";
                        case "warning": return "info-box info-warning";
                        default: return "info-box info-default";
                    }
                })()}
            ><Icon name={content.icon} />{content.message}</span>
        </div>
    ))}</div>;

    render = () => <>
        {(this.state.modal || this.state.preview) && this.mask()}
        {this.state.modal && this.modal()}
    </>;
}
export default ModalBuilder;