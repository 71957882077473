import React from "react";
import Spikes from "../../services/Spikes";
import Icon from "../../directives/Icon";
import TableView from "../../directives/TableView";
import SwitchBox from "../../directives/SwitchBox";
import BaseData from "../../services/BaseData";
import BaseApi from "../../services/BaseApi";

export default class AlertSettingsPage extends React.Component {
    state = {
        isMobile: this.props.isMobile,
        numberOfNotify: null,
        notifySms: false,
        alertSettings: false,
        updated: false
    };

    updates = {};

    wHolder = Spikes.watchHolder();
    setVal = Spikes.stateStream(this);

    routines = {
        saveSettings: () => {
            BaseApi.user.saveSettings({alertSettings: Spikes.fuseObj(this.state.alertSettings, this.updates)})(data => {
                if (data.success) {
                    Spikes.notify("pop", {group: "default", icon: "settings", message: `Alert Settings Saved.`});
                    this.updates = {};
                    this.setVal({updated: false});
                }
            });
        },
        update: (name, value) => {
            this.updates[name] = value;
            Object.keys(this.updates).forEach(key => {
                if (this.updates[key] === this.state.alertSettings[key]) {delete(this.updates[key]);}
            });
            this.setVal({updated: Object.values(this.updates).length > 0});
        }
    };

    componentDidMount() {
        this.wHolder(Spikes.watch("isMobile")(isMobile => this.setVal({isMobile: isMobile})));
        setTimeout(() => Spikes.notify("title", "settings", "Alert Settings", ""));
        this.wHolder(BaseData.settings(settings => this.setVal({alertSettings: Spikes.fuseObj({
            applicationEnabled: false,
            applicationSeconds: 300,
            smsEnabled: false,
            smsSeconds: 300,
            voicemailEnabled: false,
            voicemailSeconds: 300,
            callbackEnabled: false,
            callbackSeconds: 300
        }, settings.alertSettings)})));
    }
    componentWillUnmount() {
        this.wHolder("end");
        this.setVal("end");
    }
    desktop = () => <div>
        <div className="optionsbar">
            <button className="solo" disabled={!this.state.updated} onClick={() => this.routines.saveSettings()}><Icon name="save" />Save Changes</button>
        </div>
        
        <TableView.Header style={{maxWidth: "600px"}}><Icon name="sms" />Notify Me By SMS</TableView.Header>
        <TableView.Body style={{maxWidth: "600px"}}>
            <TableView.RowView title="Number To Notify">
                <input 
                    type="text" 
                    onChange={e => this.routines.update("number", e.target.value)}
                    defaultValue={this.state.alertSettings.number}
                    style={{width: "170px"}}
                    placeholder="Enter Your SMS Number"
                />
            </TableView.RowView>
            <TableView.RowView title="Applications (Seconds)">
                <SwitchBox
                    onChange={v => this.routines.update("applicationEnabled", v)}
                    value={this.state.alertSettings.applicationEnabled}
                    size={28}
                    title="Notify About Applications"
                    borderColor="var(--color-2)"
                    style={{marginRight: "2px"}}
                /><input
                    type="number"
                    style={{width: "100px"}}
                    onChange={e => this.routines.update("applicationSeconds", Spikes.toNumber(e.target.value))}
                    defaultValue={this.state.alertSettings.applicationSeconds}
                />
            </TableView.RowView>
            <TableView.RowView title="SMS (Seconds)">
                <SwitchBox
                    onChange={v => this.routines.update("smsEnabled", v)}
                    value={this.state.alertSettings.smsEnabled}
                    size={28}
                    title="Notify About SMS"
                    borderColor="var(--color-2)"
                    style={{marginRight: "2px"}}
                /><input
                    type="number"
                    style={{width: "100px"}}
                    onChange={e => this.routines.update("smsSeconds", Spikes.toNumber(e.target.value))}
                    defaultValue={this.state.alertSettings.smsSeconds}
                />
            </TableView.RowView>
            <TableView.RowView title="Voicemail (Seconds)">
                <SwitchBox
                    onChange={v => this.routines.update("voicemailEnabled", v)}
                    value={this.state.alertSettings.voicemailEnabled}
                    size={28}
                    title="Notify About Voicemail"
                    borderColor="var(--color-2)"
                    style={{marginRight: "2px"}}
                /><input
                    type="number"
                    style={{width: "100px"}}
                    onChange={e => this.routines.update("voicemailSeconds", Spikes.toNumber(e.target.value))}
                    defaultValue={this.state.alertSettings.voicemailSeconds}
                />
            </TableView.RowView>
            <TableView.RowView title="Callbacks (Seconds)">
                <SwitchBox
                    onChange={v => this.routines.update("callbackEnabled", v)}
                    value={this.state.alertSettings.callbackEnabled}
                    size={28}
                    title="Notify About Callbacks"
                    borderColor="var(--color-2)"
                    style={{marginRight: "2px"}}
                /><input
                    type="number"
                    style={{width: "100px"}}
                    onChange={e => this.routines.update("callbackSeconds", Spikes.toNumber(e.target.value))}
                    defaultValue={this.state.alertSettings.callbackSeconds}
                />
            </TableView.RowView>
        </TableView.Body>
    </div>;
    mobile = () => <div></div>;
    render = () => !this.state.alertSettings ? null : (this.state.isMobile ? this.mobile : this.desktop)();
}