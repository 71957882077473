import React from "react";
import ModalFrame from "../../directives/ModalFrame";
import Spikes from "../../services/Spikes";
import Icon from "../../directives/Icon";

export default class NumberModal extends React.Component {
    state = {
        show: false,
        title: null,
        callback: null,
        number: "",

    };
    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    routines = {
        save: () => {
            if (typeof(this.state.callback) === "function") {
                this.state.callback(this.state.valid);
            }
            this.routines.close();
        },
        close: () => {
            this.setVal({
                show: false,
                title: null,
                callback: null
            });
        },
        update: event => {
            const toPhone = num => {
                if (typeof(num) === "number") {num = num.toString();}
                if (typeof(num) === "string") {
                    num = num.replace(/\D/g, "").slice(-10);
                    if (num.length !== 10) {num = false;}
                } else {num = false;}
                return num;
            };
            this.setVal({
                number: event.target.value,
                valid: toPhone(event.target.value)
            });
        }
    };


    componentDidMount = () => {
        this.wHolder(Spikes.watch("requestNumber")((title, cb) => this.setVal({show: true, title: title, callback: cb})));
    };
    componentWillUnmount = () => {
        this.wHolder("end");
    };

    render = () => this.state.show && <ModalFrame onClose={() => this.routines.close()} width="300">
        <span>{this.state.title}</span>
        <div>
            <input defaultValue={this.state.number} onChange={e => this.routines.update(e)} style={{textAlign: "center"}} />
        </div>
        <div style={{textAlign: "right", padding: "4px"}}>
            <button onClick={() => this.routines.close()}><Icon name="cancel" />Cancel</button>
            <button onClick={() => this.routines.save()} disabled={!this.state.valid}><Icon name="save" />Save</button>
        </div>
    </ModalFrame>;
}