import React from "react";
import Spikes from "../../services/Spikes";
import BaseApi from "../../services/BaseApi";
import siteState from "../../services/siteState";
import BaseData from "../../services/BaseData";
import ApplicationDesktop from "./ApplicationDesktop";
import ApplicationMobile from "./ApplicationMobile";

const lastApp = (type, applications) => {
    if (Spikes.isObject(applications) && Array.isArray(applications.forms)) {
        return Spikes.last(applications.forms.filter(f => f.complete && f.type === type))(f => f, () => false);
    } else {
        return false;
    }
};

class ApplicationPage extends React.Component {
    constructor(props) {
        super(props);
        this.index = 0;
        this.profileId = Spikes.getQueryParam("profileId");
        this.state = siteState["applicationState_" + this.profileId] || {
            application: false,
            index: this.index,
            updates: {},
            isMobile: props.isMobile
        };
    }

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    ref = {
        notes: React.createRef(),
        history: React.createRef()
    };

    routines = {
        scroll: elem => setTimeout(() => {
            if (!elem || elem === "notes") {this.ref.notes.current.scrollTop = this.ref.notes.current.scrollHeight;}
            if (!elem || elem === "history") {this.ref.history.current.scrollTop = this.ref.history.current.scrollHeight;}
        }),
        editNote: note => Spikes.notify("CustomerNoteModal", this.profileId, note),
        updateReviewStatus: event => {
            if (event.target.value !== this.state.application.reviewStatus) {
                BaseApi.applications.updateReviewStatus(this.profileId, event.target.value)();
            }
        },
        callProfile: () => Spikes.notify("callById", this.profileId),
        update: (parent, name, value) => {
            let updates = this.state.updates;
            updates[name] = value;
            this.setVal({updates: updates});
        },
        saveUpdates: () => {
            let tUpdates = this.state.updates;
            this.setVal({updates: {}}, () => {
                BaseApi.applications.updateComplete(this.profileId, tUpdates)(data => {
                    if (!data.success) {this.setVal({updates: tUpdates});}
                });
            });
        },
        indexCondition: v => {
            switch (v) {
                case 1: return "Excellent";
                case 2: return "Good";
                case 3: return "Poor";
                default: return "N/A";
            }
        },
        setTradeIn: values => {
            console.log("TradeIn:", values);
            let updates = this.state.updates;
            updates.agent_sellortrade_year = values.year;
            updates.agent_sellortrade_make = values.make;
            updates.agent_sellortrade_model = values.model;
            updates.agent_sellortrade_series = values.series;
            updates.agent_sellortrade_style = values.style;
            updates.agent_sellortrade_value = values.value;
            updates.agent_sellortrade_uvc = values.uvc;
            updates.agent_sellortrade_condition = values.condition;
            updates.agent_sellortrade_odometer = values.kilometers;
            this.setVal({updates: updates});
        },
        equifaxPaste: event => {
            event.preventDefault();
            let html = event.clipboardData.getData('text/html');
            let text = event.clipboardData.getData('text/plain');
            // console.log("efHtml:", html, text);
            if (html && text !== html) {
                BaseApi.applications.saveEquifax(this.profileId, {
                    type: "html",
                    data: html
                })();
            } else {
                BaseApi.applications.saveEquifax(this.profileId, {
                    type: "text",
                    data: text
                })();
            }
        },
        transUnionPaste: event => {
            event.preventDefault();
            let html = event.clipboardData.getData('text/html');
            let text = event.clipboardData.getData('text/plain');
            // console.log("efHtml:", html, text);
            if (html && text !== html) {
                BaseApi.applications.saveTransUnion(this.profileId, {
                    type: "html",
                    data: html
                })();
            } else {
                BaseApi.applications.saveTransUnion(this.profileId, {
                    type: "text",
                    data: text
                })();
            }
        },
        equifaxClear: () => {
            BaseApi.applications.saveEquifax(this.profileId, false)();
        },
        transUnionClear: () => {
            BaseApi.applications.saveTransUnion(this.profileId, false)();
        }
    };

    componentDidMount() {
        this.wHolder(Spikes.watch("isMobile")(isMobile => this.setVal({isMobile: isMobile})));
        setTimeout(() => window.scrollTo(0, 0));
        setTimeout(() => Spikes.notify("title", "form", `Application - ${Spikes.asPhone(this.profileId)}`, "Loading..."));
        this.wHolder(Spikes.drag.watch()((action, drag) => {
            switch (action) {
                case "drag": break;
                case "drop": 
                    if (drag.dest === "dragLicF" && drag.elem.tagName === "IMG") {
                        let updates = this.state.updates;
                        updates.agent_license_src_front = drag.elem.src;
                        this.setVal({updates: updates});
                    } else if (drag.dest === "dragLicB" && drag.elem.tagName === "IMG") {
                        let updates = this.state.updates;
                        updates.agent_license_src_back = drag.elem.src;
                        this.setVal({updates: updates});
                    }
                break;
                case "end": break;
                default:
            }
        }));
        this.wHolder(BaseData.profile(this.profileId, profile => {
            let application = {
                qualify: lastApp("qualify", profile?.applications),
                purchase: lastApp("purchase", profile?.applications),
                trade: lastApp("trade", profile?.applications),
                global: profile.global,
                reviewStatus: profile.applications?.reviewStatus,
                dateModified: profile.applications?.dateModified,
                dateReviewed: profile.applications?.dateReviewed,
                fields: {},
                userId: profile.applications?.userId,
                notes: profile.notes,
                equifax: profile.equifax || false,
                transunion: profile.transunion || false
            };
            Object.keys(profile.applications?.fields || []).forEach(key => {application.fields[key] = profile.applications.fields[key];});
            Object.keys(this.state.updates).forEach(key => {application.fields[key] = this.state.updates[key];});
            console.log("Application:", application, profile);
            this.setVal({application: application, index: ++this.index}, () => {
                setTimeout(() => Spikes.notify("title", "form", `Application - ${Spikes.asPhone(this.profileId)}`, this.state.application.reviewStatus));
            });
        }));
        Spikes.notify("workSpaceView", this.profileId);
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
        siteState["applicationState_" + this.profileId] = this.state;
    }

    desktop = () => <ApplicationDesktop application={this.state.application} routines={this.routines} state={this.state} profileId={this.profileId} refs={this.ref} />;
    mobile = () => <ApplicationMobile application={this.state.application} routines={this.routines} state={this.state} profileId={this.profileId} refs={this.ref} />;
    render = () => !this.state.application ? <img src="/images/loading_rs2.gif" className="center-screen" alt="Loading..." /> : (this.state.isMobile ? this.mobile : this.desktop)();
}

export default ApplicationPage;