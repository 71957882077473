import React from "react";
import Spikes from "../../services/Spikes";
import siteState from "../../services/siteState";
export default class PopMenu extends React.Component {
    state = {
        menus: []
    };
    index = 0;

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    componentDidMount() {
        this.wHolder(Spikes.watch("menu")((event, options) => {
            console.log("Event", event);
            event.preventDefault();
            const clickHdlr = (event, clickFunc) => {remove(); clickFunc(event);};
            let menu = <div
                key={++this.index}
                onMouseLeave={e => remove()}
                className="popmenu"
                style={{
                    left: (event.clientX - 10) + "px",
                    top: (event.clientY - 10) + "px",
                    zIndex: ++siteState.topIndex
                }}
            >{
                options.title && <div className="poptitle">{options.title}</div>
            }{
                options.menu.map((c, i) => <div key={i} onClick={e => clickHdlr(e, c.click)} className="menuitem">{c.caption}</div>)
            }</div>;
            this.setVal({menus: [...this.state.menus, menu]});
            const remove = () => {
                let nMenus = this.state.menus;
                Spikes.remove(nMenus, menu);
                this.setVal({menus: nMenus});
            };
        }));
    }
    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }
    render() {
        return this.state.menus;
    }
}