import React from "react";
// import Icon from "../../directives/Icon";
import Spikes from "../../services/Spikes";
import BaseData from "../../services/BaseData";
import Icon from "../../directives/Icon";
import VehiclesTable from "../vehicles/VehiclesTable";
import siteState from "../../services/siteState";
import GoBack from "../../directives/GoBack";
import SwitchBox from "../../directives/SwitchBox";

export default class ValidatePage extends React.Component {
    vehicles = [];
    filterTypes = [
        {caption: "Missing Year/Make/Model (No Tag)", value: "init"},
        {caption: "Missing Year/Make/Model", value: "primary"},
        {caption: "Missing Body Type", value: "bodyType"},
        {caption: "Missing Images", value: "images"},
        {caption: "Missing/Invalid Exterior Colour", value: "badColor"},
        {caption: "Missing Kilometers", value: "kilometers"},
        {caption: "Missing Price", value: "price"},
        {caption: "Missing Doors", value: "doors"},
        {caption: "Missing Financing", value: "finance"},
        {caption: "Missing Certification", value: "certification"},
        {caption: "Not Visible", value: "notVisible"}
    ];
    colorsFilter = [
        "White",
        "Grey",
        "Silver",
        "Black",
        "Brown",
        "Gold",
        "Red",
        "Dark Red",
        "Blue",
        "Dark Blue",
        "Green",
        "Dark Green",
        "Orange",
        "Yellow",
        "Pink",
        "Purple"
    ];
    state = {
        vehciles: [],
        filterType: siteState.validateFilterType || this.filterTypes[0].value,
        visibleOnly: siteState.validateFilterVisible || false
    };

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    routines = {
        setDisply: () => {
            setTimeout(() => Spikes.notify("title", "checklist", "Validate Inventory Structure", `Type: ${this.filterTypes.find(f => f.value === this.state.filterType)?.caption} | Total: ${this.state.vehicles.length}`));
        },
        runFilter: () => {
            switch (this.state.filterType) {
                case "init":
                    this.setVal({vehicles: this.vehicles.filter(v => (!v.features || !v.features.year || !v.features.make || !v.features.model) && !v.tag)}, () => this.routines.setDisply());
                break;
                case "primary":
                    this.setVal({vehicles: this.vehicles.filter(v => !v.features || !v.features.year || !v.features.make || !v.features.model)}, () => this.routines.setDisply());
                break;
                case "bodyType":
                    this.setVal({vehicles: this.vehicles.filter(v => !v.features || !v.features.body_type)}, () => this.routines.setDisply());
                break;
                case "images":
                    this.setVal({vehicles: this.vehicles.filter(v => !Array.isArray(v.images) || !v.images.length)}, () => this.routines.setDisply());
                break;
                case "badColor":
                    this.setVal({vehicles: this.vehicles.filter(v => v.details && !this.colorsFilter.includes(v.details.exterior_color))}, () => this.routines.setDisply());
                break;
                case "kilometers":
                    this.setVal({vehicles: this.vehicles.filter(v => !v.details || !v.details.kilometers)}, () => this.routines.setDisply());
                break;
                case "price":
                    this.setVal({vehicles: this.vehicles.filter(v => !v.details || !v.details.price)}, () => this.routines.setDisply());
                break;
                case "doors":
                    this.setVal({vehicles: this.vehicles.filter(v => !v.features || !v.features.doors)}, () => this.routines.setDisply());
                break;
                case "finance":
                    this.setVal({vehicles: this.vehicles.filter(v => !v.details || !v.details.finance_term || !v.details.finance_rate)}, () => this.routines.setDisply());
                break;
                case "certification":
                    this.setVal({vehicles: this.vehicles.filter(v => !v.details || !v.details.certification)}, () => this.routines.setDisply());
                break;
                case "notVisible":
                    this.setVal({vehicles: this.vehicles.filter(v => !v.visible)}, () => this.routines.setDisply());
                break;
                default:
                    this.setVal({vehicles: []});
                    setTimeout(() => Spikes.notify("title", "checklist", "Validate Inventory Structure", `Type: Invalid | Total: 0`));
            }
        },
        changeType: event => this.setVal({filterType: event.target.value}, () => {
            this.routines.runFilter();
        })
    };

    componentDidMount() {
        setTimeout(() => window.scrollTo(0, 0));
        setTimeout(() => Spikes.notify("title", "checklist", "Validate Inventory Structure", "Loading..."));
        this.wHolder(BaseData.vehicles(vehicles => {
            this.vehicles = vehicles;
            this.routines.runFilter();
        }));
    }

    componentWillUnmount() {
        siteState.validateFilterVisible = this.state.visibleOnly;
        siteState.validateFilterType = this.state.filterType;
        this.setVal("end");
        this.wHolder("end");
    }

    content = {
        menu: <>
            <div className="optionsbar">
                <GoBack className="solo"><Icon name="backward" />Back</GoBack>
                <select className="solo" defaultValue={this.state.filterType} onChange={e => this.routines.changeType(e)}>{
                    this.filterTypes.map(f => <option key={f.value} value={f.value}>{f.caption}</option>)
                }</select>
                <SwitchBox
                    onChange={v => this.setVal({visibleOnly: v})}
                    value={this.state.visibleOnly}
                    size={26}
                    title="Visible Only"
                    style={{borderColor: "#CCCCCC", color: "#FFFFFF", marginTop: "2px"}}
                    rightLabel="Visible Only"
                />
                <span style={{float: "right"}}>
                    {/* {vehicle?.source?.asset.vin && <button className="solo" onClick={() => this.routines.getFromDataOne()}><Icon name="fromCloud" />Pull DataOne Data</button>}
                    {vehicle?.source?.asset.vin && <button className="solo" onClick={() => this.routines.getFromBlackBook()}><Icon name="book" />Pull BlackBook Data</button>}

                    {(this.state.changed || this.state.imageChanged) && <button className="solo" onClick={() => this.routines.saveListing()}><i className="fas fa-save"></i> Save Changes</button>}
                    {!(this.state.changed || this.state.imageChanged) && !this.state.vehicle.visible && <button className="solo" onClick={() => this.routines.setVisible()}><i className="fas fa-eye"></i> Set Visible</button>}
                    {!(this.state.changed || this.state.imageChanged) && this.state.vehicle.visible && <button className="solo" onClick={() => this.routines.setVisible()}><i className="fas fa-eye-slash"></i> Set Invisible</button>} */}
                </span>
            </div>
        </>
    };

    render = () => <div>
        {this.content.menu}
        <VehiclesTable
            vehicles={this.state.vehicles}
            search={v => true}
            visibleOnly={this.state.visibleOnly}
        />
    </div>;
}