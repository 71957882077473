import React from "react";
import Spikes from "../../services/Spikes";
import flatVehicle from "./flatVehicle";

const VehiclesTable = props => {
    const vehicles = () => {
        let rx = (props.vehicles || []).filter(v => !props.visibleOnly || v.visible).filter(v => props.search(v)).map(s => s.flat ? s : flatVehicle(s));
        Spikes.notify("vehicleCount", {visible: rx.filter(v => v.visible).length, total: rx.length});
        return rx;
    };

    return props.isMobile ? <table className="vehicles" style={props.style}>
        <thead>
            <tr>
                <th rowSpan={3}>Hero</th>
                <th colSpan={3} style={{width: "100%"}}>Vehicle</th>
            </tr>
            <tr>
                <th>Likes</th>
                <th colSpan={2}>Ext Color</th>
            </tr>
            <tr>
                <th colSpan={3}>Certification</th>
            </tr>
            {/* <tr>
                <th colSpan={1}>ID</th>
                <th colSpan={1}>VIN</th>
                <th>Certification</th>
                <th>Price</th>
                <th>Int Color</th>
                <th>Views</th>
            </tr> */}
            <tr>
                <th colSpan={4}>Tag</th>
            </tr>
        </thead>
        {vehicles().map(v => <tbody style={{backgroundColor: v.visible ? "#FFFFFF" : "#DDDDDD", fontSize: "12px"}} key={v.id} onClick={() => Spikes.notify("navigate", `/vehicle?vehicleId=${v.id}`)}>
            <tr>
                <td rowSpan={3} style={{whiteSpace: "nowrap", height: "60px", width: "260px"}}>{(v.images.slice(0, 1) || []).map(img => <img key={img.url} src={img.url} alt="" style={{maxHeight: "60px"}} />)}</td>
                <td colSpan={3} style={{whiteSpace: "nowrap", width: "320px"}}>{([v.year, v.make, v.model, v.trim]).filter(i => i).join(" ")}</td>
            </tr>
            <tr>
                <td style={{whiteSpace: "nowrap"}}>{v.likedCount}</td>
                <td colSpan={2} style={{whiteSpace: "nowrap"}}>{v.exterior_color || "N/A"}</td>
            </tr>
            <tr>
                {/* <td colSpan={1} style={{whiteSpace: "nowrap"}}>{v.vin || ""}</td> */}
                <td colSpan={3} style={{whiteSpace: "nowrap"}}>{v.certification || ""}</td>
            </tr>
            {props.showTags && <tr>
                <td colSpan={4}>{v.tag}</td>
            </tr>}
                {/* <td style={{whiteSpace: "nowrap"}}>{v.body_type || ""}</td> */}
                {/* <td style={{whiteSpace: "nowrap"}}>{v.imageCount || ""}</td> */}
                
                
                {/* <td style={{whiteSpace: "nowrap"}}>{v.visible ? "True" : "False"}</td> */}
                {/* <td colSpan={1} style={{whiteSpace: "nowrap"}}>{v.id}</td> */}
                
                {/* <td style={{whiteSpace: "nowrap"}}>${Spikes.numberCS(v.price)}</td> */}
                {/* <td style={{whiteSpace: "nowrap"}}>{v.interior_color || "N/A"}</td> */}
                {/* <td style={{whiteSpace: "nowrap"}}>{v.views || 0}</td> */}
            
        </tbody>)}
    </table> : <table className="vehicles" style={props.style}>
        <thead>
            <tr>
                <th rowSpan={3}>Hero</th>
                <th>Vehicle</th>
                <th>B. Type</th>
                <th>Images</th>
                <th>Likes</th>
                <th>Ext Color</th>
                <th>Visible</th>
            </tr>
            <tr>
                <th colSpan={1}>ID</th>
                <th colSpan={1}>VIN</th>
                <th>Certification</th>
                <th>Price</th>
                <th>Int Color</th>
                <th>Views</th>
            </tr>
            <tr>
                <th colSpan={6}>Tag</th>
            </tr>
        </thead>
        {vehicles().map(v => <tbody style={{backgroundColor: v.visible ? "#FFFFFF" : "#DDDDDD", fontSize: "12px"}} key={v.id} onClick={() => Spikes.notify("navigate", `/vehicle?vehicleId=${v.id}`)}>
            <tr>
                <td rowSpan={3} style={{whiteSpace: "nowrap", height: "60px", width: "260px"}}>{(v.images || []).map(img => <img key={img.url} src={img.url} alt="" style={{maxHeight: "60px"}} />)}</td>
                <td style={{whiteSpace: "nowrap", width: "320px"}}>{([v.year, v.make, v.model, v.trim]).filter(i => i).join(" ")}</td>
                <td style={{whiteSpace: "nowrap"}}>{v.body_type || ""}</td>
                <td style={{whiteSpace: "nowrap"}}>{v.imageCount || ""}</td>
                <td style={{whiteSpace: "nowrap"}}>{v.likedCount}</td>
                <td style={{whiteSpace: "nowrap"}}>{v.exterior_color || "N/A"}</td>
                <td style={{whiteSpace: "nowrap"}}>{v.visible ? "True" : "False"}</td>
            </tr>
            <tr>
                <td colSpan={1} style={{whiteSpace: "nowrap"}}>{v.id}</td>
                <td colSpan={1} style={{whiteSpace: "nowrap"}}>{v.vin || ""}</td>
                <td colSpan={1} style={{whiteSpace: "nowrap"}}>{v.certification || ""}</td>
                <td style={{whiteSpace: "nowrap"}}>${Spikes.numberCS(v.price)}</td>
                <td style={{whiteSpace: "nowrap"}}>{v.interior_color || "N/A"}</td>
                <td style={{whiteSpace: "nowrap"}}>{v.views || 0}</td>
            </tr>
            <tr>
                <td colSpan={6} style={{overflow: "hidden"}}>{v.tag}</td>
            </tr>
        </tbody>)}
    </table>;
};
export default VehiclesTable;