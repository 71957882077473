import React from "react";
import Spikes from "../../services/Spikes";
import Icon from "../../directives/Icon";
import styles from "./comm.module.css";
import BaseApi from "../../services/BaseApi";
import BaseData from "../../services/BaseData";
import ModalFrame from "../../directives/ModalFrame";

export default class SmsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSms: false,
            sms: [],
            smsMedia: [],
            profileId: false,
            profile: false,
            isMobile: props.isMobile,
            alert: false
        };
        this.profileWatcher = false;
    }

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();
    ref = {
        smsWindow: React.createRef(),
        smsBody: React.createRef(),
        smsInput: React.createRef()
    };
    routines = {
        clearProfileWatcher: () => {
            if (typeof(this.profileWatcher) === "function") {
                this.profileWatcher();
                this.profileWatcher = false;
            }
        },
        baseSms: () => {
            if (this.state.showSms && this.ref.smsBody.current) {
                Spikes.constrain(this.ref.smsBody.current.parentNode, {top: 41, bottom: 25});
                this.ref.smsBody.current.scrollTop = this.ref.smsBody.current.scrollHeight;
            }
        },
        sms: {
            send: () => {
                if (this.state.profileId && this.ref.smsInput.current) {
                    let media = this.state.smsMedia;
                    this.setVal({enableSend: false, smsMedia: []}, () => {
                        this.ref.smsInput.current.disabled = true;
                        BaseApi.comm.sendSms(this.state.profileId, this.ref.smsInput.current.value, media)(data => {
                            if (data.success && this.ref.smsInput.current) {
                                this.ref.smsInput.current.value = "";
                                this.ref.smsInput.current.disabled = false;
                                this.setVal({enableSend: false});
                            }
                        });
                    });
                }
            },
            keyDown: event => {
                if (event.code === "Enter") {
                    event.preventDefault();
                    this.routines.sms.send();
                }
            },
            enableSend: event => {
                if (event.target.value && !this.state.enableSend) {
                    this.setVal({enableSend: true});
                } else if (!event.target.value && this.state.enableSend) {
                    this.setVal({enableSend: false});
                }
            },
            removeMedia: src => {
                let media = this.state.smsMedia;
                Spikes.first(media.filter(m => m === src))(m => {
                    Spikes.remove(media, m);
                    this.setVal({smsMedia: media});
                });
            },
            readSms: () => {
                if ((this.state.profile.index?.smsLast || 0) > (this.state.profile.index?.smsRead)) {
                    BaseApi.comm.readSms(this.state.profile.id)();
                }
            }
        },
    };

    componentDidMount() {
        this.wHolder(Spikes.watch("isMobile")(isMobile => this.setVal({isMobile: isMobile})));
        this.wHolder(Spikes.watch("smsProfile")((profileId, show) => {
            console.log("smsProfile", profileId, show);
            if (profileId !== this.state.profileId) {
                this.routines.clearProfileWatcher();
                this.profileWatcher = BaseData.profile(profileId, profile => {
                    console.log("Profile:", profile);
                    this.setVal({
                        profile: profile,
                        profileId: profileId || false,
                        sms: Array.isArray(profile?.sms) ? profile.sms : [],
                    }, () => this.routines.baseSms());
                });
            }
            if (show && !this.state.showSms) {
                this.setVal({showSms: true}, () => this.routines.baseSms());
            }
        }));
        this.wHolder(Spikes.watch("remoteSms")(sms => {
            if (this.state.profileId && this.state.showSms) {
                BaseApi.comm.sendSms(this.state.profileId, sms.body, sms.media)(data => {
                    Spikes.notify("pop", {
                        group: "info",
                        message: `Vehicle Reference Sent`,
                        icon: "sms",
                        timeout: 10000
                    });
                });
            } else {
                Spikes.notify("pop", {
                    group: "danger",
                    message: `Please have the SMS window open to send Vehicle Refernce Links`,
                    icon: "sms",
                    timeout: 10000
                });
            }
        }));
        this.wHolder(Spikes.drag.watch()((action, drag) => {
            if (action === "drop" && drag.dest === "smsImage") {
                this.setVal({smsMedia: [...this.state.smsMedia, drag.elem.src]});
            }
        }));
        this.wHolder(Spikes.cycle(() => {
            if (this.state.profile?.index) {
                if ((this.state.profile.index.smsLast || 0) > (this.state.profile.index.smsRead || 0)) {
                    this.setVal({alert: !this.state.alert});
                } else {
                    this.setVal({alert: false});
                }
            } else {
                this.setVal({alert: false});
            }
        }, 1));
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
        this.routines.clearProfileWatcher();
    }
    render = () => !this.state.showSms ? null : <ModalFrame
        onClose={() => this.setVal({showSms: false})}
        width="auto"
        self={this}
    >
        <span><Icon name="sms" size="20px" />SMS - {Spikes.asPhone(this.state.profileId)}</span>
        <div
            className={styles.smsDisplay}
            ref={this.ref.smsBody}
            onDragOver={e => Spikes.drag.onOver(e)}
            onDrop={e => Spikes.drag.onDrop(e)}
            dropname="smsImage"
            onClick={() => this.routines.sms.readSms()}
            style={{backgroundColor: this.state.alert ? "#FFDDDD" : "#FFFFFF"}}
        >
            {this.state.sms.map(msg => <div key={this.state.profileId + "-" + msg.id} style={{textAlign: msg.userId ? "right" : "left"}}>
                <span className={styles.smsMessageBubble} style={msg.userId === 0 ? {borderColor: "#68a168", backgroundColor: "#93ed93"} : {borderColor: "#595cff", backgroundColor: "#9cf4f7"}}>
                    <span className={styles.smsMessageName}>{msg.userId ? "User" : "Recipient"}</span>
                    <span className={styles.smsMessageName}>{Spikes.date.lDateTime(msg.stamp)}</span>
                    <span className={styles.smsMessageBody}>{msg.body}</span>
                    {Array.isArray(msg.media) && <span className={styles.smsMessageBody}>{msg.media.filter(m => m).map(media => <img
                        key={media.index}
                        src={media.url}
                        style={{maxHeight: "60px", cursor: "pointer"}}
                        onLoad={() => this.routines.baseSms()}
                        alt=""
                        draggable="true"
                        onDragStart={e => Spikes.drag.onDrag(e, "smsImage")}
                        onDragEnd={e => Spikes.drag.onEnd(e)}
                        onClick={() => Spikes.notify("preview", media.url)}
                    />)}</span>}
                </span>
            </div>)}
            {this.state.smsMedia.length > 0 && <div style={{padding: "5px 5px 2px 5px", borderStyle: "solid", borderColor: "var(--color-2)", borderWidth: "1px 0px 0px 0px"}}>{this.state.smsMedia.map((m, i) => <img
                key={i}
                src={m}
                style={{maxHeight: "60px"}}
                onLoad={() => this.routines.baseSms()}
                alt=""
                onContextMenu={e => {e.preventDefault(); this.routines.sms.removeMedia(m);}}
            />)}</div>}
        </div>
        <table style={{width: "100%", borderSpacing: "0px", borderCollapse: "separate"}}>
            <tbody>
                <tr>
                    <td style={{padding: "0px"}}><input 
                        placeholder="Type Message Here..."
                        className={this.state.isMobile ? styles.smsInputMobile : styles.smsInputDesktop}
                        ref={this.ref.smsInput}
                        onChange={e => this.routines.sms.enableSend(e)}
                        onKeyDown={e => this.routines.sms.keyDown(e)}
                        onClick={() => this.routines.sms.readSms()}
                    /></td>
                    <td style={{padding: "0px"}}><button className={styles.smsSend} onClick={() => this.routines.sms.send()} disabled={!this.state.enableSend && !this.state.smsMedia.length}><Icon name="send" />Send</button></td>
                </tr>
            </tbody>
        </table>
    </ModalFrame>;
}