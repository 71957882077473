import React from "react";
import Spikes from "../../services/Spikes";
import siteState from "../../services/siteState";
import BaseApi from "../../services/BaseApi";
import BaseData from "../../services/BaseData";
import Icon from "../../directives/Icon";
import ModalFrame from "../../directives/ModalFrame";

const WorkSpaceSlip = props => <div className={props.highlight ? "workspace-slip-active" : "workspace-slip"} onClick={e => props.onClick(e)}>{props.ws.data?.name}</div>;
const NotSpaceSlip = props => <div className="workspace-slip-active" onClick={e => props.onClick(e)}><Icon name="add" margin="0" /><Icon name="desktop" />{Spikes.asPhone(props.profileId)}</div>;

class ActiveWorkSpaceSlip extends React.Component {
    vehicleId = Spikes.getQueryParam("vehicleId") || false;
    vehicles = [];
    state = {
        workSpace: false,
        profile: false,
        vehicle: false,
        vehicles: []
    };
    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();
    workSpaceSchema = source => {
        if (source) {
            if (!source.data) {source.data = {};}
            if (!source.data.vehicles) {source.data.vehicles = [];}
            return source;
        } else {
            return false;
        }
    };

    addVehicleMenu = {
        title: "Workspace Vehicles",
        menu: [
            {caption: <><Icon name="add" /><Icon name="car" />Add Vehicle To Workspace</>, click: () => {
                let workSpace = this.state.workSpace;
                workSpace.data.vehicles.push(this.state.vehicle.id);
                BaseApi.workSpace.save(workSpace.id, workSpace.data)(() => {
                    Spikes.notify("pop", {
                        group: "default",
                        message: `Vehicle Added to Workspace`,
                        icon: "car",
                        timeout: 10000
                    });
                });
            }}
        ]
    };

    selectVehicleMenu = vehicle => ({
        title: "Workspace Vehicles",
        menu: [
            {caption: <><Icon name="car" />View Vehicle Overview</>, click: () => {
                Spikes.notify("navigate", `/vehicle?vehicleId=${vehicle.id}`)
            }},
            {caption: <><Icon name="delete" />Remove Vehicle From Workspace</>, click: () => {
                let workSpace = this.state.workSpace;
                Spikes.remove(workSpace.data.vehicles, vehicle.id);
                BaseApi.workSpace.save(workSpace.id, workSpace.data)(() => {
                    Spikes.notify("pop", {
                        group: "default",
                        message: `Vehicle Removed from Workspace`,
                        icon: "car",
                        timeout: 10000
                    });
                });
            }}
        ]
    });

    workSpaceOptionsMenu = {
        title: "Workspace Options",
        menu: [
            {caption: <><Icon name="edit" />Edit Workspace Name</>, click: () => this.props.routines.editName()},
            {caption: <><Icon name="archive" />Archive Workspace</>, click: () => {}}
        ]
    };
    processVehicles = () => {
        if (this.state.workSpace) {
            this.setVal({vehicle: this.vehicles.find(v => this.vehicleId && !this.state.workSpace.data.vehicles.includes(this.vehicleId) && v.id === this.vehicleId) || false});
            this.setVal({vehicles: this.vehicles.filter(v => this.state.workSpace.data.vehicles.includes(v.id)).map(v => {
                if (!v.features) {v.features = {};}
                if (!v.images) {v.images = [];}
                return v;
            })});
        }
    };

    componentDidMount() {
        this.profileSelect = BaseData.profile(null, profile => this.setVal({profile: profile}, () => console.log("Profile:", this.state.profile)));
        this.wHolder(this.profileSelect);
        
        this.wHolder(BaseData.vehicles(vehicles => {
            this.vehicles = vehicles;
            this.processVehicles();
        }));

        this.wHolder(Spikes.watch("pathname")(pathname => {
            this.vehicleId = Spikes.getQueryParam("vehicleId") || false;
            this.processVehicles();
        }));

        this.wHolder(BaseData.workSpace(this.props.workSpaceId, workSpace => this.setVal({workSpace: this.workSpaceSchema(workSpace)}, () => {
            console.log("WorkSpace:", this.state.workSpace);
            if (this.state.workSpace) {this.profileSelect({id: this.state.workSpace.data.profileId || null});}
            this.processVehicles();
        })));
    }
    componentWillUnmount() {
        this.wHolder("end");
        this.setVal("end");
    }
    render() {
        return !this.state.workSpace ? null : <div className="activeworkspace-slip">
            <div className="header"><Icon name="desktop" />{this.state.workSpace.data.name}</div>
            <div className="optionsbar">
                <button className="solo" onClick={() => Spikes.notify("navigate", `/application?profileId=${this.state.workSpace.data?.profileId}`)} title="View Application"><Icon name="form" margin="0" /></button>
                <button className="solo" onClick={() => Spikes.notify("navigate", `/customer?profileId=${this.state.workSpace.data?.profileId}`)} title="View Profile"><Icon name="customer" margin="0" /></button>
                <button className="solo" onClick={() => Spikes.notify("callById", this.state.profile?.id)} title="Phone/SMS"><Icon name="phone" margin="0" /><Icon name="sms" margin="0" /></button>
                <span className="right">
                    <button className="solo" title="Options" onClick={(e) => Spikes.notify("menu", e, this.workSpaceOptionsMenu)}><Icon name="menu" margin="0" /></button>
                    <button className="solo" title="Close Workspace" onClick={() => this.props.routines.closeWorkSpace()}><Icon name="cancel" margin="0" /></button>
                </span>
            </div>
            <div className="pinfo">{this.state.profile?.global?.firstname} {this.state.profile?.global?.lastname}</div>
            <div className="pinfo">{Spikes.asPhone(this.state.profile?.id)}</div>
            {this.state.profile?.global?.email && <div className="pinfo">{this.state.profile.global.email}</div>}
            {this.state.profile?.global?.city && this.state.profile?.global?.province && <div className="pinfo">{this.state.profile.global.city}, {this.state.profile.global.province}</div>}
            {this.state.profile?.applications && <div className="pinfo">Application Status: {this.state.profile?.applications.reviewStatus || "N/A"}</div>}
            {this.state.profile?.applications && <div className="pinfo">Agent: {BaseApi.data.usersNameById(this.state.profile?.applications.userId)}</div>}
            <div className="body"></div>
            {this.state.vehicle && <div
                className="pvehicle"
                onClick={(e => Spikes.notify("menu", e, this.addVehicleMenu))}
                title={[this.state.vehicle.features.year, this.state.vehicle.features.make, this.state.vehicle.features.model, this.state.vehicle.features.trim].filter(i => i).join(" ")}
            >{
                (this.state.vehicle.images || []).slice(0, 1).map(i => <img key={i.url} src={i.url} style={{maxHeight: "30px", lineHeight: "30px", verticalAlign: "middle", marginRight: "5px"}} alt="" />)
            }{[this.state.vehicle.features.year, this.state.vehicle.features.make, this.state.vehicle.features.model, this.state.vehicle.features.trim].filter(i => i).join(" ")}</div>}
            {this.state.vehicles.map(v => <div
                key={v.id}
                className="vehicle"
                onClick={(e => Spikes.notify("menu", e, this.selectVehicleMenu(v)))}
                title={[v.features.year, v.features.make, v.features.model, v.features.trim].filter(i => i).join(" ")}
            >{
                (v.images || []).slice(0, 1).map(i => <img key={i.url} src={i.url} style={{maxHeight: "30px", lineHeight: "30px", verticalAlign: "middle", marginRight: "5px"}} alt="" />)
            }{
                [v.features.year, v.features.make, v.features.model, v.features.trim].filter(i => i).join(" ")
            }</div>)}
        </div>;
    }
};

class LeftMenuFrame extends React.Component {
    activeWorkSpace = false;
    workSpaceId = false;
    state = {
        openState: false,
        openLock: false,
        online: false,
        highlight: false,
        workSpaces: [],
        activeWorkSpace: this.activeWorkSpace,
        profileId: Spikes.getQueryParam("profileId"),
        nameModal: false
    };

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    routines = {
        open: () => {
            if (!this.state.openLock && !this.state.openState) {
                this.setVal({openState: true});
            }
        },
        close: () => {
            if (!this.state.openLock && this.state.openState) {
                this.setVal({openState: false});
            }
        },
        addWorkSpace: () => {
            this.activeWorkSpace = {
                id: false,
                data: {
                    profileId: this.state.profileId,
                    name: Spikes.asPhone(this.state.profileId),
                    vehicles: []
                }
            };
            this.setVal({
                activeWorkSpace: this.activeWorkSpace,
                nameModal: true
            });
        },
        closeWorkSpace: () => {
            this.workSpaceId = false;
            this.activeWorkSpace = false;
            this.setVal({activeWorkSpace: this.activeWorkSpace});
        },
        editName: () => this.setVal({nameModal: true}),
        save: () => {
            console.log("Saving");
            BaseApi.workSpace.save(this.state.activeWorkSpace.id, this.state.activeWorkSpace.data)(rx => {
                this.workSpaceId = rx.recordId;
                this.activeWorkSpace = this.state.workSpaces.find(ws => ws.id === this.workSpaceId) || false;
                this.setVal({
                    nameModal: false,
                    activeWorkSpace: this.activeWorkSpace
                });
            });
        },
        selectWorkSpace: ws => {
            this.workSpaceId = ws.id;
            this.activeWorkSpace = ws;
            this.setVal({activeWorkSpace: this.activeWorkSpace});
        }
    };

    componentDidMount() {
        this.wHolder(Spikes.watch("leftMenuOpen")(openState => this.setVal({openState: openState})));
        this.wHolder(Spikes.watch("leftMenuLock")(lock => this.setVal({openLock: lock})));
        this.setVal({openLock: siteState.fullLeft || false});
        this.wHolder(Spikes.watch("pathname")(pathname => this.setVal({profileId: Spikes.getQueryParam("profileId")})));
        this.wHolder(BaseData.workSpaces(workSpaces => this.setVal({workSpaces: workSpaces}, () => {
            if (this.workSpaceId) {
                this.activeWorkSpace = this.state.workSpaces.find(ws => ws.id === this.workSpaceId) || false;
                this.setVal({activeWorkSpace: this.activeWorkSpace});
            }
        })));
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    nameModal = () => <ModalFrame
        onClose={() => this.setVal({nameModal: false})}
    >
        <span><Icon name="add" margin="0" /><Icon name="desktop" />Add Workspace for: {Spikes.asPhone(this.state.profileId)}</span>
        <table style={{backgroundColor: "var(--color-6)", width: "100%"}}>
            <tbody>
                <tr>
                    <td style={{whiteSpace: "nowrap", textAlign: "right", height: "30px"}}>Workspace Name</td>
                    <td><input
                        type="text"
                        style={{
                            display: "block",
                            margin: "0px",
                            width: "100%"
                        }}
                        defaultValue={this.state.activeWorkSpace.data.name}
                        placeholder="Workspace Name"
                        onChange={e => {this.activeWorkSpace.data.name = e.target.value;}}
                    /></td>
                </tr>
                <tr>
                    <td colSpan={2} style={{textAlign: "right"}}>
                    <button 
                        onClick={() => this.routines.save()}
                        style={{height: "28px", minWidth: "60px", marginLeft: "4px", cursor: "pointer"}}
                    ><Icon name="save" />Save</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </ModalFrame>;

    openMenu = () => <div
        className="lmenu-frame-opened"
        style={{height: "100%", overflow: "auto"}}
        onMouseLeave={() => this.routines.close()}        
    >
        {this.state.nameModal && this.nameModal()}
        {this.state.activeWorkSpace && this.state.activeWorkSpace.id && <ActiveWorkSpaceSlip key={this.state.activeWorkSpace.id} workSpaceId={this.state.activeWorkSpace.id} routines={this.routines} />}
        {
            this.state.profileId && 
            this.state.profileId !== this.state.activeWorkSpace?.data?.profileId && 
            Spikes.first(this.state.workSpaces.filter(ws => ws.data && this.state.profileId === ws.data.profileId))(
                ws => <WorkSpaceSlip onClick={() => this.routines.selectWorkSpace(ws)} highlight={true} key={ws.id} ws={ws} />,
                () => <NotSpaceSlip onClick={() => this.routines.addWorkSpace()} profileId={this.state.profileId} />
            )
        }   
        {this.state.workSpaces.filter(ws => ws.data && ws.data.profileId !== this.state.profileId && ws.id !== this.state.activeWorkSpace?.id).map(ws => <WorkSpaceSlip onClick={() => this.routines.selectWorkSpace(ws)} highlight={false} key={ws.id} ws={ws} />)}
    </div>;

    closeMenu = () => <div className="lmenu-frame-closed" onMouseEnter={() => this.routines.open()}><i className="fas fa-mobile-alt"></i> <div className="rotate-r">Comms - {this.state.online ? <span style={{color: "#99ff99"}}>Online</span> : <span style={{color: "#ff9999"}}>Offline</span>}</div></div>;
    render() {return this.state.openState || this.state.openLock ? this.openMenu() : this.closeMenu();}
}

export default LeftMenuFrame;