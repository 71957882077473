import React from "react";
import Spikes from "../../services/Spikes";
import SocketConnection from "../../services/SocketConnection";

export default class MyAccountPage extends React.Component {
    wHolder = Spikes.watchHolder();
    
    componentDidMount() {
        setTimeout(() => window.scrollTo(0, 0));
        setTimeout(() => Spikes.notify("title", "user-settings", "Account Settings"));
        this.wHolder(SocketConnection("wss://api.consumergenius.com/test", sock => {
            sock.autoReconnect(500);
            sock.onReceive(data => {
                console.log("Sck onReceive:", data);
                console.log("Got Data", data);
            });
            sock.onClose(event => {
                console.log("Sck onClose:", event);
            });
            sock.onError(event => {
                console.log("Sck onError:", event);
            });
            sock.onConnect(event => {
                console.log("Sck onConnect:", event);
                sock.send({msg: "It's all good! CarMonk"});
            });
            sock.connect();
        }));
    }
    componentWillUnmount() {
        this.wHolder("end");
    }
    render = () => <div>
        <div style={{padding: "10px"}}><h3>Pending Update</h3></div>
    </div>;
}