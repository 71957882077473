/* eslint eqeqeq: 0 */
import React from "react";
import Spikes from "../../services/Spikes";
import Icon from "../../directives/Icon";
import TableView from "../../directives/TableView";

export default class FieldView2 extends React.Component {
    params = {
        cross: this.props.params[0],
        wrap: this.props.params[1],
        title: this.props.params[2],
        group: this.props.params[3],
        name: this.props.params[4],
        type: this.props.params[5],
        vehicle: this.props.params[6],
        sourceName: this.props.params[7],
        destName: this.props.params[8],
        list: this.props.params.length >= 10 ? this.props.params[9] : []
    };

    state = {
        edit: false,
        value: this.params.vehicle()[this.params.destName][this.params.name],
        source: this.params.vehicle()[this.params.sourceName][this.params.name]
    };

    setVal = Spikes.stateStream(this);

    componentDidMount() {
        this.params.cross.controls({
            name: this.params.name,
            group: this.params.group,
            sync: () => {
                this.setVal({
                    value: this.state.source,
                    edit: false
                }, () => this.params.cross.save(this.params.destName, this.params.name, this.state.value));
            },
            reset: () => {
                this.setVal({
                    value: this.params.vehicle()[this.params.destName][this.params.name],
                    source: this.params.vehicle()[this.params.sourceName][this.params.name],
                    edit: false
                }, () => this.params.cross.save(this.params.destName, this.params.name, this.state.value));
            }
        });
    }
    
    componentWillUnmount() {
        this.setVal("end");
    }

    routines = {
        edit: () => this.setVal({edit: true}),
        cancel: () => this.setVal({edit: false, value: this.params.vehicle()[this.params.destName][this.params.name]}),
        sync: event => {
            this.setVal({value: this.state.source}, () => {
                Spikes.first(Array.from(event.target.parentNode.childNodes).filter(n => n.tagName === "INPUT"))(cn => {cn.value = this.state.source;});
            });
        },
        save: () => this.setVal({edit: false}, () => this.params.cross.save(this.params.destName, this.params.name, this.state.value))
    };

    compair = v => [null, undefined, ""].includes(v) ? null : v;

    editContent = () => {
        switch (this.params.type) {
            case "string": return <span
                style={{position: "absolute", zIndex: 1}}
            ><button
                style={{borderRadius: "4px 0px 0px 4px", minWidth: "30px"}}
                title="Copy Source" onClick={e => this.routines.sync(e)}
            ><Icon name="copy" margin="0" /></button><input type="text" defaultValue={this.state.value} onChange={e => this.setVal({value: e.target.value})} style={{outline: "none"}} /><button style={{borderRadius: "0px", minWidth: "30px"}} title="Save" onClick={() => this.routines.save()}><Icon name="check" margin="0" /></button><button style={{borderRadius: "0px 4px 4px 0px", minWidth: "30px"}} title="Cancel" onClick={() => this.routines.cancel()}><Icon name="cancel" margin="0" /></button></span>;
            case "number": return <span
                style={{position: "absolute", zIndex: 1}}
            ><button
                style={{borderRadius: "4px 0px 0px 4px", minWidth: "30px"}}
                title="Copy Source" onClick={e => this.routines.sync(e)}
            ><Icon name="copy" margin="0" /></button><input type="number" defaultValue={[null, undefined, ""].includes(this.state.value) ? null : Spikes.toNumber(this.state.value)} onChange={e => this.setVal({value: e.target.value}, () => console.log(e.target.value))} style={{outline: "none"}} /><button style={{borderRadius: "0px", minWidth: "30px"}} title="Save" onClick={() => this.routines.save()}><Icon name="check" margin="0" /></button><button style={{borderRadius: "0px 4px 4px 0px", minWidth: "30px"}} title="Cancel" onClick={() => this.routines.cancel()}><Icon name="cancel" margin="0" /></button></span>;
            case "boolean": return <span style={{position: "absolute", zIndex: 1}}>
                <button style={{borderRadius: "4px 0px 0px 4px", minWidth: "100px", backgroundColor: this.state.value === true ? "#66FF66" : "#CCCCCC"}} title="True" onClick={e => this.setVal({value: true}, () => this.routines.save())}>True</button>
                <button style={{borderRadius: "0px 4px 4px 0px", minWidth: "100px", backgroundColor: this.state.value === false ? "#66FF66" : "#CCCCCC"}} title="False" onClick={e => this.setVal({value: false}, () => this.routines.save())}>False</button>
                <button style={{borderRadius: "0px 4px 4px 0px", minWidth: "100px", backgroundColor: ![true, false].includes(this.state.value) ? "#66FF66" : "#CCCCCC"}} title="N/A" onClick={e => this.setVal({value: null}, () => this.routines.save())}>N/A</button>
            </span>;
            case "select": return <span
                style={{position: "absolute", zIndex: 1}}
            ><button
                style={{borderRadius: "4px 0px 0px 4px", minWidth: "30px"}}
                title="Copy Source" onClick={e => this.routines.sync(e)}
            ><Icon name="copy" margin="0" /></button><select
                defaultValue={this.state.value}
                onChange={e => this.setVal({value: e.target.value})}
                style={{outline: "none"}}
            >{this.params.list.map(l => <option key={l.value} value={l.value}>{l.caption}</option>)}</select><button style={{borderRadius: "0px", minWidth: "30px"}} title="Save" onClick={() => this.routines.save()}><Icon name="check" margin="0" /></button><button style={{borderRadius: "0px 4px 4px 0px", minWidth: "30px"}} title="Cancel" onClick={() => this.routines.cancel()}><Icon name="cancel" margin="0" /></button></span>;
            default: return null;
        }
    };

    displayContent = () => <>{
        this.compair(this.state.value) !== this.compair(this.state.source) && <Icon name="edit" color="var(--theme-lt-hover-color)" />
    }{
        typeof(this.params.wrap) === "function" ? this.params.wrap(this.state.value) : this.state.value
    }</>;

    render = () => <div>
        <TableView.FieldTitle onClick={() => this.routines.edit()}>{this.params.title} [{typeof(this.params.wrap) === "function" ? this.params.wrap(this.state.source) : this.state.source}]</TableView.FieldTitle>
        {this.state.edit && this.editContent()}
        <TableView.FieldContent onClick={() => this.routines.edit()}>
            <span style={{cursor: "pointer", minWidth: "30px", minHeight: "14px"}}>
                {this.displayContent()}
            </span>
        </TableView.FieldContent>
    </div>;
}