import BaseApi from "./BaseApi";
import Spikes from "./Spikes";

let state = {
    data: {
        calls: false,
        vehicles: false,
        profiles: false,
        workSpaces: false,
        alive: false,
        settings: BaseApi.user.getSettings()
    },
    watchers: [],
    timers: {
        vehicles: null,
        profiles: null,
        calls: null,
        workSpaces: null,
        alives: null
    }
};

const isObject = v => v && typeof(v) === "object" && !Array.isArray(v);

const standardWatch = (id, cb, type, table, apiFunc) => {
    if (state.data[table]) {cb(state.data[table].find(d => d.id === id) || false);}
    else {apiFunc(data => {
        if (data.success) {
            state.data[table] = data.records.filter(v => !v.archive);
            cb(state.data[table].find(d => d.id === id) || false);
        }
    });}
    let watcher = {type: type, cb: cb, id: id};
    state.watchers.push(watcher);
    return options => {
        if (options === undefined) {
            Spikes.remove(state.watchers, watcher);
        } else if (isObject(options)) {
            if (options.id !== undefined) {
                watcher.id = options.id;
                if (watcher.id && state.data[table]) {watcher.cb(state.data[table].find(d => d.id === watcher.id) || false);}
                else {watcher.cb(false);}
            }
        }
    };
};

const BaseData = {
    vehicles: cb => {
        if (state.data.vehicles) {cb(state.data.vehicles.filter(v => !v.archive));}
        else {BaseApi.vehicles.listFull(data => {
            if (data.success) {
                state.data.vehicles = data.records;
                cb(state.data.vehicles.filter(v => !v.archive));
            }
        });}
        let watcher = {type: "vehicles", cb: cb};
        state.watchers.push(watcher);
        return () => Spikes.remove(state.watchers, watcher);
    },
    profiles: cb => {
        if (state.data.profiles) {cb(state.data.profiles);}
        else {BaseApi.customers.listFull(data => {
            if (data.success) {
                state.data.profiles = data.records;
                cb(state.data.profiles);
            }
        });}
        let watcher = {type: "profiles", cb: cb};
        state.watchers.push(watcher);
        return () => Spikes.remove(state.watchers, watcher);
    },
    calls: cb => {
        if (state.data.calls) {cb(state.data.calls);}
        else {BaseApi.comm.getCalls(data => {
            if (data.success) {
                state.data.calls = data.records;
                cb(state.data.calls);
            }
        });}
        let watcher = {type: "calls", cb: cb};
        state.watchers.push(watcher);
        return () => Spikes.remove(state.watchers, watcher);
    },
    workSpaces: cb => {
        if (state.data.workSpaces) {cb(state.data.workSpaces);}
        else {BaseApi.workSpace.get(data => {
            if (data.success) {
                state.data.workSpaces = data.records;
                cb(state.data.workSpaces);
            }
        });}
        let watcher = {type: "workSpaces", cb: cb};
        state.watchers.push(watcher);
        return () => Spikes.remove(state.watchers, watcher);
    },
    alives: cb => {
        if (state.data.alive) {cb(state.data.alive);}
        else {BaseApi.customers.getAlive(data => {
            if (data.success) {
                state.data.alive = data.records.map(d => d.id);
                cb(state.data.alive);
            }
        });}
        let watcher = {type: "alives", cb: cb};
        state.watchers.push(watcher);
        return () => Spikes.remove(state.watchers, watcher);
    },
    vehicle: (id, cb) => standardWatch(id, cb, "vehicle", "vehicles", BaseApi.vehicles.listFull),
    profile: (id, cb) => standardWatch(id, cb, "profile", "profiles", BaseApi.customers.listFull),
    call: (id, cb) => standardWatch(id, cb, "call", "calls", BaseApi.comm.getCalls),
    workSpace: (id, cb) => standardWatch(id, cb, "workSpace", "workSpaces", BaseApi.workSpace.get),
    alive: (id, cb) => {
        if (state.data.alive) {cb(state.data.alive.includes(id));}
        else {BaseApi.comm.getCalls(data => {
            if (data.success) {
                state.data.alive = data.records.map(d => d.id);
                cb(state.data.alive.includes(id));
            }
        });}
        let watcher = {type: "alive", cb: cb, id: id};
        state.watchers.push(watcher);
        return options => {
            if (options === undefined) {
                Spikes.remove(state.watchers, watcher);
            } else if (isObject(options)) {
                if (options.id !== undefined) {
                    watcher.id = options.id;
                    if (state.data.alive) {cb(state.data.alive.includes(watcher.id));}
                }
            }
        };
    },
    settings: cb => {
        if (state.data.settings) {cb(state.data.settings);}
        let watcher = {type: "settings", cb: cb};
        state.watchers.push(watcher);
        return () => Spikes.remove(state.watchers, watcher);
    }
};

Spikes.watch("dbVehicle")(vehicle => {
    console.log("Vehicle Update:", vehicle);
    if (state.data.vehicles) {
        let sVehicle = Spikes.first(state.data.vehicles.filter(d => d.id === vehicle.id))(
            d => {
                const vKeys = Object.keys(vehicle);
                Object.keys(d).filter(i => !vKeys.includes(i)).forEach(key => delete(d[key]));
                vKeys.forEach(key => {d[key] = vehicle[key];});
                return d;
            },
            () => {
                state.data.vehicles.push(vehicle);
                return vehicle;
            }
        );
        state.watchers.filter(w => w.id && w.type === "vehicle" && w.id === sVehicle.id).forEach(w => w.cb(sVehicle));
        state.data.vehicles.filter(v => v.archive).forEach(v => Spikes.remove(state.data.vehicles, v));
        clearTimeout(state.timers.vehicles);
        state.timers.vehicles = setTimeout(() => state.watchers.filter(w => w.type === "vehicles").forEach(w => w.cb(state.data.vehicles.filter(v => !v.archive))), 250);
    }
});

Spikes.watch("dbProfile")(profile => {
    if (state.data.profiles) {
        let sProfile = Spikes.first(state.data.profiles.filter(d => d.id === profile.id))(
            d => {
                const vKeys = Object.keys(profile);
                Object.keys(d).filter(i => !vKeys.includes(i)).forEach(key => delete(d[key]));
                vKeys.forEach(key => {d[key] = profile[key];});
                return d;
            },
            () => {
                state.data.profiles.push(profile);
                return profile;
            }
        );
        state.watchers.filter(w => w.id && w.type === "profile" && w.id === sProfile.id).forEach(w => w.cb(sProfile));
        clearTimeout(state.timers.profiles);
        state.timers.profiles = setTimeout(() => state.watchers.filter(w => w.type === "profiles").forEach(w => w.cb(state.data.profiles)), 250);
    }
});

Spikes.watch("call")(call => {
    if (state.data.calls) {
        let sCall = Spikes.first(state.data.calls.filter(d => d.id === call.id))(
            d => {
                const vKeys = Object.keys(call);
                Object.keys(d).filter(i => !vKeys.includes(i)).forEach(key => delete(d[key]));
                vKeys.forEach(key => {d[key] = call[key];});
                return d;
            },
            () => {
                state.data.calls.push(call);
                return call;
            }
        );
        state.watchers.filter(w => w.id && w.type === "call" && w.id === sCall.id).forEach(w => w.cb(sCall));
        clearTimeout(state.timers.calls);
        state.timers.calls = setTimeout(() => state.watchers.filter(w => w.type === "calls").forEach(w => w.cb(state.data.calls)), 250);
    }
});

Spikes.watch("dbWorkSpace")(workSpace => {
    if (state.data.workSpaces) {
        let sWorkSpace = Spikes.first(state.data.workSpaces.filter(d => d.id === workSpace.id))(
            d => {
                const vKeys = Object.keys(workSpace);
                Object.keys(d).filter(i => !vKeys.includes(i)).forEach(key => delete(d[key]));
                vKeys.forEach(key => {d[key] = workSpace[key];});
                return d;
            },
            () => {
                state.data.workSpaces.push(workSpace);
                return workSpace;
            }
        );
        state.watchers.filter(w => w.id && w.type === "workSpace" && w.id === sWorkSpace.id).forEach(w => w.cb(sWorkSpace));
        clearTimeout(state.timers.workSpaces);
        state.timers.workSpaces = setTimeout(() => state.watchers.filter(w => w.type === "workSpaces").forEach(w => w.cb(state.data.workSpaces)), 250);
    }
});

Spikes.watch("alive")(alive => {
    if (state.data.alive) {
        if (alive.alive && !state.data.alive.includes(alive.profileId)) {
            state.data.alive.push(alive.profileId);
        } else if (!alive.alive && state.data.alive.includes(alive.profileId)) {
            Spikes.remove(state.data.alive, alive.profileId);
        }
        state.watchers.filter(w => w.id && w.type === "alive" && w.id === alive.profileId).forEach(w => w.cb(alive.alive));
        clearTimeout(state.timers.alives);
        state.timers.alives = setTimeout(() => state.watchers.filter(w => w.type === "alives").forEach(w => w.cb(state.data.alive)), 250);
    }
});

Spikes.watch("userSettings")(settings => {
    state.data.settings = settings;
    state.watchers.filter(w => w.type === "settings").forEach(w => w.cb(state.data.settings));
});

export default BaseData;