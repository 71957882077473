import Spikes from "./Spikes";

const memory = {
    get: cookieName => {
        let i, x, y, ARRcookies = document.cookie.split(";"), rValue = "";
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, ""); 
            if (x === cookieName) {rValue = unescape(y);}
        } 
        return rValue || sessionStorage.getItem(cookieName);
    },
    set: (cookieName, cookieValue, expireInDays) => {
        sessionStorage.setItem(cookieName, cookieValue);
        let expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + (expireInDays || 30));
        document.cookie = `${cookieName}=${escape(cookieValue)}; expires=${expiryDate.toUTCString()}; Secure; SameSite=Lax`;
    }
};

let systemUser = {
    valid: false,
    key: memory.get("ukey"),
    id: 0,
    firstname: "Guest",
    lastname: "Guest",
    email: "Unknown",
    settings: {}
};

const apiUrl = (version, script, routine, format) => `https://dsa.carmonk.ca/${version}/${script}/${routine}/${format || 'jj'}`;

const apiReq = varObj => ({
    method: 'POST',
    headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 'authkey': systemUser.valid ? systemUser.key : ''},
    body: JSON.stringify(varObj || {})  
});

const resolveData = res => {
    if (res.ok) {
        try {return res.json();} 
        catch (e) {return Promise.reject({res: res, error: e});}
    } else {return Promise.reject({res: res});}
};

const networkState = () => {
    if (api.active > 2) {Spikes.notify("network", 1);} 
    else if (api.active > 4) {Spikes.notify("network", 2);} 
    else {Spikes.notify("network", 0);}
};

let api = {
    userWatchers: [],
    requests: [],
    active: 0,
    push: (version, script, routine, params, callback) => {
        api.active++;
        networkState();
        let errorCount = 0;
        const subRequest = () => {
            fetch(
                apiUrl(version, script, routine),
                apiReq(params)
            ).then(resolveData).then(data => {
                if (data.authExpired) {routines.login.logout();} 
                else if (typeof(callback) === "function") {callback(data);}
                if (data.updateActive) {Spikes.notify("updateActive");}
                api.active--;
                networkState();
            }).catch(error => {
                console.error("API Request Failed:", errorCount + 1, error);
                if (++errorCount < 3) {setTimeout(subRequest, 250);} else {api.active--;}
            });
        };
        subRequest();
    }
};

const routines = {
    config: (group, id) => {
        if (group === undefined) {return routines.data.configs;}
        else if (id === undefined) {return routines.data.configs.filter(c => c.group === group);}
        else if (typeof(id) === "number") {return routines.data.configs.find(c => c.group === group && c.value === id) || {group: group, name: "UNKNOWN", caption: "Unknown", description: "Requested config was not found.", value: -1};}
        else if (typeof(id) === "string") {return routines.data.configs.find(c => c.group === group && c.name === id.toUpperCase()) || {group: group, name: "UNKNOWN", caption: "Unknown", description: "Requested config was not found.", value: -1};}
        else {return {group: group, name: "INVALID", caption: "Invalid", description: "Invalid config request type.", value: -1};}
    },
    data: {
        users: [],
        configs: [],
        pendingApplications: [],
        getUserById: id => routines.data.users.find(u => u.id === id) || {},
        usersNameById: id => Spikes.first(routines.data.users.filter(u => u.id === id))(u => `${u.firstname} ${u.lastname}`, () => "N/A")
    },
    login: {
        login: (email, password) => callback => api.push("v1", "crmLogin", "login", {email: email, password: password}, data => {
            if (data.success) {
                systemUser = {
                    valid: true,
                    key: data.data.key,
                    id: data.data.id,
                    firstname: data.data.firstname,
                    lastname: data.data.lastname,
                    email: data.data.email,
                    settings: data.data.settings
                };
                Spikes.notify("userSettings", systemUser.settings);
                api.userWatchers.forEach(cb => cb(true));
                memory.set("ukey", data.data.key, 2);
            }
            callback(data);
        }),
        setPassword: email => callback => api.push("v1", "crmLogin", "setPassword", {email: email}, callback),
        savePassword: (key, password) => callback => api.push("v1", "crmLogin", "savePassword", {key: key, password: password}, callback),
        resetInfo: key => callback => api.push("v1", "crmLogin", "resetInfo", {key: key}, callback),
        logout: callback => api.push("v1", "crmLogin", "logout", {}, data => {
            memory.set("ukey", "", 1);
            systemUser = {
                valid: false,
                key: null,
                id: 0,
                firstname: "Guest",
                lastname: "Guest",
                email: "Unknown",
                settings: {}
            };
            if (typeof(callback) === "function") {callback(data);}
            if (!data.success) {console.log("Could not Logout");}
            api.userWatchers.forEach(cb => cb(false));
        }),
    },
    user: {
        stateWatcher: callback => {
            api.userWatchers.push(callback);
            return () => Spikes.remove(api.userWatchers, callback);
        },
        info: () => systemUser,
        getUserInfo: authkey => callback => api.push("v1", "crmUser", "getUserInfo", {authkey: authkey}, callback),
        getActive: lastId => callback => api.push("v1", "crmUser", "getActive", {lastId: lastId}, callback),
        lastActive: callback => api.push("v1", "crmUser", "lastActive", {}, callback),
        saveSettings: settings => callback => api.push("v1", "crmUser", "saveSettings", {settings: settings}, callback),
        getSettings: () => systemUser.settings
    },
    general: {
        listUsers: callback => api.push("v1", "crmGeneral", "listUsers", {}, callback),
        configs: callback => api.push("v1", "crmGeneral", "configs", {}, callback),
    },
    vehicles: {
        listFull: callback => api.push("v1", "proVehicles", "listFull", {}, callback),
        setVisible: (vehicleId, visible) => callback => api.push("v1", "crmVehicles", "setVisible", {vehicleId: vehicleId, visible: visible}, callback),
        saveNote: (vehicleId, note) => callback => api.push("v1", "proVehicles", "saveNote", {vehicleId: vehicleId, note: note}, callback),
        saveVehicle: (vehicleId, tag, details, features, options, images, dealer, history, note) => callback => api.push("v1", "crmVehicles", "saveVehicle", {
            vehicleId: vehicleId, 
            tag: typeof(tag) === "string" ? tag : false,
            details: details || false,
            features: features || false,
            options: options || false,
            dealer: dealer || false,
            images: images || false,
            history: history || false,
            note: note || false
        }, callback),
        saveFeatureSource: (vehicleId, featureSource) => callback => api.push("v1", "crmVehicles", "saveFeatureSource", {vehicleId: vehicleId, featureSource: featureSource}, callback),
        importVehicles: records => callback => api.push("v1", "crmImportVehicles", "importVehicles", {records: records}, callback),
        archiveVehicles: vehicleIds => callback => api.push("v1", "crmImportVehicles", "archiveVehicles", {vehicleIds: vehicleIds}, callback),
        updateDealer: records => callback => api.push("v1", "crmImportVehicles", "updateDealer", {records: records}, callback),
        saveSource: (vehicleId, source) => callback => api.push("v1", "proVehicles", "saveSource", {vehicleId: vehicleId, source: source}, callback),
        // getDocuments: vehicleId => callback => api.push("v1", "crmVehicles", "getDocuments", {vehicleId: vehicleId}, callback),
        // saveDocument: (vehicleId, description, file) => callback => api.push("v1", "crmVehicles", "saveDocument", {vehicleId: vehicleId, description: description, file: file}, callback),
        // dropDocument: (vehicleId, description, url) => callback => api.push("v1", "crmVehicles", "dropDocument", {vehicleId: vehicleId, description: description, url: url}, callback),
        // editDocument: (vehicleId, documentId, description) => callback => api.push("v1", "crmVehicles", "editDocument", {vehicleId: vehicleId, documentId: documentId, description: description}, callback),
        // deleteDocument: (vehicleId, documentId) => callback => api.push("v1", "crmVehicles", "deleteDocument", {vehicleId: vehicleId, documentId: documentId}, callback),
    },
    customers: {
        getAlive: callback => api.push("v1", "crmCustomers", "getAlive", {}, callback),
        listFull: callback => api.push("v1", "crmCustomers", "listFull", {}, callback),
        // delete: profileId => callback => api.push("v1", "crmCustomers", "delete", {profileId: profileId}, callback),
        saveNote: (profileId, note) => callback => api.push("v1", "crmCustomers", "saveNote", {profileId: profileId, note: note}, callback),
        saveDocument: (profileId, description, file) => callback => api.push("v1", "crmCustomers", "saveDocument", {profileId: profileId, description: description, file: file}, callback),
        dropDocument: (profileId, description, url) => callback => api.push("v1", "crmCustomers", "dropDocument", {profileId: profileId, description: description, url: url}, callback),
        editDocument: (profileId, documentId, description) => callback => api.push("v1", "crmCustomers", "editDocument", {profileId: profileId, documentId: documentId, description: description}, callback),
        deleteDocument: (profileId, documentId) => callback => api.push("v1", "crmCustomers", "deleteDocument", {profileId: profileId, documentId: documentId}, callback),
    },
    comm: {
        sendSms: (profileId, message, media) => callback => api.push("twl", "client", "sendSms2", {profileId: profileId, message: message, media: media || []}, callback),
        getAccessToken: callback => api.push("twl", "client", "getAccessToken", {}, callback),
        answer: callId => callback => api.push("twl", "client", "answer", {callId: callId}, callback),
        hold: callId => callback => api.push("twl", "client", "hold", {callId: callId}, callback),
        dial: number => callback => api.push("twl", "client", "dial", {number: number}, callback),
        getActive: callback => api.push("twl", "client", "getActive", {}, callback),
        getVoicemail: callback => api.push("twl", "client", "getVoicemail", {}, callback),
        getCallbacks: callback => api.push("twl", "client", "getCallbacks", {}, callback),
        getCalls: callback => api.push("v1", "crmCalls", "getCalls", {}, callback),
        setReviewed: callId => callback => api.push("twl", "client", "setReviewed", {callId: callId}, callback),
        cancelCallback: callId => callback => api.push("twl", "client", "cancelCallback", {callId: callId}, callback),
        readSms: profileId => callback => api.push("twl", "client", "readSms", {profileId: profileId}, callback),
    },
    blackBook: {
        vData: vin => callback => api.push("v1", "crmBlackBook", "vData", {vin: vin}, callback),
        drillUvc: (year, make, model, series, style) => callback => api.push("v1", "siteBlackBook", "drillUvc", {year: year, make: make, model: model, series: series, style: style}, callback),
        byVin: (vin, uvc) => callback => api.push("v1", "siteBlackBook", "byVin", {vin: vin, uvc: uvc || false}, callback),
        usedByUvc: (uvc, kilometers, province) => callback => api.push("v1", "siteBlackBook", "usedByUvc", {uvc: uvc || false, kilometers: kilometers || false, province: province || false}, callback),
        vinUvc: vin => callback => api.push("v1", "siteBlackBook", "usedByUvc", {vin: vin}, callback),
        basicByVin: vin => callback => api.push("v1", "crmBlackBook", "basicByVin", {vin: vin}, callback),
        equipmentByUvc: uvc => callback => api.push("v1", "crmBlackBook", "equipmentByUvc", {uvc: uvc}, callback),
    },
    dataOne: {
        vData: (vehicleId, vin, styleId) => callback => api.push("v1", "crmDataOne", "vData", {vehicleId: vehicleId, vin: vin, styleId: styleId || false}, callback),
        setSource: (vehicleId, source) => callback => api.push("v1", "crmDataOne", "setSource", {vehicleId: vehicleId, source: source}, callback),
    },
    applications: {
        saveNote: (applicationId, note) => callback => api.push("v1", "crmApplications", "saveNote", {applicationId: applicationId, note: note}, callback),
        updateReviewStatus: (profileId, reviewStatus) => callback => api.push("v1", "crmApplications", "updateReviewStatus", {profileId: profileId, reviewStatus: reviewStatus}, callback),
        updateComplete: (profileId, save) => callback => api.push("v1", "crmApplications", "updateComplete", {profileId: profileId, save: save}, callback),
        saveEquifax: (profileId, save) => callback => api.push("v1", "crmApplications", "saveEquifax", {profileId: profileId, save: save}, callback),
        saveTransUnion: (profileId, save) => callback => api.push("v1", "crmApplications", "saveTransUnion", {profileId: profileId, save: save}, callback),
        // pendingApplications: callback => api.push("v1", "crmApplications", "pendingApplications", {}, callback),
    },
    evox: {
        vifList: callback => api.push("v1", "crmEvox", "vifList", {}, callback),
        images: (vif, year) => callback => api.push("v1", "crmEvox", "images2", {vif: vif, year: year}, callback),
        saveEvoxSource: (vehicleId, evoxSource) => callback => api.push("v1", "crmEvox", "saveEvoxSource", {vehicleId: vehicleId, evoxSource: evoxSource}, callback),
        getEvoxSource: vehicleId => callback => api.push("v1", "crmEvox", "getEvoxSource", {vehicleId: vehicleId}, callback),
    },
    workSpace: {
        get: callback => api.push("v1", "crmWorkSpace", "getWorkSpaces", {}, callback),
        save: (workSpaceId, data) => callback => api.push("v1", "crmWorkSpace", "saveWorkSpace", {workSpaceId: workSpaceId || false, data: data || false}, callback)
    }
};

(() => {
    if (systemUser.key) {
        console.log("Session Key:", systemUser.key);
        routines.user.getUserInfo(systemUser.key)(data => {
            if (data.success) {
                systemUser.valid = true;
                systemUser.id = data.data.id;
                systemUser.firstname = data.data.firstname;
                systemUser.lastname = data.data.lastname;
                systemUser.email = data.data.email;
                systemUser.settings = data.data.settings;
                memory.set("ukey", systemUser.key, 2);
                api.userWatchers.forEach(cb => cb(true));
                Spikes.notify("userSettings", systemUser.settings);
            } else {
                memory.set("ukey", "", 1);
                api.userWatchers.forEach(cb => cb(false));
            }
        });
    }
})();

export default routines;