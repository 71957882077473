import React from "react";
import Icon from "../../directives/Icon";
import Spikes from "../../services/Spikes";
import BaseApi from "../../services/BaseApi";
import OrderH from "../../directives/OrderH";
import BreakSearch from "../../directives/BreakSearch";
import BaseData from "../../services/BaseData";
import SwitchBox from "../../directives/SwitchBox";

const parseReferrer = referrer => {
    if (referrer && typeof(referrer) === "string") {
        const refSource = referrer.split("/");
        if (refSource.length > 2) {
            const site = refSource[2].split(".");
            if (site.length > 1) {
                const domainE = site[site.length - 2];
                switch (domainE.toLowerCase()) {
                    case "facebook": return "Facebook";
                    case "google": return "Google";
                    case "twitter": return "Twitter";
                    case "youtube": return "YouTube";
                    case "android": return "Android";
                    case "carsfast": return "CarsFast";
                    default: return domainE;
                }
            }
        }
    }
};

const lastApp = (type, applications) => {
    if (Spikes.isObject(applications) && Array.isArray(applications.forms)) {
        return Spikes.last(applications.forms.filter(f => f.complete && f.type === type))(f => f, () => false);
    } else {
        return false;
    }
};

export default class ApplicationsPage extends React.Component {
    state = {
        applications: false,
        typeFilter: "",
        completeFilter: "0",
        reviewStatusFilter: "",
        alive: [],
        search: () => true,
        isMobile: this.props.isMobile,
        mine: false
    };
    lastField = false;
    orderBy = {
        field: "dateModified",
        desc: true,
        last: false
    };

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    ref = {
        typeFilter: React.createRef()
    };

    routines = {
        search: () => this.setVal({search: this.search}),
        order: (field, cb) => {
            if (!field) {
                this.orderBy.last = cb;
            } else if (this.orderBy.field === field) {
                this.orderBy.desc = !this.orderBy.desc;
                cb(this.orderBy.desc ? "up" : "down");
            } else {
                this.orderBy.field = field;
                this.orderBy.desc = false;
                cb("down");
                if (this.orderBy.last) {this.orderBy.last("");}
                this.orderBy.last = cb;
            }
            this.setVal({applications: Spikes.orderBy(this.state.applications, this.orderBy.field, this.orderBy.desc)});
        },
        addApplication: () => {
            Spikes.notify("addApplication");
        }
    };

    componentDidMount() {
        this.wHolder(Spikes.watch("isMobile")(isMobile => this.setVal({isMobile: isMobile})));
        setTimeout(() => window.scrollTo(0, 0));
        setTimeout(() => Spikes.notify("title", "form", "Applications"));

        this.wHolder(BaseData.profiles(profiles => {
            let completeApps = profiles.filter(s => !s.archive && s.applications && s.applications.forms && s.applications.forms.length).map(s => ({
                profileId: s.id,
                global: s.global,
                lastSms: Array.isArray(s.sms) && s.sms.length && typeof(s.sms[s.sms.length - 1].body) === "string" ? (s.sms[s.sms.length - 1].userId ? "> " : "< ") + s.sms[s.sms.length - 1].body.slice(0, 30) + (s.sms[s.sms.length - 1].body.length > 30 ? "..." : "") : "",
                qualify: lastApp("qualify", s.applications) ? true : false,
                purchase: lastApp("purchase", s.applications) ? true : false,
                trade: lastApp("trade", s.applications) ? true : false,
                dateModified: s.applications.dateModified,
                dateReviewed: s.applications.dateReviewed,
                reviewStatus: s.applications.reviewStatus,
                notesCount: Array.isArray(s.notes) ? s.notes.length : 0,
                userId: s.applications.userId,
                referrer: parseReferrer(s.referrer)
            }));
            this.setVal({
                applications: Spikes.orderBy(completeApps.map(r => Spikes.flatten(r)), this.orderBy.field, this.orderBy.desc)
            });
        }));
        this.wHolder(BaseData.alives(alives => this.setVal({alive: alives})));
        console.log("My ID:", BaseApi.user.info().id);
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    filterApps = () => (
        this.state.applications
        .filter(c => !this.state.mine || c.userId === BaseApi.user.info().id)
        .filter(c => this.state.completeFilter === "0" ? (c.qualify || c.purchase || c.trade) : !(c.qualify || c.purchase || c.trade))
        .filter(c => (!this.state.typeFilter || c[this.state.typeFilter]) && (this.state.reviewStatusFilter === "" || c.reviewStatus === this.state.reviewStatusFilter))
        .filter(this.state.search)
    );

    render = () => !this.state.applications ? <img src="/images/loading_rs2.gif" className="center-screen" alt="Loading..." /> : <div>
        <div className="optionsbar" style={{color: "var(--color-6)"}}>
            <BreakSearch
                name="customers"
                filters={{
                    "Firstname": "firstname",
                    "Lastname": "lastname",
                    "Phone": "profileId",
                    "Email": "email",
                    "City": "city",
                    "Referrer": "referrer"
                }}
                searchBack={sb => this.search = sb}
                processSearch={() => this.routines.search()}
            />
            <select defaultValue={this.state.completeFilter} onChange={e => this.setVal({completeFilter: e.target.value})} className="solo">
                <option value={"0"}>Complete</option>
                <option value={"1"}>Incomplete</option>
            </select>
            <select defaultValue={this.state.typeFilter} onChange={e => this.setVal({typeFilter: e.target.value})} className="solo">
                <option value={""}>Type: All</option>
                <option value={"qualify"}>Type: Qualify</option>
                <option value={"purchase"}>Type: Purchase</option>
                <option value={"trade"}>Type: Sell/Trade</option>
            </select>
            <select title="Review Status" defaultValue={this.state.reviewStatusFilter} onChange={e => this.setVal({reviewStatusFilter: e.target.value})} className="solo">
                <option value={""}>All Status</option>
                <option value={"Pending"}>Pending</option>
                <option value={"CarsFast.ca"}>CarsFast.ca</option>
                <option value={"Transfer"}>Transfer</option>
                <option value={"Reviewing"}>Reviewing</option>
                <option value={"Processing"}>Processing</option>
                <option value={"Accepted"}>Accepted</option>
                <option value={"Rejected"}>Rejected</option>
                <option value={"Invalid"}>Invalid</option>
                <option value={"Test"}>Test</option>
                <option value={"Approved"}>Approved</option>
                <option value={"Declined"}>Declined</option>
                <option value={"WON Deal"}>WON Deal</option>
            </select>
            {/* <button className="solo" onClick={() => this.routines.addApplication()}><Icon name="add" />New Application</button> */}
            <SwitchBox
                onChange={v => this.setVal({mine: v})}
                value={this.state.mine}
                size={26}
                title="My Applications"
                borderColor="var(--color-6)"
                style={{marginRight: "2px"}}
                rightLabel="My Apps"
            />
        </div>
        {this.state.isMobile ? <table className="wide">
            <thead>
                <tr>
                    <th><Icon name="vital" margin="0" style={{color: "#FF0000"}} title="Customer Is Online" /></th>
                    <th title="Qualify">Q</th>
                    <th title="Purchase">P</th>
                    <th title="Trade">T</th>
                    <th><Icon name="note" margin="0" /></th>
                    <OrderH onClick={this.routines.order} caption="Name" field="lastname" />
                </tr>
                <tr>
                    <OrderH onClick={this.routines.order} caption="Phone" field="profileId" colSpan={5} />
                    <OrderH onClick={this.routines.order} caption="Email" field="email" colSpan={2} />
                </tr>
                <tr>
                    <OrderH onClick={this.routines.order} caption="City" field="city" colSpan={5} />
                    <OrderH onClick={this.routines.order} caption="Province" field="province" colSpan={2} />
                </tr>
                <tr>
                    <OrderH onClick={this.routines.order} caption="Date Modified" field="dateModified" defaultValue="up" colSpan={5}/>
                    <OrderH onClick={this.routines.order} caption="Date Reviewed" field="dateReviewed" colSpan={2} />
                </tr>
                <tr>
                    <th colSpan={5}>Agent</th>
                    <OrderH onClick={this.routines.order} caption="Status" field="reviewStatus" colSpan={2} />
                </tr>
            </thead>
            {this.filterApps().map(c => (
                <tbody
                    className={c.dateModified > c.dateReviewed ? "hover-green" : "hover"}
                    key={c.profileId}
                    onClick={() => Spikes.notify("navigate", `/application?profileId=${c.profileId}`)}
                >
                    <tr>
                        <td>{this.state.alive.includes(c.profileId) && <Icon name="vital" margin="0" style={{color: "#FF0000"}} title="Customer Is Online" />}</td>
                        <td>{c.qualify && "Q"}</td>
                        <td>{c.purchase && "P"}</td>
                        <td>{c.trade && "T"}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.notesCount}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.lastname || ""}, {c.firstname || ""}</td>
                    </tr>
                    <tr>
                        <td style={{whiteSpace: "nowrap"}} colSpan={5}>{Spikes.asPhone(c.profileId)}</td>
                        <td style={{whiteSpace: "nowrap"}} colSpan={2}>{c.email || ""}</td>
                    </tr>
                    <tr>
                        <td style={{whiteSpace: "nowrap"}} colSpan={5}>{c.city || ""}</td>
                        <td style={{whiteSpace: "nowrap"}} colSpan={5}>{c.province || ""}</td>
                    </tr>
                    <tr>
                        <td style={{whiteSpace: "nowrap"}} colSpan={5}>{Spikes.date.lhDateTime(c.dateModified)}</td>
                        <td style={{whiteSpace: "nowrap"}} colSpan={2}>{c.dateReviewed ? Spikes.date.lhDateTime(c.dateReviewed) : "Pending"}</td>
                    </tr>
                    <tr>
                        <td style={{whiteSpace: "nowrap"}} colSpan={5}>{BaseApi.data.usersNameById(c.userId)}</td>
                        <td style={{whiteSpace: "nowrap"}} colSpan={2}>{c.reviewStatus || "Unknown"}</td>
                    </tr>
                </tbody>
            ))}
        </table> : <table className="wide">
            <thead>
                <tr>
                    <th><Icon name="vital" margin="0" style={{color: "#FF0000"}} title="Customer Is Online" /></th>
                    <th title="Qualify">Q</th>
                    <th title="Purchase">P</th>
                    <th title="Trade">T</th>
                    <OrderH onClick={this.routines.order} caption="Ref" field="referrer" />
                    <OrderH onClick={this.routines.order} caption="Name" field="lastname" />
                    <OrderH onClick={this.routines.order} caption="Phone" field="profileId" />
                    <OrderH onClick={this.routines.order} caption="City" field="city" />
                    <OrderH onClick={this.routines.order} caption="Status" field="reviewStatus" />
                    <th><Icon name="note" margin="0" /></th>
                    <OrderH onClick={this.routines.order} caption="Date Modified" field="dateModified" defaultValue="up" />
                    <OrderH onClick={this.routines.order} caption="Date Reviewed" field="dateReviewed" />
                    <th>Agent</th>
                    <th>Last SMS</th>
                </tr>
            </thead>
            {this.filterApps().map(c => (
                <tbody
                    className={c.dateModified > c.dateReviewed ? "hover-green" : "hover"}
                    key={c.profileId}
                    onClick={() => Spikes.notify("navigate", `/application?profileId=${c.profileId}`)}
                >
                    <tr>
                        <td>{this.state.alive.includes(c.profileId) && <Icon name="vital" margin="0" style={{color: "#FF0000"}} title="Customer Is Online" />}</td>
                        <td>{c.qualify && "Q"}</td>
                        <td>{c.purchase && "P"}</td>
                        <td>{c.trade && "T"}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.referrer || ""}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.lastname || ""}, {c.firstname || ""}</td>
                        <td style={{whiteSpace: "nowrap"}}>{Spikes.asPhone(c.profileId)}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.city || ""}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.reviewStatus || "Unknown"}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.notesCount}</td>
                        <td style={{whiteSpace: "nowrap"}}>{Spikes.date.lhDateTime(c.dateModified)}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.dateReviewed ? Spikes.date.lhDateTime(c.dateReviewed) : "Pending"}</td>
                        <td style={{whiteSpace: "nowrap"}}>{BaseApi.data.usersNameById(c.userId)}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.lastSms}</td>
                    </tr>
                </tbody>
            ))}
        </table>}
    </div>;
}