import React from "react";
import Spikes from "../../services/Spikes";
import siteState from "../../services/siteState";
import Icon from "../../directives/Icon";

class ScrollMinder extends React.Component {
    state = {
        network: 0,
        openLock: siteState.fullLeft || false,
        toTop: false
    };
    
    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    scrollState = () => {
        if (window.scrollY > 200 && !this.state.toTop) {this.setVal({toTop: true});} 
        else if (window.scrollY < 100 && this.state.toTop) {this.setVal({toTop: false});}
    };

    componentDidMount() {
        window.addEventListener("scroll", this.scrollState);
        this.wHolder(Spikes.watch("network")(networkState => this.setVal({network: networkState})));
        this.wHolder(Spikes.watch("leftMenuLock")(lock => this.setVal({openLock: lock})));
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollState);
        this.wHolder("end");
        this.setVal("end");
    }

    render() {
        return <>
            {this.state.toTop && <><button className="to-top" style={{left: this.state.openLock ? "313px" : "32px"}} onClick={() => window.scrollTo(0, 0)}><Icon name="arrow-up" />Top</button><button className="to-top" style={{right: "32px"}} onClick={() => window.scrollTo(0, 0)}><Icon name="arrow-up" />Top</button></>}
            {this.state.network === 1 && <Icon name="network" className="network" size="48px" style={{color: "#dec60d"}} />}
            {this.state.network === 2 && <Icon name="network" className="network" size="48px" style={{color: "#d1150f"}} />}
        </>;
    }
};
export default ScrollMinder;