/* eslint eqeqeq: 0 */
import React from "react";
import GoBack from "../../directives/GoBack";
import Icon from "../../directives/Icon";
import TableView from "../../directives/TableView";
import BaseApi from "../../services/BaseApi";
import Spikes from "../../services/Spikes";

export default class EvoxLibraryPage extends React.Component {
    constructor(props) {
        super(props);
        this.vehicleId = Spikes.getQueryParam("vehicleId")
        this.state = {
            vehicleId: this.vehicleId,
            vifList: false,
            years: [],
            makes: [],
            models: [],
            trims: [],
            body: [],
            fYear: "",
            fMake: "",
            fModel: "",
            fTrim: "",
            fBody: "",
            images: false,
            use: [],
            selected: {},
            title: "Vehicle Not Loaded"
        };
        this.vifList = [];
    }

    setVal = Spikes.stateStream(this);

    ref = {
        year: React.createRef(),
        make: React.createRef(),
        model: React.createRef(),
        trim: React.createRef(),
        body: React.createRef()
    };

    routines = {
        reduceFilters: (index, event) => {
            if (
                !index || 
                (index === 1 && event.target.value !== this.state.fYear) ||
                (index === 2 && event.target.value !== this.state.fMake) ||
                (index === 3 && event.target.value !== this.state.fModel) ||
                (index === 4 && event.target.value !== this.state.fTrim) ||
                (index === 5 && event.target.value !== this.state.fBody)
            ) {
                console.log("reduceFilters");
                let newState = {
                    years: [],
                    makes: [],
                    models: [],
                    trims: [],
                    body: [],
                    vifList: this.vifList
                };
                // Year
                newState.vifList.forEach(v => {
                    if (!newState.years.includes(v.yr)) {
                        newState.years.push(v.yr);
                    }
                });
                newState.years = newState.years.sort();
                if (index === 1) {
                    newState.fYear = event.target.value;
                } else if (this.state.fYear !== "" && !newState.years.includes(this.state.fYear)) {
                    newState.fYear = "";
                } else {
                    newState.fYear = this.state.fYear;
                }
                // Make
                newState.vifList = newState.vifList.filter(v => newState.fYear === "" || v.yr === newState.fYear);
                newState.vifList.forEach(v => {
                    if (!newState.makes.includes(v.make)) {
                        newState.makes.push(v.make);
                    }
                });
                newState.makes = newState.makes.sort();
                if (index === 2) {
                    newState.fMake = event.target.value;
                } else if (this.state.fMake !== "" && !newState.makes.map(c => Spikes.compair(c)).includes(Spikes.compair(this.state.fMake))) {
                    newState.fMake = "";
                } else {
                    newState.fMake = Spikes.first(newState.makes.filter(m => Spikes.compair(m) === Spikes.compair(this.state.fMake)))(m => m, () => "");
                }
                // Model
                newState.vifList = newState.vifList.filter(v => newState.fMake === "" || v.make === newState.fMake);
                newState.vifList.forEach(v => {
                    if (!newState.models.includes(v.model)) {
                        newState.models.push(v.model);
                    }
                });
                newState.models = newState.models.sort();
                if (index === 3) {
                    newState.fModel = event.target.value;
                } else if (this.state.fModel !== "" && !newState.models.map(c => Spikes.compair(c)).includes(Spikes.compair(this.state.fModel))) {
                    newState.fModel = "";
                } else {
                    newState.fModel = Spikes.first(newState.models.filter(m => Spikes.compair(m) === Spikes.compair(this.state.fModel)))(m => m, () => "");
                }
                // Trim
                newState.vifList = newState.vifList.filter(v => newState.fModel === "" || v.model === newState.fModel);
                newState.vifList.forEach(v => {
                    if (!newState.trims.includes(v.trim)) {
                        newState.trims.push(v.trim);
                    }
                });
                newState.trims = newState.trims.sort();
                if (index === 4) {
                    newState.fTrim = event.target.value;
                } else if (this.state.fTrim !== "" && !newState.trims.map(c => Spikes.compair(c)).includes(Spikes.compair(this.state.fTrim))) {
                    newState.fTrim = "";
                } else {
                    newState.fTrim = Spikes.first(newState.trims.filter(m => Spikes.compair(m) === Spikes.compair(this.state.fTrim)))(m => m, () => "");
                }
                // Body
                newState.vifList = newState.vifList.filter(v => newState.fTrim === "" || v.trim === newState.fTrim);
                newState.vifList.forEach(v => {
                    if (!newState.body.includes(v.body)) {
                        newState.body.push(v.body);
                    }
                });
                newState.body = newState.body.sort();
                if (index === 5) {
                    newState.fBody = event.target.value;
                } else if (this.state.fBody !== "" && !newState.body.map(c => Spikes.compair(c)).includes(Spikes.compair(this.state.fBody))) {
                    newState.fBody = "";
                } else {
                    newState.fBody = Spikes.first(newState.body.filter(m => Spikes.compair(m) === Spikes.compair(this.state.fBody)))(m => m, () => "");
                }
                newState.vifList = newState.vifList.filter(v => newState.fBody === "" || v.body === newState.fBody);
                this.setVal(newState);
            }
        },
        load: () => {
            let q = Spikes.queue();
            q.add("vif", cb => BaseApi.evox.vifList(data => cb(data.data || false)));
            q.add("profile", cb => {
                if (this.vehicleId) {
                    BaseApi.evox.getEvoxSource(this.vehicleId)(data => cb(data.record || false));
                } else {
                    cb(false);
                }
            });
            q.execute(res => {
                console.log("res:", res);
                if (res.vif) {
                    this.vifList = res.vif;
                    this.setVal({
                        use: res.profile ? res.profile.images : [],
                        images: [],
                        fYear: res.profile ? res.profile.year : "",
                        fMake: res.profile ? res.profile.make : "",
                        fModel: res.profile ? res.profile.model : "",
                        fTrim: res.profile ? res.profile.trim : "",
                        fBody: res.profile ? res.profile.body_type : "",
                        title: res.profile ? [
                            res.profile.year,
                            res.profile.make,
                            res.profile.model,
                            res.profile.trim,
                            res.profile.body_type,
                            `(${res.profile.exterior_color || "N/A"}/${res.profile.interior_color || "N/A"})`
                        ].filter(s => s).join(" ") : "Vehicle Not Loaded"
                    }, () => {
                        this.routines.reduceFilters();
                    });
                }
            });
        },
        getImages: li => {
            this.setVal({
                images: false,
                selected: li
            });
            BaseApi.evox.images(li.vif, li.yr)(data => {
                console.log("Evox ImageList:", data);
                if (data.success) {
                    this.setVal({images: data.images});
                }
            });
        },
        addImage: img => {
            this.setVal({use: [...this.state.use, {
                filename: img,
                url: "https://dsa.carmonk.ca/evoximg/" + img,
                type: "image", 
                content_type: "image/png",
                caption: [this.state.selected.yr, this.state.selected.make, this.state.selected.model, this.state.selected.trim].filter(s => s).join(" ")
            }]});
        },
        removeImage: img => {
            Spikes.remove(this.state.use, img);
            this.setVal({use: [...this.state.use]});
        },
        save: () => BaseApi.evox.saveEvoxSource(this.state.vehicleId, this.state.use)(data => {
            if (data.success) {Spikes.notify("navigate", `/vehicle?vehicleId=${this.state.vehicleId}`);}
        })
    };

    componentDidMount() {
        setTimeout(() => Spikes.notify("title", "images", "Evox Image Library"));
        this.routines.load();
    }
    componentWillUnmount() {
        this.setVal("end");
    }
    render() {
        if (!this.state.vifList || !this.state.images) {return <img src="/images/loading_rs2.gif" className="center-screen" alt="Loading..." />;}
        return <div>
            <div className="optionsbar">
                <GoBack className="solo"><Icon name="backward" />Back</GoBack>
                <button className="solo" onClick={() => this.routines.load()}><Icon name="refresh" />Refresh</button>
                <span className="right">
                    {this.state.vehicleId && this.state.images && <button className="solo" onClick={() => this.routines.save()}><Icon name="save" />Save To Vehicle</button>}
                </span>
            </div>
            <TableView.Info><div className="h2">{this.state.title}</div></TableView.Info>
            <div style={{padding: "10px"}}>
                <select onChange={e => this.routines.reduceFilters(1, e)} ref={this.ref.year} defaultValue={this.state.fYear}>
                    <option value="">Select</option>
                    {this.state.years.map(li => <option key={li} value={li}>{li}</option>)}
                </select>
                <select onChange={e => this.routines.reduceFilters(2, e)} ref={this.ref.make} defaultValue={this.state.fMake}>
                    <option value="">Select</option>
                    {this.state.makes.map(li => <option key={li} value={li}>{li}</option>)}
                </select>
                <select onChange={e => this.routines.reduceFilters(3, e)} ref={this.ref.model} defaultValue={this.state.fModel}>
                    <option value="">Select</option>
                    {this.state.models.map(li => <option key={li} value={li}>{li}</option>)}
                </select>
                <select onChange={e => this.routines.reduceFilters(4, e)} ref={this.ref.trim} defaultValue={this.state.fTrim}>
                    <option value="">Select</option>
                    {this.state.trims.map(li => <option key={li} value={li}>{li}</option>)}
                </select>
                <select onChange={e => this.routines.reduceFilters(5, e)} ref={this.ref.body} defaultValue={this.state.fBody}>
                    <option value="">Select</option>
                    {this.state.body.map(li => <option key={li} value={li}>{li}</option>)}
                </select>
            </div>
            <table className="wide">
                <thead>
                    <tr>
                        <th>VIF</th>
                        <th>Year</th>
                        <th>Make</th>
                        <th>Model</th>
                        <th>Trim</th>
                        <th>Doors</th>
                        <th>Body</th>
                        <th>Cab</th>
                        <th>Drv</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.vifList.slice(0, 100).map((li, idx) => <tr key={li["vif"] + "_" + idx} className="hover" onClick={() => this.routines.getImages(li)}>
                        <td style={{whiteSpace: "nowrap"}}>{li["vif"]}</td>
                        <td style={{whiteSpace: "nowrap"}}>{li["yr"]}</td>
                        <td style={{whiteSpace: "nowrap"}}>{li["make"]}</td>
                        <td style={{whiteSpace: "nowrap"}}>{li["model"]}</td>
                        <td style={{whiteSpace: "nowrap"}}>{li["trim"]}</td>
                        <td style={{whiteSpace: "nowrap"}}>{li["drs"]}</td>
                        <td style={{whiteSpace: "nowrap"}}>{li["body"]}</td>
                        <td style={{whiteSpace: "nowrap"}}>{li["cab"]}</td>
                        <td style={{whiteSpace: "nowrap"}}>{li["whls"]}</td>
                    </tr>)}
                </tbody>
            </table>
            {this.state.use.length > 0 && <div style={{padding: "10px"}}><h2>Selected Images</h2>{this.state.use.map(img => <img key={img.url} onClick={e => this.routines.removeImage(img)} src={img.url} alt={img.caption} style={{maxHeight: "200px", cursor: "pointer"}} />)}</div>}
            {this.state.images.length > 0 && <div style={{padding: "10px"}}><h2>Available Images</h2>{this.state.images.filter(img => !this.state.use.filter(u => u.filename === img).length).map(img => <img key={img} onClick={e => this.routines.addImage(img)} src={"https://dsa.carmonk.ca/evoximg/" + img} alt="" style={{maxHeight: "200px", cursor: "pointer"}} />)}</div>}
        </div>;
    }
}