import React from "react";
import Spikes from "../../services/Spikes";
import Icon from "../../directives/Icon";
import VehiclesTable from "../vehicles/VehiclesTable";
import BaseData from "../../services/BaseData";
import TableView from "../../directives/TableView";

export default class LikedVehiclesElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicles: false
        };
        this.vehicles = false;
        this.profile = false;
        this.updates = 0;
    }

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    routines = {
        sync: () => {
            if (this.vehicles && this.profile && this.profile.liked) {
                this.setVal({updates: ++this.updates, vehicles: this.vehicles.filter(v => Object.keys(this.profile.liked).includes(v.id)).map(s => {
                    let results = {
                        id: s.id,
                        tag: s.tag || null,
                        likedCount: s.liked ? Object.values(s.liked).length : 0,
                        visible: s.visible ? true : false
                    };
                    if (s.stats) {
                        results.views = s.stats.views || 0;
                    }
                    if (s.features) {
                        results.year = s.features.year || null;
                        results.make = s.features.make || null;
                        results.model = s.features.model || null;
                        results.trim = s.features.trim || null;
                        results.body_type = s.features.body_type || null;
                    }
                    if (s.images) {
                        results.images = s.images.slice(0, 3);
                        results.imageCount = s.images.length;
                    }
                    if (s.details) {
                        results.exterior_color = s.details.exterior_color || null;
                        results.interior_color = s.details.interior_color || null;
                        results.vin = s.details.vin || null;
                        results.price = s.details.price || null;
                        results.kilometers = s.kilometers || null;
                    }
                    return results;
                })});
            }
        }
    };

    componentDidMount() {
        if (this.props.profileId) {
            this.wHolder(BaseData.profile(this.props.profileId, profile => {
                this.profile = profile;
                this.routines.sync();
            }));
            this.wHolder(BaseData.vehicles(vehicles => {
                this.vehicles = vehicles;
                this.routines.sync();
            }));
        } else {
            console.log("Liked Vehicles: No profileId");
        }
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    render = () => !this.state.vehicles ? null : <div>
        <TableView.Header><Icon name="car" />Liked Vehicles</TableView.Header>
        <div style={{paddingLeft: "10px", paddingRight: "10px"}}>
            <VehiclesTable
                key={this.state.updates}
                vehicles={this.state.vehicles}
                search={() => true}
            />
        </div>
    </div>;
}