import React from "react";
import Spikes from "../../services/Spikes";
import Icon from "../../directives/Icon";

class PopMsg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popMsgs: []
        };
        this.mouseWatch = false;
        this.popIndex = 0;
        this.popMsgs = [];
    }

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();
    popRef = React.createRef();

    routines = {
        mouseMove: event => {
            if (this.popRef.current && this.state.popMsgs?.length) {
                if (
                    event.clientX > this.popRef.current.offsetLeft && 
                    event.clientX < this.popRef.current.offsetLeft + this.popRef.current.offsetWidth &&
                    event.clientY > this.popRef.current.offsetTop && 
                    event.clientY < this.popRef.current.offsetTop + this.popRef.current.offsetHeight
                ) {
                    this.popRef.current.style.opacity = "0.3";
                } else {
                    this.popRef.current.style.opacity = "1.0";
                }
            }
        },
        setMessages: msgs => {
            this.setVal({popMsgs: msgs});
            if (msgs.length && !this.mouseWatch) {window.addEventListener("mousemove", this.routines.mouseMove);}
            else if (!msgs.length && this.mouseWatch) {window.removeEventListener("mousemove", this.routines.mouseMove);}
        }
    };

    componentDidMount() {
        this.wHolder(Spikes.watch("pop")(data => {
            let pMsg = {
                id: ++this.popIndex,
                message: data.message,
                group: data.group || "default",
                icon: data.icon || false
            };
            this.popMsgs.push(pMsg);
            setTimeout(() => {
                Spikes.remove(this.popMsgs, pMsg);
                this.routines.setMessages(this.popMsgs);
            }, data.timeout || 5000);
            this.routines.setMessages(this.popMsgs);
        }));
        
    }
    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }
    render() {
        return !this.state.popMsgs.length ? null : <div
            style={{
                display: "block",
                position: "fixed",
                right: "30px",
                bottom: "35px",
                textAlign: "right",
                pointerEvents: "none",
                zIndex: 10
            }}
            ref={this.popRef}
        >{this.state.popMsgs.map(content => (
            <div
                key={content.id}
            >
                <span
                    className={(() => {
                        switch (content.group) {
                            case "info": return "info-box info-info";
                            case "danger": return "info-box info-danger";
                            case "warning": return "info-box info-warning";
                            default: return "info-box info-default";
                        }
                    })()}
                ><Icon name={content.icon} />{content.message}</span>
            </div>
        ))}</div>;
    }
}
export default PopMsg;