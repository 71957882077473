import React from "react";
import Spikes from "../../services/Spikes";
import BreakSearch from "../../directives/BreakSearch";
import Icon from "../../directives/Icon";
import OrderH from "../../directives/OrderH";
import BaseData from "../../services/BaseData";

export default class CustomersPage extends React.Component {
    state = {
        customers: false,
        alive: [],
        search: () => true,
        isMobile: this.props.isMobile
    };
    orderBy = {
        field: "dateModified",
        desc: true,
        last: false
    };

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    routines = {
        search: () => this.setVal({search: this.search}),
        order: (field, cb) => {
            if (!field) {
                this.orderBy.last = cb;
            } else if (this.orderBy.field === field) {
                this.orderBy.desc = !this.orderBy.desc;
                cb(this.orderBy.desc ? "up" : "down");
            } else {
                this.orderBy.field = field;
                this.orderBy.desc = false;
                cb("down");
                if (this.orderBy.last) {this.orderBy.last("");}
                this.orderBy.last = cb;
            }
            this.setVal({customers: Spikes.orderBy(this.state.customers, this.orderBy.field, this.orderBy.desc)});
        }
    };

    componentDidMount() {
        this.wHolder(Spikes.watch("isMobile")(isMobile => this.setVal({isMobile: isMobile})));
        setTimeout(() => window.scrollTo(0, 0));
        setTimeout(() => Spikes.notify("title", "customer", "Customers"));
        this.wHolder(BaseData.alives(alives => this.setVal({alive: alives})));
        this.wHolder(BaseData.profiles(profiles => {
            let customers = profiles.map(s => ({
                id: s.id,
                info: s.info || {},
                global: s.global || {},
                likedCount: Object.keys(s.liked || {}).length,
                dateAccessed: s.dateAccessed || 0,
                dateModified: s.dateModified || 0,
                dateCreated: s.dateCreated || 0,
                purchaseAppsCount: Array.isArray(s.forms) ? s.forms.filter(t => t.type === "purchase").length : 0,
                qualifyAppsCount: Array.isArray(s.forms) ? s.forms.filter(t => t.type === "qualify").length : 0,
                tradeAppsCount: Array.isArray(s.forms) ? s.forms.filter(t => t.type === "trade").length : 0,
                documents: Array.isArray(s.documents) ? s.documents.length : 0,
                notes: Array.isArray(s.notes) ? s.notes.length : 0,
                calls: s.calls && Array.isArray(s.calls.calls) ? s.calls.calls.length : 0,
                sms: Array.isArray(s.sms) ? s.sms.length : 0,
            }));
            this.setVal({customers: Spikes.orderBy(customers.map(r => Spikes.flatten(r)), this.orderBy.field, this.orderBy.desc)});
        }));
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    desktop = () => <div>
        <div className="optionsbar">
            <BreakSearch
                name="customers"
                filters={{
                    "Phone": "id",
                    "Firstname": "firstname",
                    "Lastname": "lastname",
                    "Email": "email"
                }}
                searchBack={sb => this.search = sb}
                processSearch={() => this.routines.search()}
            />
        </div>
        <table className="wide">
            <thead>
                <tr>
                    <th><Icon name="vital" margin="0" style={{color: "#FF0000"}} title="Customer Is Online" /></th>
                    <OrderH onClick={this.routines.order} caption="Phone" field="id" />
                    <OrderH onClick={this.routines.order} caption="Firstname" field="firstname" />
                    <OrderH onClick={this.routines.order} caption="Lastname" field="lastname" />
                    <OrderH onClick={this.routines.order} caption="Email" field="email" />
                    <OrderH onClick={this.routines.order} caption="Likes" field="likedCount" />
                    <th>Q. Apps</th>
                    <th>P. Apps</th>
                    <th style={{width: "14px"}}><Icon name="note" margin="0" /></th>
                    <th style={{width: "14px"}}><Icon name="document" margin="0" /></th>
                    <th style={{width: "14px"}}><Icon name="phone" margin="0" /></th>
                    <th style={{width: "14px"}}><Icon name="sms" margin="0" /></th>
                    <OrderH onClick={this.routines.order} caption="Date Created" field="dateCreated" />
                    <OrderH onClick={this.routines.order} caption="Date Modified" field="dateModified" defaultValue="up" />
                    <OrderH onClick={this.routines.order} caption="Date Accessed" field="dateAccessed" />
                </tr>
            </thead>
            {this.state.customers.filter(this.state.search).map(c => (
                <tbody
                    className="hover"
                    key={c.id}
                    onClick={() => Spikes.notify("navigate", `/customer?profileId=${c.id}`)}
                >
                    <tr>
                        <td>{this.state.alive.includes(c.id) && <Icon name="vital" margin="0" style={{color: "#FF0000"}} title="Customer Is Online" />}</td>
                        <td style={{whiteSpace: "nowrap"}}>{Spikes.asPhone(c.id)}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.firstname || ""}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.lastname || ""}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.email || ""}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.likedCount || "0"}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.qualifyAppsCount || "0"}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.purchaseAppsCount || "0"}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.notes || "0"}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.documents || "0"}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.calls || "0"}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.sms || "0"}</td>
                        <td style={{whiteSpace: "nowrap"}}>{Spikes.date.lhDateTime(c.dateCreated)}</td>
                        <td style={{whiteSpace: "nowrap"}}>{Spikes.date.lhDateTime(c.dateModified)}</td>
                        <td style={{whiteSpace: "nowrap"}}>{Spikes.date.lhDateTime(c.dateAccessed)}</td>
                    </tr>
                </tbody>
            ))}
        </table>
    </div>;

    mobileCustomerPane = c => <div
        key={c.id}
        className="mobile-customer-pane"
        onClick={() => Spikes.notify("navigate", `/customer?profileId=${c.id}`)}
    >
        <div><span>{Spikes.asPhone(c.id)} {this.state.alive.includes(c.id) && <Icon name="vital" margin="0" style={{color: "#FF0000"}} title="Customer Is Online" />}</span><span style={{position: "absolute", right: "4px"}}>{c.firstname} {c.lastname}</span></div>
    </div>;

    mobile = () => <div>
        <div className="optionsbar">
            <BreakSearch
                name="customers"
                filters={{
                    "Phone": "id",
                    "Firstname": "firstname",
                    "Lastname": "lastname",
                    "Email": "email"
                }}
                searchBack={sb => this.search = sb}
                processSearch={() => this.routines.search()}
            />
        </div>
        {this.state.customers.filter(this.state.search).map(c => this.mobileCustomerPane(c))}
    </div>;

    render = () => !this.state.customers ? <img src="/images/loading_rs2.gif" className="center-screen" alt="Loading..." /> : (this.state.isMobile ? this.mobile : this.desktop)();
}