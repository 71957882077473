import Spikes from "./Spikes";
const financing = {
    calculateTermRate: (_year, _price, callback) => {
        let year = Spikes.toNumber(_year);
        let price = Spikes.toNumber(_price) * 1.13;
        let term = 0;
        let rate = 0;
        if (year >= 2020) {term = 96;}
        else if (year >= 2019) {term = 84;}
        else if (year >= 2018) {term = 72;}
        else if (year >= 2017) {term = 72;}
        else if (year >= 2016) {term = 72;}
        else if (year >= 2015) {term = 60;}
        else if (year >= 2014) {term = 48;}
        else if (year >= 2013) {term = 36;}
        else {term = 24;}

        if (price >= 7500 && price < 12500) {rate = 3;}
        else if (price >= 12500 && price < 20000) {rate = 3.55;}
        else if (price >= 20000 && price < 30000) {rate = 4.7;}
        else if (price >= 30000 && price < 40000) {rate = 5;}
        else if (price >= 40000) {rate = 5;}
        else {rate = 5.98;}

        if (typeof(callback) === "function") {callback(term, rate);}
        return {term: term, rate: rate};
    },
    calculateFinancing: (_price, _term, _rate, _admin) => {
        let price = Spikes.toNumber(_price);
        let term = Spikes.toNumber(_term);
        let rate = Spikes.toNumber(_rate);
        let admin = Spikes.toNumber(_admin);
        if (price && term && rate) {
            let P = (price + admin) * 1.13;
            let R = rate / 100 / 26;
            let T = term / 12 * 26;
            let payment = P * (R * (1 + R) ** T) / ((1 + R) ** T - 1);
            return Math.ceil(payment);
        } else {
            return null;
        }
    }
};
export default financing;