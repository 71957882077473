import React from "react";
import Spikes from "../services/Spikes";
import Icon from "./Icon";

class EditField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            value: null,
            discrep: false,
            sIndex: -1
        };
        this.editName = Spikes.first(props.names)(n => n.name, () => null);
        Spikes.first(props.names.filter(n => ![undefined, null, ""].includes(props.parent[n.name])))(fv => {
            this.state.value = props.parent[fv.name];
            if (props.names.filter(n => ![undefined, null, ""].includes(props.parent[n.name]) && props.parent[n.name] !== props.parent[fv.name]).length) {
                this.state.discrep = true;
            }
        });
        if (props.type === "select" && Array.isArray(props.options)) {
            props.options.forEach((o, i) => {
                if (this.sType(o.value) === this.sType(this.state.value)) {
                    this.state.sIndex = i;
                }
            });
        }
    }

    editRef = React.createRef();

    setVal = Spikes.stateStream(this);

    onChange = event => {
        if (typeof(this.props.onChange) === "function") {
            switch (this.props.type) {
                case "string":
                    this.setVal({value: event.target.value}, () => {
                        this.props.onChange(this.props.parent, this.editName, event.target.value);
                    });
                break;
                case "select":
                    this.setVal({sIndex: event.target.value}, () => {
                        if (event.target.value > -1 && event.target.value < this.props.options.length && this.props.options[event.target.value].value) {
                            this.props.onChange(this.props.parent, this.editName, this.props.options[event.target.value].value);
                        }
                    });
                break;
                case "boolean":
                    this.setVal({value: event.target.value === "1" ? true : false}, () => {
                        this.props.onChange(this.props.parent, this.editName, this.state.value ? true : false);
                    });
                break;
                default: 
            }
        }
    };

    sType = v => {
        if (typeof(v) === "string") {return v;}
        else if (typeof(v) === "number") {return v.toString();}
        else if (typeof(v) === "boolean") {return v ? "1" : "0";}
        else {return "";}
    };

    bType = v => v ? "1" : "0";
    
    componentDidMount() {}

    render() {
        if (this.state.edit) {
            switch (this.props.type) {
                case "string": return <input
                    defaultValue={this.state.value}
                    style={{
                        display: "block",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "#000000",
                        borderRadius: "5px",
                        height: "24px",
                        lineHeight: "24px",
                        padding: "0px 5px 0px 5px",
                        width: "80%"
                    }}
                    ref={this.editRef}
                    onBlur={() => this.setVal({edit: false})}
                    onChange={e => this.onChange(e)}
                />;
                case "select": return <select
                    defaultValue={this.state.sIndex}
                    style={{
                        display: "block",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "#000000",
                        borderRadius: "5px",
                        height: "26px",
                        lineHeight: "26px",
                        padding: "0px 5px 0px 5px",
                        width: "80%"
                    }}
                    ref={this.editRef}
                    onBlur={() => this.setVal({edit: false})}
                    onChange={e => this.onChange(e)}
                >{this.props.options.map((o, i) => <option key={i} value={i}>{o.caption}</option>)}</select>;
                case "boolean": return <select
                    defaultValue={this.bType(this.state.value)}
                    style={{
                        display: "block",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "#000000",
                        borderRadius: "5px",
                        height: "26px",
                        lineHeight: "26px",
                        padding: "0px 5px 0px 5px",
                        width: "80%"
                    }}
                    ref={this.editRef}
                    onBlur={() => this.setVal({edit: false})}
                    onChange={e => this.onChange(e)}
                >{[{caption: "True", value: "1"}, {caption: "False", value: "0"}].map((o, i) => <option key={i} value={o.value}>{o.caption}</option>)}</select>;
                default: return null;
            }
        } else {
            return <div
                style={{
                    cursor: "pointer",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderColor: "#000000",
                    borderRadius: "5px",
                    paddingLeft: "5px",
                    height: "24px",
                    lineHeight: "24px"
                }}
                onClick={() => this.setVal({edit: true}, () => this.editRef.current?.focus())}
            ><span style={{display: "inline-block", verticalAlign: "middle", overflow: "hidden", whiteSpace: "nowrap"}}>{this.state.discrep && <Icon name="edit" color="var(--theme-lt-hover-color)" />}{(() => {
                switch (this.props.type) {
                    case "string": return typeof(this.props.format) === "function" ? this.props.format(this.state.value) : this.state.value;
                    case "select": return this.state.sIndex > -1 && this.state.sIndex < this.props.options.length ? this.props.options[this.state.sIndex].caption : "";
                    case "boolean": return this.state.value ? "True" : "False";
                    default: return null;
                }
            })()}</span></div>;
        }
    }
}
export default EditField;