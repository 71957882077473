import React from "react";
import Icon from "../../directives/Icon";
import Spikes from "../../services/Spikes";
import ModalFrame from "../../directives/ModalFrame";

class ImagePreviewModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preview: false
        };
    }

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    componentDidMount() {
        this.wHolder(Spikes.watch("preview")(src => this.setVal({preview: src})));
    }
    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    mask = () => (
        <div 
            style={{
                position: "fixed", 
                top: "0px", 
                left: "0px", 
                width: "100%", 
                height: "100%", 
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                zIndex: 5
            }}
            onClick={() => this.setVal({preview: false})}
        ></div>
    );

    preview = () => (
        <ModalFrame
            width="auto"
            contain={{}}
            onClose={() => this.setVal({preview: false})}
            self={this}
        >
            <span><Icon name="images" />Image Preview</span>
            <div
                style={{
                    display: "block",
                    backgroundColor: "var(--theme-lt-background-color)",
                    padding: "0px"
                }}
            ><img
                src={this.state.preview}
                style={{
                    maxHeight: (window.innerHeight - 100) + "px",
                    maxWidth: (window.innerWidth - 100) + "px",
                    display: "block"
                }}
                alt=""
            /></div>
        </ModalFrame>
    );

    render() {
        return !this.state.preview ? null : <>
            {this.mask()}
            {this.preview()}
        </>;
    }
}
export default ImagePreviewModal;