import React from "react";
import Spikes from "../../services/Spikes";
import Icon from "../../directives/Icon";
import styles from "../../frames/modal/comm.module.css";
import DocumentElement from "./DocumentsElement";
import LikedVehiclesElement from "./LikedVehiclesElement";
import BaseData from "../../services/BaseData";
import CallsElement from "./CallsElement";
import TableView from "../../directives/TableView";
import GoBack from "../../directives/GoBack";
import BaseApi from "../../services/BaseApi";

export default class CustomerPage extends React.Component {
    profileId = Spikes.getQueryParam("profileId");
    state = {
        profile: false,
        navigate: false,
        vehicles: [],
        applications: [],
        sms: [],
        isMobile: this.props.isMobile
    };

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    ref = {
        notes: React.createRef(),
        history: React.createRef(),
        sms: React.createRef()
    };

    routines = {
        scroll: elem => setTimeout(() => {
            if (this.ref.notes.current && (!elem || elem === "notes")) {this.ref.notes.current.scrollTop = this.ref.notes.current.scrollHeight;}
            if (this.ref.history.current && (!elem || elem === "history")) {this.ref.history.current.scrollTop = this.ref.history.current.scrollHeight;}
            if (this.ref.sms.current && (!elem || elem === "sms")) {this.ref.sms.current.scrollTop = this.ref.sms.current.scrollHeight;}
        }),
        load: data => {
            if (data.success) {
                this.setVal({
                    profile: data.record,
                    sms: Array.isArray(data.record?.sms) ? data.record?.sms : []
                }, () => {
                    setTimeout(() => Spikes.notify("title", "customer", `Customer - ${Spikes.asPhone(this.state.profile.id)}`, `Likes: ${this.state.profile.liked ? Object.keys(this.state.profile.liked).length : 0} | Notes: ${(this.state.profile.notes || []).length}`));
                    this.routines.scroll();
                });
            } else {
                Spikes.notify("navigate", "/customers");
            }
        },
        editNote: note => Spikes.notify("CustomerNoteModal", this.profileId, note),
        callProfile: () => Spikes.notify("callProfile", this.state.profile),
    };

    componentDidMount() {
        this.wHolder(Spikes.watch("isMobile")(isMobile => this.setVal({isMobile: isMobile})));
        setTimeout(() => window.scrollTo(0, 0));
        setTimeout(() => Spikes.notify("title", "customer", `Customer - Loading...`));
        // this.routines.getApplications();
        this.wHolder(Spikes.watch("sms")(sms => {
            if (this.profileId === sms.profileId) {
                this.setVal({
                    sms: [...this.state.sms, sms.sms]
                }, () => {
                    this.routines.scroll("sms");
                });
            }
        }));
        this.wHolder(BaseData.profile(this.profileId, profile => {
            if (profile) {
                this.setVal({
                    profile: profile,
                    sms: Array.isArray(profile.sms) ? profile.sms : []
                }, () => {
                    setTimeout(() => Spikes.notify("title", "customer", `Customer - ${Spikes.asPhone(this.state.profile.id)}`, `Likes: ${this.state.profile.liked ? Object.keys(this.state.profile.liked).length : 0} | Notes: ${(this.state.profile.notes || []).length}`));
                    this.routines.scroll();
                });
            } else {
                Spikes.notify("navigate", "/customers");
            }
        }));
        Spikes.notify("workSpaceView", this.profileId);
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }


    desktop = profile => <div>
        <div className="optionsbar">
            <GoBack className="solo"><Icon name="backward" />Back</GoBack>
            {/* <button className="solo" onClick={() => this.routines.get()}><Icon name="refresh" />Refresh</button> */}
            <button className="solo" onClick={() => this.routines.callProfile()}><Icon name="phone" />Call / <Icon name="sms" />SMS</button>
            <button className="solo" onClick={() => Spikes.notify("navigate", `/application?profileId=${this.profileId}`)}><Icon name="form" />Application</button>
            <span className="right">
                {/* <button className="solo" onClick={() => this.routines.delete()}><i className="fas fa-trash"></i>Delete</button> */}
            </span>
        </div>

        <TableView.Header><Icon name="customer" />Profile</TableView.Header>
        <TableView.Body>
            <TableView.Table>
                <TableView.Column colSpan="6">
                    <TableView.FieldView title="Firstname" content={profile.global?.firstname || "N/A"} />
                    <TableView.FieldView title="Lastname" content={profile.global?.lastname || "N/A"} />
                    <TableView.FieldView title="Phone Number" content={Spikes.asPhone(profile.id)} />
                    <TableView.FieldView title="E-mail Address">{profile.global?.email ? <a href={"mailto:" + profile.global?.email} target="_new">{profile.global?.email}</a> : "N/A"}</TableView.FieldView>
                </TableView.Column>
                <TableView.Column colSpan="6">
                    <TableView.FieldView title="Street" content={profile.global?.street || "N/A"} />
                    <TableView.FieldView title="City" content={profile.global?.city || "N/A"} />
                    <TableView.FieldView title="Province" content={profile.global?.province || "N/A"} />
                    <TableView.FieldView title="PostalCode" content={profile.global?.postalCode || "N/A"} />
                </TableView.Column>
            </TableView.Table>
        </TableView.Body>

        <LikedVehiclesElement profileId={this.profileId} />

        <TableView.Table>
            <TableView.Column colSpan="5">
                <TableView.Header><Icon name="note" />Notes<Icon name="add" className="link" title="Add Note" style={{float: "right"}} onClick={() => this.routines.editNote()} /></TableView.Header>
                <TableView.Body style={{height: "300px", overflow: "auto"}} ref={this.ref.notes}>{
                    (profile.notes || []).map((note, index) => <div key={index} style={{cursor: "pointer"}} onClick={() => this.routines.editNote(note)}>
                        <TableView.FieldView title={Spikes.date.lhDateTime(note.stamp) + " [" + BaseApi.data.usersNameById(note.userId) + "]"} content={note.note.split("\n").map((n, i) => <div key={i}>{n}</div>)} />
                    </div>)
                }</TableView.Body>
            </TableView.Column>
            <TableView.Column colSpan="4">
                <DocumentElement key={this.profileId} profileId={this.profileId} />
            </TableView.Column>
            <TableView.Column colSpan="3">
                <TableView.Header><Icon name="history" />History</TableView.Header>
                <TableView.Body style={{height: "300px", overflow: "auto"}} ref={this.ref.history}></TableView.Body>
            </TableView.Column>
        </TableView.Table>

        <TableView.Table>
            <TableView.Column colSpan="5">
                <TableView.Header><Icon name="sms" />SMS</TableView.Header>
                <TableView.Body style={{height: "300px", overflow: "auto"}} ref={this.ref.sms}>{
                    (this.state.sms || []).map(msg => <div key={this.profileId + "-" + msg.id} style={{textAlign: msg.userId ? "right" : "left"}}>
                        <span className={styles.smsMessageBubble} style={msg.userId === 0 ? {borderColor: "#68a168", backgroundColor: "#93ed93"} : {borderColor: "#595cff", backgroundColor: "#9cf4f7"}}>
                            <span className={styles.smsMessageName}>{msg.userId ? "User" : "Recipient"}</span>
                            <span className={styles.smsMessageName}>{Spikes.date.lDateTime(msg.stamp)}</span>
                            <span className={styles.smsMessageBody}>{msg.body}</span>
                            {Array.isArray(msg.media) && <span className={styles.smsMessageBody}>{msg.media.filter(m => m).map(media => <img
                                key={media.index} 
                                src={media.url}
                                style={{maxHeight: "60px"}}
                                onLoad={() => this.routines.scroll("sms")}
                                alt=""
                                draggable="true"
                                onDragStart={e => Spikes.drag.onDrag(e, "smsImage")}
                                onDragEnd={e => Spikes.drag.onEnd(e)}
                                onClick={() => Spikes.notify("preview", media.url)}
                            />)}</span>}
                        </span>
                    </div>)
                }</TableView.Body>
            </TableView.Column>
            <TableView.Column colSpan="7">
                <CallsElement key={this.profileId} profileId={this.profileId} />
            </TableView.Column>
        </TableView.Table>
    </div>;

    mobile = profile => <div>
        <div className="optionsbar">
            <GoBack className="solo"><Icon name="backward" />Back</GoBack>
            <button className="solo" onClick={() => this.routines.callProfile()}><Icon name="phone" />Call / <Icon name="sms" />SMS</button>
            <button className="solo" onClick={() => Spikes.notify("navigate", `/application?profileId=${this.profileId}`)}><Icon name="form" />Application</button>
        </div>

        <TableView.Header><Icon name="customer" />Profile</TableView.Header>
        <TableView.Body>
            <TableView.FieldView title="Phone Number" content={Spikes.asPhone(profile.id)} />
            <TableView.Table>
                <TableView.Column colSpan="6">
                    <TableView.FieldView title="Firstname" content={profile.global?.firstname || "N/A"} />
                    <TableView.FieldView title="Street" content={profile.global?.street || "N/A"} />
                    <TableView.FieldView title="Province" content={profile.global?.province || "N/A"} />
                </TableView.Column>
                <TableView.Column colSpan="6">
                    <TableView.FieldView title="Lastname" content={profile.global?.lastname || "N/A"} />
                    <TableView.FieldView title="City" content={profile.global?.city || "N/A"} />
                    <TableView.FieldView title="PostalCode" content={profile.global?.postalCode || "N/A"} />
                </TableView.Column>
            </TableView.Table>
            <TableView.FieldView title="E-mail Address">{profile.global?.email ? <a href={"mailto:" + profile.global?.email} target="_new">{profile.global?.email}</a> : "N/A"}</TableView.FieldView>
        </TableView.Body>

        {/* <LikedVehiclesElement profileId={this.profileId} /> */}

        <TableView.Header><Icon name="note" />Notes<Icon name="add" className="link" title="Add Note" style={{float: "right"}} onClick={() => this.routines.editNote()} /></TableView.Header>
        <TableView.Body style={{height: "300px", overflow: "auto"}} ref={this.ref.notes}>{
            (profile.notes || []).map((note, index) => <div key={index} style={{cursor: "pointer"}} onClick={() => this.routines.editNote(note)}>
                <TableView.FieldView title={Spikes.date.lhDateTime(note.stamp) + " [" + BaseApi.data.usersNameById(note.userId) + "]"} content={note.note.split("\n").map((n, i) => <div key={i}>{n}</div>)} />
            </div>)
        }</TableView.Body>

        <DocumentElement key={this.profileId} profileId={this.profileId} />

        <TableView.Header><Icon name="sms" />SMS</TableView.Header>
        <TableView.Body style={{height: "300px", overflow: "auto"}} ref={this.ref.sms}>{
            (this.state.sms || []).map(msg => <div key={this.profileId + "-" + msg.id} style={{textAlign: msg.userId ? "right" : "left"}}>
                <span className={styles.smsMessageBubble} style={msg.userId === 0 ? {borderColor: "#68a168", backgroundColor: "#93ed93"} : {borderColor: "#595cff", backgroundColor: "#9cf4f7"}}>
                    <span className={styles.smsMessageName}>{msg.userId ? "User" : "Recipient"}</span>
                    <span className={styles.smsMessageName}>{Spikes.date.lDateTime(msg.stamp)}</span>
                    <span className={styles.smsMessageBody}>{msg.body}</span>
                    {Array.isArray(msg.media) && <span className={styles.smsMessageBody}>{msg.media.filter(m => m).map(media => <img
                        key={media.index} 
                        src={media.url}
                        style={{maxHeight: "60px"}}
                        onLoad={() => this.routines.scroll("sms")}
                        alt=""
                        draggable="true"
                        onDragStart={e => Spikes.drag.onDrag(e, "smsImage")}
                        onDragEnd={e => Spikes.drag.onEnd(e)}
                        onClick={() => Spikes.notify("preview", media.url)}
                    />)}</span>}
                </span>
            </div>)
        }</TableView.Body>

        {/* <TableView.Table>
            <TableView.Column colSpan="5">
                
            </TableView.Column>
            <TableView.Column colSpan="4">
                
            </TableView.Column>
            <TableView.Column colSpan="3">
                <TableView.Header><Icon name="history" />History</TableView.Header>
                <TableView.Body style={{height: "300px", overflow: "auto"}} ref={this.ref.history}></TableView.Body>
            </TableView.Column>
        </TableView.Table> */}

        {/* <TableView.Table>
            <TableView.Column colSpan="5">
                
            </TableView.Column>
            <TableView.Column colSpan="7">
                <CallsElement key={this.profileId} profileId={this.profileId} />
            </TableView.Column>
        </TableView.Table> */}
    </div>;

    render = () => (this.state.isMobile ? this.mobile : this.desktop)(this.state.profile);
}