import React from "react";
import siteState from "../../services/siteState";
import Spikes from "../../services/Spikes";
import BaseApi from "../../services/BaseApi";
import Icon from "../../directives/Icon";
import BreakSearch from "../../directives/BreakSearch";
// import OrderH from "../../directives/OrderH";
import BaseData from "../../services/BaseData";

export default class CallsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = siteState["callsPageState_" + props.filter] || {
            calls: false,
            call: false,
            filter: props.filter,
            search: () => true,
            callSource: "",
            answeredBy: "",
            isMobile: false
        };
        this.state.isMobile = props.isMobile;
        if (this.state.filter !== props.filter) {this.state.filter = props.filter;}
        this.orderBy = {
            field: "dateModified",
            desc: true,
            last: false
        };
        this.count = 0;
        this.postVoicemail = BaseApi.config("CALL_POST", "VOICEMAIL").value;
        this.postCallback = BaseApi.config("CALL_POST", "CALLBACK").value;
    }

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    routines = {
        search: () => this.setVal({search: this.search}),
        setTitle: () => setTimeout(() => {
            switch (this.props.filter) {
                case "voicemail": Spikes.notify("title", "voicemail", "Voicemail", `Total: ${this.count}`); break;
                case "callbacks": Spikes.notify("title", "enter", "Callbacks", `Total: ${this.count}`); break;
                case "callLogs": Spikes.notify("title", "phone", "Call Logs", `Total: ${this.count}`); break;
                default:
            }
        }),
        refresh: () => {
            BaseApi.comm.getCalls(data => {
                if (data.success) {
                    this.setVal({calls: data.records});
                } else {
                    this.setVal({calls: []});
                }
            });
        },
        order: (field, cb) => {
            if (!field) {
                this.orderBy.last = cb;
            } else if (this.orderBy.field === field) {
                this.orderBy.desc = !this.orderBy.desc;
                cb(this.orderBy.desc ? "up" : "down");
            } else {
                this.orderBy.field = field;
                this.orderBy.desc = false;
                cb("down");
                if (this.orderBy.last) {this.orderBy.last("");}
                this.orderBy.last = cb;
            }
            this.setVal({applications: Spikes.orderBy(this.state.calls, this.orderBy.field, this.orderBy.desc)});
        },
        count: arr => {
            if (this.count !== arr.length) {
                this.count = arr.length;
                this.routines.setTitle();
            }
            return arr;
        }
    };

    componentDidMount() {
        this.wHolder(Spikes.watch("isMobile")(isMobile => this.setVal({isMobile: isMobile})));
        setTimeout(() => window.scrollTo(0, 0));
        this.routines.refresh();
        this.routines.setTitle();
        this.wHolder(BaseData.calls(calls => this.setVal({calls: calls})));
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
        siteState["callsPageState_" + this.props.filter] = this.state;
    }
    
    render = () => !this.state.calls ? <img src="/images/loading_rs2.gif" className="center-screen" alt="Loading..." /> : <div>
        <div className="optionsbar">
            <BreakSearch
                name="calls"
                filters={{
                    "Number": "number"
                }}
                searchBack={sb => this.search = sb}
                processSearch={() => this.routines.search()}
            />
            {this.props.filter === "callLogs" && <select defaultValue={this.state.callSource} onChange={e => this.setVal({callSource: e.target.value})} className="solo">
                <option value={""}>Source: All</option>
                {BaseApi.config("CALL_SOURCE").map(cs => <option key={cs.value} value={cs.value}>Source: {cs.caption}</option>)}
            </select>}
            {this.props.filter === "callLogs" && <select defaultValue={this.state.answeredBy} onChange={e => this.setVal({answeredBy: e.target.value})} className="solo">
                <option value={""}>Agent: All</option>
                <option value={"!"}>Agent: N/A</option>
                {BaseApi.data.users.map(u => <option key={u.id} value={u.id}>Agent: {u.firstname} {u.lastname}</option>)}
            </select>}
        </div>
        {this.state.isMobile ? <table className="wide">
            <thead>
                <tr>
                    <th>Date/Time</th>
                    <th>Source</th>
                    <th>Number</th>
                </tr>
                <tr>
                    <th>Answered By</th>
                    <th>Current State</th>
                    <th>Post State</th>
                </tr>
                <tr>
                    <th></th>
                    <th colSpan={2}>Duration</th>
                </tr>
            </thead>
            {this.routines.count(
                this.state.calls
                .filter(c => this.state.callSource === "" || Number(this.state.callSource) === c.source)
                .filter(c => this.state.answeredBy === "" || (this.state.answeredBy === "!" && !c.agent) || (this.state.answeredBy !== "!" && Number(this.state.answeredBy) === c.agent))
                .filter(c => this.props.filter === "callLogs" || (this.props.filter === "voicemail" && c.post === this.postVoicemail) || (this.props.filter === "callbacks" && c.post === this.postCallback))
                .filter(this.state.search)
            ).map(c => (
                <tbody
                    className={c.dateModified > c.dateReviewed ? "hover-green" : "hover"}
                    key={c.id}
                    onClick={() => Spikes.notify("callModal", c)}
                >
                    <tr>
                        <td style={{whiteSpace: "nowrap"}}>{Spikes.date.lhDateTime(c.dateStarted)}</td>
                        <td style={{whiteSpace: "nowrap"}}>{BaseApi.config("CALL_SOURCE", c.source).caption}</td>
                        <td style={{whiteSpace: "nowrap"}}>{Spikes.asPhone(c.number)}</td>
                    </tr>
                    <tr>
                        <td style={{whiteSpace: "nowrap"}}>{BaseApi.data.usersNameById(c.agent)}</td>
                        <td style={{whiteSpace: "nowrap"}}>{BaseApi.config("CALL_STATE", c.state).caption}</td>
                        <td style={{whiteSpace: "nowrap"}}>{BaseApi.config("CALL_POST", c.post).caption}</td>
                    </tr>
                    <tr>
                        <td style={{whiteSpace: "nowrap"}}>{c.voicemailFile && <Icon name="voicemail" margin="0" title="Voicemail File" />}{c.recordingFile && <Icon name="mic" margin="0" title="Recording File" />}</td>
                        <td style={{whiteSpace: "nowrap"}} colSpan={2}>{c.dateStarted && c.dateEnded ? ((c.dateEnded - c.dateStarted) / 1000).toFixed(0) : "0"}</td>
                    </tr>
                </tbody>
            ))}
        </table> : <table className="wide">
            <thead>
                <tr>
                    <th>Date/Time</th>
                    <th>Source</th>
                    <th>Number</th>
                    <th>Answered By</th>
                    <th>Current State</th>
                    <th>Post State</th>
                    <th></th>
                    <th>Duration</th>
                </tr>
            </thead>
            {this.routines.count(
                this.state.calls
                .filter(c => this.state.callSource === "" || Number(this.state.callSource) === c.source)
                .filter(c => this.state.answeredBy === "" || (this.state.answeredBy === "!" && !c.agent) || (this.state.answeredBy !== "!" && Number(this.state.answeredBy) === c.agent))
                .filter(c => this.props.filter === "callLogs" || (this.props.filter === "voicemail" && c.post === this.postVoicemail) || (this.props.filter === "callbacks" && c.post === this.postCallback))
                .filter(this.state.search)
            ).map(c => (
                <tbody
                    className={c.dateModified > c.dateReviewed ? "hover-green" : "hover"}
                    key={c.id}
                    onClick={() => Spikes.notify("callModal", c)}
                >
                    <tr>
                        <td style={{whiteSpace: "nowrap"}}>{Spikes.date.lhDateTime(c.dateStarted)}</td>
                        <td style={{whiteSpace: "nowrap"}}>{BaseApi.config("CALL_SOURCE", c.source).caption}</td>
                        <td style={{whiteSpace: "nowrap"}}>{Spikes.asPhone(c.number)}</td>
                        <td style={{whiteSpace: "nowrap"}}>{BaseApi.data.usersNameById(c.agent)}</td>
                        <td style={{whiteSpace: "nowrap"}}>{BaseApi.config("CALL_STATE", c.state).caption}</td>
                        <td style={{whiteSpace: "nowrap"}}>{BaseApi.config("CALL_POST", c.post).caption}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.voicemailFile && <Icon name="voicemail" margin="0" title="Voicemail File" />}{c.recordingFile && <Icon name="mic" margin="0" title="Recording File" />}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.dateStarted && c.dateEnded ? ((c.dateEnded - c.dateStarted) / 1000).toFixed(0) : "0"}</td>
                    </tr>
                </tbody>
            ))}
        </table>}
    </div>;
}