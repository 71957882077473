import React from "react";
import BaseApi from "../../services/BaseApi";
import Spikes from "../../services/Spikes";

class BlackBookPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            makes: [],
            models: [],
            series: [],
            styles: [],
            uvc: null,
            equipment: false,
            values: {},
            kilometers: 0
        };
    }

    setVal = Spikes.stateStream(this);

    ref = {
        year: React.createRef(),
        make: React.createRef(),
        model: React.createRef(),
        series: React.createRef(),
        style: React.createRef()
    };
    
    byDrill = event => {
        if (event && event.target && event.target.value) {
            let fieldIndex = [
                this.ref.year.current,
                this.ref.make.current,
                this.ref.model.current,
                this.ref.series.current,
                this.ref.style.current,
            ].indexOf(event.target);
            if (fieldIndex < 1) {this.ref.make.current.value = "";}
            if (fieldIndex < 2) {this.ref.model.current.value = "";}
            if (fieldIndex < 3) {this.ref.series.current.value = "";}
            if (fieldIndex < 4) {this.ref.style.current.value = "";}
            BaseApi.blackBook.drillUvc(
                this.ref.year.current.value || null,
                this.ref.make.current.value || null,
                this.ref.model.current.value || null,
                this.ref.series.current.value || null,
                this.ref.style.current.value || null,
            )(data => {
                let newState = {};
                if (this.ref.make.current.value !== data.selected.make) {this.ref.make.current.value = data.selected.make || "";}
                if (this.ref.model.current.value !== data.selected.model) {this.ref.model.current.value = data.selected.model || "";}
                if (this.ref.series.current.value !== data.selected.series) {this.ref.series.current.value = data.selected.series || "";}
                if (this.ref.style.current.value !== data.selected.style) {this.ref.style.current.value = data.selected.style || "";}
                if (fieldIndex < 1) {newState.makes = data.lists.makes;}
                if (fieldIndex < 2) {newState.models = data.lists.models;}
                if (fieldIndex < 3) {newState.series = data.lists.series;}
                if (fieldIndex < 4) {newState.styles = data.lists.styles;}
                newState.uvc = data.uvc || false;
                newState.values = {};
                this.setVal(newState);
            });
        }
    };

    getValues = () => {
        if (this.state.uvc && this.state.kilometers) {
            BaseApi.blackBook.usedByUvc(this.state.uvc, this.state.kilometers)(data2 => {
                this.setVal({values: data2.values});
            });
        }
    };

    componentDidMount() {
        setTimeout(() => window.scrollTo(0, 0));
        setTimeout(() => Spikes.notify("title", "book", "BlackBook Vehicle Value Interface"));
    }
    componentWillUnmount() {

    }
    render() {
        return <div>
            <table className="center-screen">
                <tbody>
                    {this.state.equipment && this.state.equipment.map((eq, i) => <tr key={i}><td>{eq.category}</td><td>{eq.equipment}</td><td>{eq.value}</td></tr>)}
                    <tr>
                        <td style={{textAlign: "right"}}>Year:</td>
                        <td colSpan={2}><select ref={this.ref.year} onChange={e => this.byDrill(e)}><option value="">Select Year</option>{
                            (() => {
                                let years = [];
                                for (let i = 2022; i >= 2002; i--) {years.push(i.toString());}
                                return years;
                            })().map(year => <option key={year} value={year}>{year}</option>)
                        }</select></td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "right"}}>Make:</td>
                        <td colSpan={2}><select ref={this.ref.make} onChange={e => this.byDrill(e)}><option value="">Select Make</option>{
                            this.state.makes.map(make => <option key={make} value={make}>{make}</option>)
                        }</select></td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "right"}}>Models:</td>
                        <td colSpan={2}><select ref={this.ref.model} onChange={e => this.byDrill(e)}><option value="">Select Model</option>{
                            this.state.models.map(model => <option key={model} value={model}>{model}</option>)
                        }</select></td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "right"}}>Series:</td>
                        <td colSpan={2}><select ref={this.ref.series} onChange={e => this.byDrill(e)}><option value="">Select Series</option>{
                            this.state.series.map(series => <option key={series} value={series}>{series}</option>)
                        }</select></td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "right"}}>Styles:</td>
                        <td colSpan={2}><select ref={this.ref.style} onChange={e => this.byDrill(e)}><option value="">Select Style</option>{
                            this.state.styles.map(style => <option key={style} value={style}>{style}</option>)
                        }</select></td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "right"}}>Odometer:</td>
                        <td colSpan={2}><input onChange={e => {e.target.value = e.target.value.replace(/\D/g, ""); this.setVal({kilometers: e.target.value});}} /></td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "right"}}>UVC:</td>
                        <td colSpan={2}>{this.state.uvc}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "right"}}>Values By Condition:</td>
                        <td style={{textAlign: "right"}}>Rough: $</td>
                        <td>{this.state.values?.rough || "Pending"}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style={{textAlign: "right"}}>Avg: $</td>
                        <td>{this.state.values?.avg || "Pending"}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style={{textAlign: "right"}}>Clean: $</td>
                        <td>{this.state.values?.clean || "Pending"}</td>
                    </tr>
                    <tr>
                        <td colSpan={3} style={{textAlign: "right"}}><button onClick={() => this.setVal({uvc: "", values: {}}, () => Object.values(this.ref).forEach(r => {r.current.value = "";}))}>Reset</button><button disabled={!this.state.uvc || !this.state.kilometers} onClick={() => this.getValues()}>Submit</button></td>
                    </tr>
                </tbody>
            </table>
        </div>;
    }
}
export default BlackBookPage;