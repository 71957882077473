import React from "react";
import Icon from "../../directives/Icon";
import Spikes from "../../services/Spikes";
import AudioPlayer from "../../directives/AudioPlayer";
import BaseApi from "../../services/BaseApi";
import BaseData from "../../services/BaseData";
import ModalFrame from "../../directives/ModalFrame";

export default class CallModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            call: false,
            callState: {},
            postState: {},
            isMobile: props.isMobile
        };
    }
    
    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    routines = {
        setCall: call => {
            this.setVal({
                call: call,
                callState: BaseApi.config("CALL_STATE", call.state),
                postState: BaseApi.config("CALL_POST", call.post)
            });
        }
    };

    componentDidMount() {
        this.wHolder(Spikes.watch("isMobile")(isMobile => this.setVal({isMobile: isMobile})));
        this.wHolder(Spikes.watch("callModal")(call => {
            this.routines.setCall(call);
        }));
        this.wHolder(BaseData.calls(calls => {
            if (this.state.call) {
                let cCall = calls.find(c => c.id === this.state.call.id);
                if (cCall) {this.routines.setCall(cCall);}
            }
        }));
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    render = () => !this.state.call ? null : <ModalFrame onClose={() => this.setVal({call: false})} width={this.state.isMobile ? "380px" : "500px"}>
        <span><Icon name="phone" size="20px" />Call - {this.state.call && Spikes.asPhone(this.state.call.number)}</span>
        <div className="optionsbar">
            <button className="solo" onClick={() => Spikes.notify("navigate", `/customer?profileId=${this.state.call.number}`)}><Icon name="customer" />View Profile</button>
            <button className="solo" onClick={() => Spikes.notify("callById", this.state.call.number)}><Icon name="phone" />Call / <Icon name="sms" />SMS</button>
            <span className="right">
                {this.state.postState?.name === "VOICEMAIL" && <button className="solo" onClick={() => BaseApi.comm.setReviewed(this.state.call.id)()}><Icon name="check" />Mark Reviewed</button>}
                {this.state.postState?.name === "CALLBACK" && <button className="solo" onClick={() => BaseApi.comm.cancelCallback(this.state.call.id)()}><Icon name="cancel" />Cancel Callback</button>}
            </span>
        </div>
        {this.state.call && <div style={{backgroundColor: "var(--color-6)", width: "100%"}}>
            <table style={{width: "100%"}}>
                <tbody>
                    <tr>
                        <td style={{textAlign: "right"}}>Number:</td>
                        <td>{Spikes.asPhone(this.state.call.number)}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "right"}}>Source:</td>
                        <td>{BaseApi.config("CALL_SOURCE", this.state.call.source).caption}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "right"}}>Date Of Call:</td>
                        <td>{Spikes.date.lhDateTime(this.state.call.dateStarted)}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "right"}}>Duration:</td>
                        <td>{this.state.call.dateStarted && this.state.call.dateEnded ? ((this.state.call.dateEnded - this.state.call.dateStarted) / 1000).toFixed(0) : "0"} Seconds</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "right"}}>Call State:</td>
                        <td>{this.state.callState?.caption}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "right"}}>Answered By:</td>
                        <td>{BaseApi.data.usersNameById(this.state.call.agent)}</td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "right"}}>Post Call State:</td>
                        <td>{this.state.postState?.caption}</td>
                    </tr>
                </tbody>
            </table>
            {this.state.call.voicemailFile && <AudioPlayer key={this.state.call.voicemailFile} icon="voicemail" title="Voicemail" filename={`https://dsa.carmonk.ca/media/${this.state.call.voicemailFile}`} />}
            {this.state.call.recordingFile && <AudioPlayer key={this.state.call.recordingFile} icon="mic" title="Recording" filename={`https://dsa.carmonk.ca/media/${this.state.call.recordingFile}`} />}
        </div>}
    </ModalFrame>;
}