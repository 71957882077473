const SocketConnection = (url, controls) => {
    const fromJson = data => {try {return JSON.parse(data);} catch (err) {return undefined;}};
    let onReceive = false;
    let onConnect = false;
    let onClose = false;
    let onError = false;
    let socket = false;
    let autoReconnect = false;
    let isConnected = false;
    let isEnd = true;
    let queue = [];
    const connect = () => {
        socket = new WebSocket(url);
        isEnd = false;
        const reconnect = () => {
            if (!isEnd && autoReconnect === true) {
                setTimeout(connect, 1000);
            } else if (!isEnd && typeof(autoReconnect) === "number") {
                setTimeout(connect, autoReconnect);
            }
        };
        socket.addEventListener('open', event => {
            isConnected = true;
            while (queue.length) {socket.send(queue.shift());}
            if (typeof(onConnect) === "function") {onConnect(event);}
        });
        socket.addEventListener('message', event => typeof(onReceive) === "function" && onReceive(fromJson(event.data)));
        socket.addEventListener('close', event => {
            event.target.close();
            isConnected = false;
            if (typeof(onClose) === "function") {onClose(event);}
            reconnect();
        });
        socket.addEventListener('error', event => {
            event.target.close();
            isConnected = false;
            if (typeof(onError) === "function") {onError(event);}
            else if (typeof(onClose) === "function") {onClose(event);}
            reconnect();
        });
    };
    const close = () => {
        isEnd = true;
        isConnected = false;
        if (socket) {socket.close();}
        socket = false;
    };
    controls({
        onReceive: cb => {onReceive = cb;},
        onConnect: cb => {onConnect = cb;},
        onClose: cb => {onClose = cb;},
        onError: cb => {onError = cb;},
        send: data => isConnected ? socket.send(JSON.stringify(data)) : queue.push(JSON.stringify(data)),
        autoReconnect: nv => {autoReconnect = nv;},
        isConnected: () => isConnected,
        connect: () => connect(),
        close: () => close()
    });
    return () => {
        isEnd = true;
        isConnected = false;
        if (socket) {socket.close();}
        socket = false;
    };
};
export default SocketConnection;