import moment from "moment";
import siteState from "./siteState";

let state = {
    watchers: [],
    dropWatchers: [],
    cycleWatchers: [],
    dropBox: false,
    sessionData: {},
    state: {},
    drag: false
};

const Spikes = {
    state: state.state,
    stateStream: parent => {
        let updateTimer = null;
        let toUpdate = {};
        let toCallback = [];
        let exit = false;
        return (input, cb) => {
            if (input === "end") {exit = true;}
            else {
                Object.keys(input).forEach(key => {toUpdate[key] = input[key];});
                if (typeof(cb) === "function") {toCallback.push(cb);}
                clearTimeout(updateTimer);
                updateTimer = setTimeout(() => {
                    Object.keys(toUpdate).filter(key => ["string", "number", "boolean"].includes(typeof(toUpdate[key]))).forEach(key => {
                        if (parent.state[key] === toUpdate[key]) {delete(toUpdate[key]);}
                    });
                    if (!parent.exit && !exit) {
                        if (Object.keys(toUpdate).length) {
                            let updates = toUpdate, callbacks = toCallback;
                            toUpdate = {};
                            toCallback = [];
                            parent.setState(updates, () => callbacks.forEach(ecb => ecb()));
                        } else if (toCallback.length) {
                            let callbacks = toCallback;
                            toCallback = [];
                            callbacks.forEach(ecb => ecb());
                        }
                    }
                });
            }
        };
    },
    funcState: (state, setState) => {
        let updateTimer = null;
        let toUpdate = {};
        let exit = false;
        return input => {
            if (input.exit) {exit = true;}
            Object.keys(input).forEach(key => {toUpdate[key] = input[key];});
            clearTimeout(updateTimer);
            updateTimer = setTimeout(() => {
                Object.keys(toUpdate).filter(key => ["string", "number", "boolean"].includes(typeof(toUpdate[key]))).forEach(key => {
                    if (state[key] === toUpdate[key]) {delete(toUpdate[key]);}
                });
                if (!exit) {
                    if (Object.keys(toUpdate).length) {
                        let updates = {};
                        Object.keys(state).forEach(key => {updates[key] = state[key];});
                        Object.keys(toUpdate).forEach(key => {updates[key] = toUpdate[key];});
                        toUpdate = {};
                        setState(updates);
                    }
                }
            });
        };
    },
    watchHolder: () => {
        let watchers = [];
        return wcb => {
            if (typeof(wcb) === "function") {watchers.push(wcb);}
            else if (wcb === "end") {watchers.filter(w => typeof(w) === "function").forEach(w => w())}
        };
    },
    getQueryParam: name => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        return (params && params[name]) || null;
    },
    asPhone: source => {
        let s2 = typeof(source) === "string" ? source.replace(/\D/g, "").slice(-10) : "";
        let m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return !m ? s2 : "(" + m[1] + ") " + m[2] + "-" + m[3];
    },
    numberCS: (x, dot) => {
        if (typeof(x) === "string") {x = parseFloat(x.replace(/[^0-9.]/g, '') || 0);}
        else if (typeof(x) !== "number") {x = 0;}
        return x.toFixed(dot !== undefined ? dot : 0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    toNumber: x => {
        if (typeof(x) === "string") {x = parseFloat(x.replace(/[^0-9.]/g, '') || 0);}
        else if (typeof(x) !== "number") {x = 0;}
        return x;
    },
    compair: v => {
        if ((["number", "boolean"]).includes(typeof(v))) {v = v.toString();}
        else if (([null, undefined]).includes(v)) {v = "";}
        if (typeof(v) === "string") {
            return v.toLowerCase().replace(/[^a-z0-9]/g, "");
        } else {
            return "";
        }
    },
    watch: name => callback => {
        if (typeof(callback) === "function") {
            let watcher = {name: name, callback: callback};
            state.watchers.push(watcher);
            return () => {Spikes.remove(state.watchers, watcher);};
        }
    },
    notify: (name, ...data) => state.watchers.filter(watcher => watcher.name === name).forEach(watcher => watcher.callback(...data)),
    cycle: (callback, interval, start) => {
        if (typeof(callback) === "function") {
            let watcher = {interval: interval || 1, count: typeof(start) === "number" ? start : interval || 1, callback: callback};
            if (watcher.count === 0) {watcher.callback(); watcher.count = watcher.interval || 1;}
            state.cycleWatchers.push(watcher);
            return option => {
                if (option && typeof(option) === "object") {
                    Object.keys(option).forEach(key => {
                        switch (key) {
                            case "interval": watcher.interval = option[key]; break;
                            case "count": watcher.count = option[key]; break;
                            case "end": if (option[key]) {Spikes.remove(state.cycleWatchers, watcher);} break;
                            case "now": if (option[key]) {watcher.count = watcher.interval; watcher.callback();} break;
                            default:
                        }
                    });
                } else {
                    Spikes.remove(state.cycleWatchers, watcher);
                }
            };
        }
    },
    isObject: value => (value && typeof(value) === "object" && !Array.isArray(value)) ? true : false,
    first: array => (callback, alternate) => {
        if (Array.isArray(array) && array.length && typeof(callback) === "function") {
            return callback(array[0]);
        } else if ((!Array.isArray(array) || !array.length) && typeof(alternate) === "function") {
            return alternate();
        } else if (Array.isArray(array) && array.length) {
            return array[0];
        } else {
            return null;
        }
    },
    last: array => (callback, alternate) => {
        if (Array.isArray(array) && array.length && typeof(callback) === "function") {
            return callback(array[array.length - 1]);
        } else if ((!Array.isArray(array) || !array.length) && typeof(alternate) === "function") {
            return alternate();
        } else if (Array.isArray(array) && array.length) {
            return array[array.length -1];
        } else {
            return null;
        }
    },
    remove: (objArray, element) => {
        if (objArray && typeof(objArray) === "object") {
            if (Array.isArray(objArray)) {
                let i = objArray.indexOf(element);
                if (i > -1) {objArray.splice(i, 1);}
            } else {
                if (objArray[element] !== undefined) {
                    delete(objArray[element]);
                }
            }
        }
    },
    upload: (callback, accept, multiple) => {
        if (window.FileReader) {
            let uldr = document.createElement("input");
            uldr.type = "file";
            uldr.accept = typeof(accept) === "string" ? accept : "image/*";
            uldr.multiple = multiple ? true : false;
            uldr.onchange = event => {
                console.log("Upload Event:", event);
                const files = Object.values(event.target.files);
                if (files.length) {
                    let results = [];
                    files.forEach(file => {
                        const readFile = new FileReader();
                        readFile.onload = fData => {
                            results.push({
                                name: file.name,
                                size: file.size,
                                type: file.type,
                                lastModified: file.lastModified,
                                dateUploaded: new Date().getTime(),
                                data: fData.target.result
                            });
                            if (results.length === files.length) {
                                callback(true, multiple ? results : results[0]);
                                setTimeout(() => uldr.remove());
                            }
                        };
                        readFile.readAsDataURL(file);
                    });
                } else {
                    callback(false);
                    setTimeout(() => uldr.remove());
                }
            };
            document.body.appendChild(uldr);
            setTimeout(() => {
                uldr.click();
                uldr.style.display = "none";
            });
        } else {
            callback(false);
        }
    },
    date: {
        lDateTime: uTime => moment.utc(uTime).local().format("YYYY-MM-DD HH:mm:ss"),
        lhDateTime: uTime => moment.utc(uTime).local().format("YYYY/MM/DD h:mma"),
        stamp: () => Date.now() || new Date().getTime()
    },
    move: (pEvent, elem, options) => {
        pEvent.preventDefault();
        let pX = elem.offsetLeft;
        let pY = elem.offsetTop;
        let mX = pEvent.clientX;
        let mY = pEvent.clientY;
        elem.style.zIndex = ++siteState.topIndex;
        const move = event => {
            if (event.buttons !== 1) {
                window.removeEventListener("mousemove", move);
            } else {
                elem.style.left = (pX - mX + event.clientX) + "px";
                elem.style.top = (pY - mY + event.clientY) + "px";
                Spikes.constrain(elem, options);
            }
        };
        window.addEventListener("mousemove", move);
    },
    constrain: (elem, pOptions) => {
        let options = pOptions && typeof(pOptions) === "object" && !Array.isArray(pOptions) ? pOptions: {};
        if (elem.offsetLeft - elem.offsetWidth / 2 < (options.left || 0)) {elem.style.left = ((options.left || 0) + elem.offsetWidth / 2) + "px";}
        else if (elem.offsetLeft + elem.offsetWidth / 2 > document.body.clientWidth - (options.right || 0)) {elem.style.left = (document.body.clientWidth - elem.offsetWidth / 2 - (options.right || 0)) + "px";}
        else if (elem.offsetLeft + elem.offsetWidth / 2 > window.innerWidth - (options.right || 0)) {elem.style.left = (window.innerWidth - elem.offsetWidth / 2 - (options.right || 0)) + "px";}
        if (elem.offsetTop - elem.offsetHeight / 2 < (options.top || 0)) {elem.style.top = ((options.top || 0) + elem.offsetHeight / 2) + "px";}
        else if (elem.offsetTop + elem.offsetHeight / 2 > window.innerHeight - (options.bottom || 0)) {elem.style.top = (window.innerHeight - elem.offsetHeight / 2 - (options.bottom || 0)) + "px";}
    },
    drag: {
        watch: name => callback => {
            if (typeof(callback) === "function") {
                let watcher = {names: !name || Array.isArray(name) ? name : [name], callback: callback};
                state.dropWatchers.push(watcher);
                return () => {Spikes.remove(state.dropWatchers, watcher);};
            }
        },
        onDrag: (event, name) => {
            console.log("onDrag:", event.target.tagName, name);
            state.drag = {
                elem: event.target,
                source: name,
                dest: false,
                drop: false
            };
            state.dropWatchers.filter(w => !w.names || w.names.includes(name)).forEach(w => {
                w.callback("drag", state.drag);
            });
        },
        onDrop: event => {
            event.preventDefault();
            let pe = event.target;
            while (!pe.getAttribute("dropname") && pe !== document.body) {
                pe = pe.parentNode;
            }
            if (pe !== document.body && state.drag) {
                let name = pe.getAttribute("dropname");
                console.log("onDrop:", event.target.tagName, name);
                state.drag.drop = name;
                state.dropWatchers.filter(w => !w.names || w.names.includes(name)).forEach(w => {
                    w.callback("drop", state.drag);
                });
            }
            if (state.dropBox) {state.dropBox.remove(); state.dropBox = false;}
        },
        onEnd: event => {
            event.preventDefault();
            console.log("onEnd:", state.drag);
            if (state.drag) {
                state.dropWatchers.filter(w => !w.names).forEach(w => {
                    w.callback("end", state.drag);
                });
                state.drag = false;
            }
            if (state.dropBox) {state.dropBox.remove(); state.dropBox = false;}
        },
        onOver: event => {
            event.preventDefault();
            let pe = event.target;
            while (!pe.getAttribute("dropname") && pe !== document.body) {
                pe = pe.parentNode;
            }
            if (state.drag && pe !== state.drag.destTarget && pe !== document.body) {
                let name = pe.getAttribute("dropname");
                console.log("onOver:", pe.tagName, name);
                state.drag.dest = name;
                state.drag.destTarget = pe;
                state.dropWatchers.filter(w => !w.names || w.names.includes(name)).forEach(w => {
                    w.callback("over", state.drag);
                });
                if (state.dropBox) {state.dropBox.remove();}
                state.dropBox = document.createElement("span");
                state.dropBox.style.pointerEvents = "none";
                state.dropBox.style.position = "absolute";
                state.dropBox.style.left = state.drag.destTarget.offsetLeft + "px";
                state.dropBox.style.top = state.drag.destTarget.offsetTop + "px";
                state.dropBox.style.width = state.drag.destTarget.offsetWidth + "px";
                state.dropBox.style.height = state.drag.destTarget.offsetHeight + "px";
                state.dropBox.style.backgroundColor = "rgba(0, 100, 0, 0.5)";
                state.dropBox.style.zIndex = 101;
                pe.append(state.dropBox);
            }
        }
    },
    queue: () => {
        let q = [], results = {};
        return {
            add: (name, cb) => q.push({name: name, cb: cb}),
            execute: callback => {
                const next = () => {
                    if (q.length) {
                        let c = q.shift(); 
                        c.cb(r => {
                            if (!c.name || c.name === "collect") {
                                if (!results.collect) {results.collect = [];}
                                results.collect.push(r);
                            } else if (results[c.name] === undefined) {
                                results[c.name] = r;
                            } else if (Array.isArray(results[c.name]) && Array.isArray(r)) {
                                results[c.name].push(...r);
                            } else if (typeof(results[c.name]) === "object" && results[c.name] !== null && typeof(r) === "object" && r !== null && !Array.isArray(results[c.name]) && !Array.isArray(r)) {
                                Object.keys(r).forEach(key => {results[c.name][key] = r[key];});
                            } else {
                                if (!results.collect) {results.collect = [];}
                                results.collect.push(r);
                            }
                            setTimeout(next);
                        });
                    } else {callback(results);}
                };
                next();
            }
        };
    },
    orderBy: (arr, field, descending) => {
        let results = arr.length ? [arr[0]] : [];
        const asString = value => {
            if (typeof(value) === "string") {return value;}
            else if (typeof(value) === "number") {return value.toString();}
            else if (typeof(value) === "boolean") {return value ? "0" : "1";}
            else {return "";}
        };
        for (let i = 1; i < arr.length; i++) {
            let match = false;
            for (let ii = 0; ii < results.length; ii++) {
                if ((descending && asString(arr[i][field]) >= asString(results[ii][field])) || (!descending && asString(arr[i][field]) <= asString(results[ii][field]))) {
                    results.splice(ii, 0, arr[i]);
                    match = true;
                    break;
                }
            }
            if (!match) {results.push(arr[i]);}
        }
        return results;
    },
    flatten: obj => {
        let nr = {};
        const sp = or => Object.keys(or).forEach(key => {
            if (["string", "boolean", "number"].includes(typeof(or[key])) || Array.isArray(or[key])) {
                nr[key] = or[key];
            } else if (or[key] && typeof(or[key]) === "object") {
                sp(or[key]);
            }
        });
        sp(obj);
        return nr;
    },
    fuseObj: (...obj) => {
        let results = {};
        obj.filter(o => o && typeof(o) === "object" && !Array.isArray(o)).forEach(o => Object.keys(o).forEach(key => {results[key] = o[key];}));
        return results;
    }
};
setInterval(() => {
    state.cycleWatchers.forEach(cw => {
        cw.count--;
        if (cw.count <= 0) {
            cw.count = cw.interval || 1;
            cw.callback();
        }
    });
}, 1000);
export default Spikes;