import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import siteState from './services/siteState';
import BaseApi from './services/BaseApi';
import Spikes from './services/Spikes';

import LoginPage from './login/LoginPage';
import PrePopulateFrame from './login/PrePopulate';

import HeaderFrame from './frames/header/HeaderFrame';
import FooterFrame from './frames/footer/FooterFrame';
import LeftMenuFrame from './frames/lmenu/LeftMenuFrame';
import ModalBuilder from './frames/modal/ModalBuilder';
import AlertMenuFrame from './frames/amenu/AlertMenuFrame';

import OverviewPage from './pages/overview/OverviewPage';
import ApplicationsPage from './pages/applications/ApplicationsPage';
import CustomersPage from './pages/customers/CustomersPage';
import VehiclesPage from './pages/vehicles/VehiclesPage';
import ReportsPage from './pages/reports/ReportsPage';
import MyAccountPage from './pages/myaccount/MyAccountPage';
import CustomerPage from './pages/customers/CustomerPage';
import VehiclePage from './pages/vehicles/VehiclePage';
import ImportPage from './pages/import/ImportPage';
import InventoryPage from './pages/inventory/InventoryPage';
import ValidatePage from './pages/validate/ValidatePage';
// import NotFoundPage from './pages/notFound/NotFoundpage';
import SettingsPage from './pages/settings/settings';
import BlackBookPage from './pages/blackbook/blackbook';
import ApplicationPage from './pages/applications/ApplicationPage';
import EvoxLibraryPage from './pages/evox/evoxLibrary';
import CallsPage from './pages/calls/CallsPage';
import CallModal from './frames/modal/CallModal';
import ImagePreviewModal from './frames/modal/ImagePreviewModal';
import PopMsg from './frames/modal/PopMsg';
import SmsModal from './frames/modal/SmsModal';
import ClipBoardModal from './frames/modal/ClipBoardModal';
import ActiveLink from './services/ActiveLink';
// import ModalContainer from './frames/modal/ModalContainer';
import ScrollMinder from './frames/modal/ScrollMinder';
import PopMenu from './frames/modal/PopMenu';
import BlackBookModal from './pages/applications/BlackBookModal';
import CustomerNoteModal from './frames/modal/CustomerNoteModal';
import VehicleNoteModal from './frames/modal/VehicleNoteModal';
import SmsPage from './pages/calls/SmsPage';
import AlertSettingsPage from './pages/alertSettings/alertSettingsPage';
import NumberModal from './frames/modal/NumberModal';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validUser: 0,
      navigate: false,
      leftPad: "20px",
      isMobile: window.innerWidth <= 800
    };
    siteState.isMobile = this.state.isMobile;
    this.fullLeft = false;
  }

  setVal = Spikes.stateStream(this);
  wHolder = Spikes.watchHolder();

  resize = () => {
    if (window.innerWidth > 1666) {
      this.fullLeft = true;
      siteState.fullLeft = true;
      Spikes.notify("leftMenuLock", true);
      this.setVal({leftPad: "301px", isMobile: false}, () => Spikes.notify("isMobile", this.state.isMobile));
    } else if (window.innerWidth > 800) {
      this.fullLeft = false;
      siteState.fullLeft = false;
      Spikes.notify("leftMenuLock", false);
      this.setVal({ leftPad: "20px", isMobile: false}, () => Spikes.notify("isMobile", this.state.isMobile));
    } else {
      this.fullLeft = false;
      siteState.fullLeft = false;
      Spikes.notify("leftMenuLock", false);
      this.setVal({leftPad: "0px", isMobile: true}, () => Spikes.notify("isMobile", this.state.isMobile));
    }
  };

  componentDidMount() {
    this.resize();
    this.wHolder(BaseApi.user.stateWatcher(valid => {
      this.setVal({validUser: valid ? 2 : 1});
    }));
    this.wHolder(Spikes.watch("navigate")(path => this.setState({ navigate: path }, () => this.setState({ navigate: false }))));
    this.setVal({validUser: BaseApi.user.info().valid ? 2 : 1});
    window.addEventListener("resize", this.resize);
    this.wHolder(Spikes.watch("title")((icon, title, info) => {
      siteState.headerIcon = icon || "";
      siteState.headerTitle = title || "";
      siteState.headerInfo = info || "";
    }));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
    this.setVal("end");
    this.wHolder("end");
  }

  render() {
    Spikes.notify("pathname", window.location.pathname);
    switch (this.state.validUser) {
      case 0: return <img src="/images/loading_rs2.gif" className="center-screen" alt="Loading..." />;
      case 1: return <BrowserRouter>
        {this.state.navigate && <Navigate to={this.state.navigate} />}
        <Routes>
          <Route exact path="*" element={<LoginPage />} />
        </Routes>
      </BrowserRouter>;
      case 2: return <PrePopulateFrame onComplete={() => this.setVal({ validUser: 3 })} />;
      case 3: return <div className={this.state.isMobile ? "mobile-body-frame" : "body-frame"} style={{ paddingLeft: this.state.leftPad }}>
        <CallModal isMobile={this.state.isMobile} />
        <ImagePreviewModal />
        <PopMsg />
        <SmsModal isMobile={this.state.isMobile} />
        <ClipBoardModal />
        <NumberModal />
        <ActiveLink />
        <ModalBuilder />
        <PopMenu />
        <AlertMenuFrame isMobile={this.state.isMobile} />
        <BlackBookModal />
        <CustomerNoteModal isMobile={this.state.isMobile} />
        <VehicleNoteModal />
        <BrowserRouter>
          {this.state.navigate && <Navigate to={this.state.navigate} />}
          <Routes>
            <Route exact path="/" element={<OverviewPage />} />
            <Route exact path="/overview" element={<OverviewPage />} />
            <Route exact path="/applications" element={<ApplicationsPage isMobile={this.state.isMobile} />} />
            <Route exact path="/application" element={<ApplicationPage key={Spikes.getQueryParam("applicationId")} isMobile={this.state.isMobile} />} />
            <Route exact path="/customers" element={<CustomersPage isMobile={this.state.isMobile} />} />
            <Route exact path="/customer" element={<CustomerPage key={Spikes.getQueryParam("profileId")} isMobile={this.state.isMobile} />} />
            <Route exact path="/vehicles" element={<VehiclesPage isMobile={this.state.isMobile} />} />
            <Route exact path="/vehicle" element={<VehiclePage key={Spikes.getQueryParam("vehicleId")} />} />
            <Route exact path="/inventory" element={<InventoryPage />} />
            <Route exact path="/import" element={<ImportPage />} />
            <Route exact path="/validate" element={<ValidatePage />} />
            <Route exact path="/blackbook" element={<BlackBookPage />} />
            <Route exact path="/reports" element={<ReportsPage />} />
            <Route exact path="/settings" element={<SettingsPage />} />
            <Route exact path="/myAccount" element={<MyAccountPage />} />
            <Route exact path="/evoxLibrary" element={<EvoxLibraryPage key={Spikes.getQueryParam("vehicleId")} />} />
            <Route exact path="/voicemail" element={<CallsPage key="voicemail" filter="voicemail" isMobile={this.state.isMobile} />} />
            <Route exact path="/callbacks" element={<CallsPage key="callbacks" filter="callbacks" isMobile={this.state.isMobile} />} />
            <Route exact path="/calllogs" element={<CallsPage key="callLogs" filter="callLogs" isMobile={this.state.isMobile} />} />
            <Route exact path="/unreadsms" element={<SmsPage key="unreadsms" filter="unreadsms" isMobile={this.state.isMobile} />} />
            <Route exact path="/smsLogs" element={<SmsPage key="smsLogs" filter="smsLogs" isMobile={this.state.isMobile} />} />
            <Route exact path="/alertSettings" element={<AlertSettingsPage isMobile={this.state.isMobile} />} />
            <Route exact path="*" element={<OverviewPage />} />
          </Routes>
        </BrowserRouter>
        {!this.state.isMobile && <ScrollMinder />}
        <HeaderFrame isMobile={this.state.isMobile} />
        {!this.state.isMobile && <LeftMenuFrame />}
        <FooterFrame />
      </div>;
      default: return <div>Error...</div>;
    }
  }
}
