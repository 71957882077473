import React from "react";
import Spikes from "../services/Spikes";
import Icon from "./Icon";

class AudioPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            audio: false,
            duration: 0,
            play: false
        };
    }

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    progressRef = React.createRef();

    routines = {
        playPause: () => {this.audio.paused ? this.audio.play() : this.audio.pause();},
        event: event => {
            switch (event.type) {
                case "play": this.setVal({play: true}); break;
                case "pause": this.setVal({play: false}); break;
                case "timeupdate": this.progressRef.current.value = event.target.currentTime; break;
                case "durationchange": this.setVal({duration: this.audio.duration || 0}); break;
                case "ended": this.progressRef.current.value = Math.ceil(this.state.duration); break;
                default:
            }
        },
        seek: event => {this.audio.currentTime = event.target.value;}
    };

    componentDidMount() {
        this.audio = new Audio(this.props.filename);
        this.audio.addEventListener("play", this.routines.event);
        this.audio.addEventListener("pause", this.routines.event);
        this.audio.addEventListener("ended", this.routines.event);
        this.audio.addEventListener("timeupdate", this.routines.event);
        // this.audio.addEventListener("loaddata", this.routines.event);
        this.audio.addEventListener("durationchange", this.routines.event);
    }
    componentWillUnmount() {
        this.setVal("end");
        this.audio.removeEventListener("play", this.routines.event);
        this.audio.removeEventListener("pause", this.routines.event);
        this.audio.removeEventListener("ended", this.routines.event);
        this.audio.removeEventListener("timeupdate", this.routines.event);
        // this.audio.removeEventListener("loaddata", this.routines.event);
        this.audio.removeEventListener("durationchange", this.routines.event);
    }
    render() {
        return <div className="audio-player" style={{width: "100%"}}>
            {this.props.icon && <span className="ctrls" style={{textAlign: "center"}}>
                <Icon name={this.props.icon} style={{marginLeft: "4px", verticalAlign: "middle"}} size="24px" title={this.props.title} />
            </span>}
            <span className="ctrls">
                <button className="ctrl" onClick={() => this.routines.playPause()}>{this.state.play ? <Icon name="pause" margin="0" /> : <Icon name="play" margin="0" />}</button>
            </span>
            <span className="slider">
                <input type="range" min="0" max={Math.ceil(this.state.duration)} ref={this.progressRef} onChange={e => this.routines.seek(e)} style={{width: "100%"}} />
            </span>
            <span className="duration">{Math.ceil(this.state.duration || 0)}</span>
        </div>;
    }
}
export default AudioPlayer;