import EditField from "../../directives/EditField";
import GoBack from "../../directives/GoBack";
import TableView from "../../directives/TableView";
import VehiclePanelElement from "./VehiclePanelElement";
import Spikes from "../../services/Spikes";
import Icon from "../../directives/Icon";
import BaseApi from "../../services/BaseApi";
import DocumentElement from "../customers/DocumentsElement";
import LikedVehiclesElement from "../customers/LikedVehiclesElement";

const optionsBar = (props, application, state) => <div className="optionsbar">
    <GoBack className="solo"><Icon name="backward" />Back</GoBack>
    <button className="solo" onClick={() => Spikes.notify("navigate", `/customer?profileId=${props.profileId}`)}><Icon name="customer" />View Profile</button>
    {application.purchase?.fields?.vehicleId && <button className="solo" onClick={() => Spikes.notify("navigate", `/vehicle?vehicleId=${application.purchase?.fields?.vehicleId}`)}><Icon name="car" />View Vehicle</button>}
    <button className="solo" onClick={() => props.routines.callProfile()}><Icon name="phone" />Call / <Icon name="sms" />SMS</button>
    <span className="right">
        {Object.keys(state.updates).length > 0 && <button className="solo" onClick={() => props.routines.saveUpdates()}><Icon name="save" />Save Changes</button>}
        <select title="Review Status" defaultValue={application.reviewStatus} onChange={e => props.routines.updateReviewStatus(e)} className="solo">
            <option value={"Pending"}>Pending</option>
            <option value={"Transfer"}>Transfer</option>
            <option value={"Reviewing"}>Reviewing</option>
            <option value={"Processing"}>Processing</option>
            <option value={"Accepted"}>Accepted</option>
            <option value={"Rejected"}>Rejected</option>
            <option value={"Invalid"}>Invalid</option>
            <option value={"Test"}>Test</option>
            <option value={"Approved"}>Approved</option>
            <option value={"Declined"}>Declined</option>
            <option value={"WON Deal"}>WON Deal</option>
        </select>
    </span>
</div>;

const applicationDetails = (props, application, state) => <div>
    <TableView.Header><Icon name="form" />Application Details</TableView.Header>
    <TableView.Body>
        <TableView.Table>
            <TableView.Column colSpan="4">
                <TableView.FieldView title="Application Status" content={(application.qualify || application.purchase || application.trade) ? "Complete" : "Incomplete"} />
                <TableView.FieldView title="Review Status" content={application.reviewStatus || "Unknown"} />
            </TableView.Column>
            <TableView.Column colSpan="4">
                <TableView.FieldView title="Date Last Modified By Applicant" content={Spikes.date.lhDateTime(application.dateModified)} />
                <TableView.FieldView title="Date Of Last Review Updated" content={application.dateReviewed ? Spikes.date.lhDateTime(application.dateReviewed) : "Pending"} />
            </TableView.Column>
            <TableView.Column colSpan="4">
                <TableView.FieldView title="Applications Included" content={[application.qualify ? "Qualify" : false, application.purchase ? "Purchase" : false, application.trade ? "Trade/Sell" : false].filter(t => t).join(", ")} />
                <TableView.FieldView title="Agent Assigned" content={BaseApi.data.usersNameById(application.userId)} />
            </TableView.Column>
        </TableView.Table>
    </TableView.Body>
</div>;

const applicantDetails = (props, application, state) => <div>
    <TableView.Header><Icon name="customer" />Applicant Details</TableView.Header>
    <TableView.Body>
        <TableView.Table>
            <TableView.Column colSpan="3">
                <TableView.FieldView title="Firstname"><EditField
                    key={state.index}
                    parent={application.fields}
                    names={[
                        {src: "Agent", name: "agent_personal_firstName"},
                        {src: "Purchase", name: "purchase_personal_firstName"},
                        {src: "Qualify", name: "qualify_personal_firstName"},
                        {src: "Trade/Sell", name: "trade_personal_firstName"}
                    ]}
                    type="string"
                    onChange={(p, n, v) => props.routines.update(p, n, v)}
                /></TableView.FieldView>
                <TableView.FieldView title="Lastname"><EditField
                    key={state.index}
                    parent={application.fields}
                    names={[
                        {src: "Agent", name: "agent_personal_lastName"},
                        {src: "Purchase", name: "purchase_personal_lastName"},
                        {src: "Qualify", name: "qualify_personal_lastName"},
                        {src: "Trade/Sell", name: "trade_personal_lastName"}
                    ]}
                    type="string"
                    onChange={(p, n, v) => props.routines.update(p, n, v)}
                /></TableView.FieldView>
                <TableView.FieldView title="Phone Number" content={Spikes.asPhone(props.profileId)} />
                <TableView.FieldView title="E-mail Address"><EditField
                    key={state.index}
                    parent={application.fields}
                    names={[
                        {src: "Agent", name: "agent_personal_emailAddress"},
                        {src: "Purchase", name: "purchase_personal_emailAddress"},
                        {src: "Qualify", name: "qualify_personal_emailAddress"},
                        {src: "Trade/Sell", name: "trade_personal_emailAddress"}
                    ]}
                    type="string"
                    onChange={(p, n, v) => props.routines.update(p, n, v)}
                /></TableView.FieldView>
                <TableView.FieldView title="Date Of Birth"><EditField
                    key={state.index}
                    parent={application.fields}
                    names={[
                        {src: "Agent", name: "agent_personal_dob"},
                        {src: "Purchase", name: "purchase_personal_dob"},
                        {src: "Qualify", name: "qualify_personal_dob"},
                        {src: "Trade/Sell", name: "trade_personal_dob"}
                    ]}
                    type="string"
                    onChange={(p, n, v) => props.routines.update(p, n, v)}
                /></TableView.FieldView>
            </TableView.Column>
            <TableView.Column colSpan="3">
                <TableView.FieldView title="Unit"><EditField
                    key={state.index}
                    parent={application.fields}
                    names={[
                        {src: "Agent", name: "agent_personal_address_unit"},
                        {src: "Purchase", name: "purchase_personal_address_unit"},
                        {src: "Qualify", name: "qualify_personal_address_unit"},
                        {src: "Trade/Sell", name: "trade_personal_address_unit"}
                    ]}
                    type="string"
                    onChange={(p, n, v) => props.routines.update(p, n, v)}
                /></TableView.FieldView>
                <TableView.FieldView title="Street"><EditField
                    key={state.index}
                    parent={application.fields}
                    names={[
                        {src: "Agent", name: "agent_personal_address_street"},
                        {src: "Purchase", name: "purchase_personal_address_street"},
                        {src: "Qualify", name: "qualify_personal_address_street"},
                        {src: "Trade/Sell", name: "trade_personal_address_street"}
                    ]}
                    type="string"
                    onChange={(p, n, v) => props.routines.update(p, n, v)}
                /></TableView.FieldView>
                <TableView.FieldView title="City"><EditField
                    key={state.index}
                    parent={application.fields}
                    names={[
                        {src: "Agent", name: "agent_personal_address_city"},
                        {src: "Purchase", name: "purchase_personal_address_city"},
                        {src: "Qualify", name: "qualify_personal_address_city"},
                        {src: "Trade/Sell", name: "trade_personal_address_city"}
                    ]}
                    type="string"
                    onChange={(p, n, v) => props.routines.update(p, n, v)}
                /></TableView.FieldView>
                <TableView.FieldView title="Province"><EditField
                    key={state.index}
                    parent={application.fields}
                    names={[
                        {src: "Agent", name: "agent_personal_address_province"},
                        {src: "Purchase", name: "purchase_personal_address_province"},
                        {src: "Qualify", name: "qualify_personal_address_province"},
                        {src: "Trade/Sell", name: "trade_personal_address_province"}
                    ]}
                    type="string"
                    onChange={(p, n, v) => props.routines.update(p, n, v)}
                /></TableView.FieldView>
                <TableView.FieldView title="PostalCode"><EditField
                    key={state.index}
                    parent={application.fields}
                    names={[
                        {src: "Agent", name: "agent_personal_address_postalcode"},
                        {src: "Purchase", name: "purchase_personal_address_postalcode"},
                        {src: "Qualify", name: "qualify_personal_address_postalcode"},
                        {src: "Trade/Sell", name: "trade_personal_address_postalcode"}
                    ]}
                    type="string"
                    onChange={(p, n, v) => props.routines.update(p, n, v)}
                /></TableView.FieldView>
                <TableView.FieldView title="Offers & Tips"><EditField
                    key={state.index}
                    parent={application.fields}
                    names={[
                        {src: "Agent", name: "agent_personal_offersAndTips"},
                        {src: "Purchase", name: "purchase_personal_offersAndTips"},
                        {src: "Qualify", name: "qualify_personal_offersAndTips"},
                        {src: "Trade/Sell", name: "trade_personal_offersAndTips"}
                    ]}
                    type="boolean"
                    onChange={(p, n, v) => props.routines.update(p, n, v)}
                /></TableView.FieldView>
            </TableView.Column>
            <TableView.Column colSpan="3">
                <TableView.FieldTitle title="Drivers License Front" />
                <div
                    style={{
                        textAlign: "center",
                        lineHeight: "279px",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderRadius: "6px 0px 0px 6px",
                        borderColor: "#000000",
                        height: "279px"
                    }}
                    onDragOver={e => Spikes.drag.onOver(e)}
                    onDrop={e => Spikes.drag.onDrop(e)}
                    dropname="dragLicF"
                >{(state.updates.agent_license_src_front || application.fields?.agent_license_src_front || application.fields?.purchase_license_src_front || application.fields?.purchase_license_src) ? <img
                    src={state.updates.agent_license_src_front || application.fields?.agent_license_src_front || application.fields?.purchase_license_src_front || application.fields?.purchase_license_src}
                    alt="Drivers Licence"
                    onClick={() => Spikes.notify("preview", state.updates.agent_license_src_front || application.fields?.agent_license_src_front || application.fields?.purchase_license_src_front || application.fields?.purchase_license_src)}
                    style={{maxHeight: "100%", maxWidth: "100%", cursor: "pointer", display: "inline-block", verticalAlign: "middle"}}
                    draggable="true"
                    onDragStart={e => Spikes.drag.onDrag(e, "driversLicence")}
                    onDragEnd={e => Spikes.drag.onEnd(e)}    
                /> : <span style={{display: "inline-block", verticalAlign: "middles"}}>Drivers License Image (Front)</span>}</div>
            </TableView.Column>
            <TableView.Column colSpan="3">
                <TableView.FieldTitle title="Drivers License Back" />
                <div
                    style={{
                        textAlign: "center",
                        lineHeight: "279px",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderRadius: "0px 6px 6px 0px",
                        borderColor: "#000000",
                        height: "279px"
                    }}
                    onDragOver={e => Spikes.drag.onOver(e)}
                    onDrop={e => Spikes.drag.onDrop(e)}
                    dropname="dragLicB"
                >{(state.updates.agent_license_src_back || application.fields?.agent_license_src_back || application.fields?.purchase_license_src_back) ? <img
                    src={state.updates.agent_license_src_back || application.fields?.agent_license_src_back || application.fields?.purchase_license_src_back}
                    alt="Drivers Licence"
                    onClick={() => Spikes.notify("preview", state.updates.agent_license_src_back || application.fields?.agent_license_src_back || application.fields?.purchase_license_src_back)}
                    style={{maxHeight: "100%", maxWidth: "100%", cursor: "pointer", display: "inline-block", verticalAlign: "middle"}}
                    draggable="true"
                    onDragStart={e => Spikes.drag.onDrag(e, "driversLicence")}
                    onDragEnd={e => Spikes.drag.onEnd(e)}    
                /> : <span style={{display: "inline-block", verticalAlign: "middles"}}>Drivers License Image (Back)</span>}</div>
            </TableView.Column>
        </TableView.Table>
    </TableView.Body>
</div>;

const incomeExpense = (props, application, state) => <div>
    <TableView.Header><Icon name="dollar" />Income/Expense</TableView.Header>
    <TableView.Body>
        <TableView.Table>
            <TableView.Column colSpan="6">
                <TableView.FieldView title="Company"><EditField
                    key={state.index}
                    parent={application.fields}
                    names={[
                        {src: "Agent", name: "agent_finance_company"},
                        {src: "Purchase", name: "purchase_finance_company"},
                        {src: "Qualify", name: "qualify_finance_company"},
                        {src: "Trade/Sell", name: "trade_finance_company"}
                    ]}
                    type="string"
                    onChange={(p, n, v) => props.routines.update(p, n, v)}
                /></TableView.FieldView>
                <TableView.FieldView title="Employment Status"><EditField
                    key={state.index}
                    parent={application.fields}
                    names={[
                        {src: "Agent", name: "agent_finance_employmentStatus"},
                        {src: "Purchase", name: "purchase_finance_employmentStatus"},
                        {src: "Qualify", name: "qualify_finance_employmentStatus"},
                        {src: "Trade/Sell", name: "trade_finance_employmentStatus"}
                    ]}
                    type="select"
                    options={[
                        {caption: "N/A", value: 0},
                        {caption: "Employed", value: 1},
                        {caption: "Self Employed", value: 2},
                        {caption: "Retired", value: 3},
                        {caption: "Disability", value: 4},
                        {caption: "Other", value: 5},
                        {caption: "Unemployed", value: 6}
                    ]}
                    onChange={(p, n, v) => props.routines.update(p, n, v)}
                /></TableView.FieldView>
                <TableView.FieldView title="Job Title"><EditField
                    key={state.index}
                    parent={application.fields}
                    names={[
                        {src: "Agent", name: "agent_finance_jobTitle"},
                        {src: "Purchase", name: "purchase_finance_jobTitle"},
                        {src: "Qualify", name: "qualify_finance_jobTitle"},
                        {src: "Trade/Sell", name: "trade_finance_jobTitle"}
                    ]}
                    type="string"
                    onChange={(p, n, v) => props.routines.update(p, n, v)}
                /></TableView.FieldView>
                <TableView.FieldView title="Monthly Income"><EditField
                    key={state.index}
                    parent={application.fields}
                    names={[
                        {src: "Agent", name: "agent_finance_monthlyIncome"},
                        {src: "Purchase", name: "purchase_finance_monthlyIncome"},
                        {src: "Qualify", name: "qualify_finance_monthlyIncome"},
                        {src: "Trade/Sell", name: "trade_finance_monthlyIncome"}
                    ]}
                    format={v => v ? "$" + Spikes.numberCS(v, 2) : null}
                    type="string"
                    onChange={(p, n, v) => props.routines.update(p, n, v)}
                /></TableView.FieldView>
            </TableView.Column>
            <TableView.Column colSpan="6">
                <TableView.FieldView title="Residence Type"><EditField
                    key={state.index}
                    parent={application.fields}
                    names={[
                        {src: "Agent", name: "agent_finance_residenceType"},
                        {src: "Purchase", name: "purchase_finance_residenceType"},
                        {src: "Qualify", name: "qualify_finance_residenceType"},
                        {src: "Trade/Sell", name: "trade_finance_residenceType"}
                    ]}
                    type="select"
                    options={[
                        {value: 0, caption: "N/A"},
                        {value: 1, caption:  "Own"},
                        {value: 2, caption:  "Rent"}
                    ]}
                    onChange={(p, n, v) => props.routines.update(p, n, v)}
                /></TableView.FieldView>
                <TableView.FieldView title="Monthly Payments"><EditField
                    key={state.index}
                    parent={application.fields}
                    names={[
                        {src: "Agent", name: "agent_finance_monthlyPayments"},
                        {src: "Purchase", name: "purchase_finance_monthlyPayments"},
                        {src: "Qualify", name: "qualify_finance_monthlyPayments"},
                        {src: "Trade/Sell", name: "trade_finance_monthlyPayments"}
                    ]}
                    format={v => v ? "$" + Spikes.numberCS(v, 2) : null}
                    type="string"
                    onChange={(p, n, v) => props.routines.update(p, n, v)}
                /></TableView.FieldView>
            </TableView.Column>
        </TableView.Table>
    </TableView.Body>
</div>;

const sellTrade = (props, application, state) => <div>
    <TableView.Header><Icon name="car" />Vehicle For Sell/Trade</TableView.Header>
    <TableView.Options>
        <button className="solo" onClick={() => Spikes.notify("blackbookModal", props.routines.setTradeIn)}><Icon name="book" />Blackbook Selector</button>
    </TableView.Options>
    <TableView.Body>
        <TableView.Table>
            <TableView.Column colSpan="6">
                <TableView.FieldView title="Vehicle" content={[
                    state.updates.agent_sellortrade_year || application.fields.agent_sellortrade_year || application.fields.trade_sellortrade_year,
                    state.updates.agent_sellortrade_make || application.fields.agent_sellortrade_make || application.fields.trade_sellortrade_make,
                    state.updates.agent_sellortrade_model || application.fields.agent_sellortrade_model || application.fields.trade_sellortrade_model,
                    state.updates.agent_sellortrade_series || application.fields.agent_sellortrade_series || application.fields.trade_sellortrade_series,
                    state.updates.agent_sellortrade_style || application.fields.agent_sellortrade_style || application.fields.trade_sellortrade_style
                ].filter(f => f).join(" ") || application.fields.trade_sellortrade_vehicle || "N/A"} />
                <TableView.FieldView title="VIN" content={application.fields.trade_sellortrade_vin  || "N/A"} />
                <TableView.FieldView title="Odometer" content={Spikes.numberCS(state.updates.agent_sellortrade_odometer || application.fields.agent_sellortrade_odometer || application.fields.trade_sellortrade_odometer)} />
                <TableView.FieldView title="Colour" content={application.fields.trade_sellortrade_color || "N/A"} />
            </TableView.Column>
            <TableView.Column colSpan="6">
                <TableView.FieldView title="Blackbook UVC" content={application.fields.trade_sellortrade_uvc || "N/A"} />
                <TableView.FieldView title="Condition" content={props.routines.indexCondition(application.fields.trade_sellortrade_condition)} />
                <TableView.FieldView title="Comments" content={application.fields.trade_sellortrade_comments || "N/A"} />
                <TableView.FieldView title="Value Given" content={application.fields.trade_sellortrade_value ? Spikes.numberCS(application.fields.trade_sellortrade_value, 2) : "N/A"} />
            </TableView.Column>
        </TableView.Table>
    </TableView.Body>
</div>;

const addressFields = (props, application, state) => <div>
    <TableView.Table>
        <TableView.Column colSpan="6">
            <TableView.Header><Icon name="map-pin" />Billing Address</TableView.Header>
            <TableView.Body>
                <TableView.Table>
                    <TableView.Column colSpan="6">
                        <TableView.FieldView title="Same as Personal Address"><EditField
                            key={state.index}
                            parent={application.fields}
                            names={[
                                {src: "Agent", name: "agent_personal_billingAddress"},
                                {src: "Purchase", name: "purchase_personal_billingAddress"},
                                {src: "Qualify", name: "qualify_personal_billingAddress"},
                                {src: "Trade/Sell", name: "trade_personal_billingAddress"}
                            ]}
                            type="boolean"
                            onChange={(p, n, v) => props.routines.update(p, n, v)}
                        /></TableView.FieldView>
                        <TableView.FieldView title="Unit"><EditField
                            key={state.index}
                            parent={application.fields}
                            names={[
                                {src: "Agent", name: "agent_personal_billingAddress_unit"},
                                {src: "Purchase", name: "purchase_personal_billingAddress_unit"},
                                {src: "Qualify", name: "qualify_personal_billingAddress_unit"},
                                {src: "Trade/Sell", name: "trade_personal_billingAddress_unit"}
                            ]}
                            type="string"
                            onChange={(p, n, v) => props.routines.update(p, n, v)}
                        /></TableView.FieldView>
                        <TableView.FieldView title="Street"><EditField
                            key={state.index}
                            parent={application.fields}
                            names={[
                                {src: "Agent", name: "agent_personal_billingAddress_street"},
                                {src: "Purchase", name: "purchase_personal_billingAddress_street"},
                                {src: "Qualify", name: "qualify_personal_billingAddress_street"},
                                {src: "Trade/Sell", name: "trade_personal_billingAddress_street"}
                            ]}
                            type="string"
                            onChange={(p, n, v) => props.routines.update(p, n, v)}
                        /></TableView.FieldView>
                    </TableView.Column>
                    <TableView.Column colSpan="6">
                        <TableView.FieldView title="City"><EditField
                            key={state.index}
                            parent={application.fields}
                            names={[
                                {src: "Agent", name: "agent_personal_billingAddress_city"},
                                {src: "Purchase", name: "purchase_personal_billingAddress_city"},
                                {src: "Qualify", name: "qualify_personal_billingAddress_city"},
                                {src: "Trade/Sell", name: "trade_personal_billingAddress_city"}
                            ]}
                            type="string"
                            onChange={(p, n, v) => props.routines.update(p, n, v)}
                        /></TableView.FieldView>
                        <TableView.FieldView title="Province"><EditField
                            key={state.index}
                            parent={application.fields}
                            names={[
                                {src: "Agent", name: "agent_personal_billingAddress_province"},
                                {src: "Purchase", name: "purchase_personal_billingAddress_province"},
                                {src: "Qualify", name: "qualify_personal_billingAddress_province"},
                                {src: "Trade/Sell", name: "trade_personal_billingAddress_province"}
                            ]}
                            type="string"
                            onChange={(p, n, v) => props.routines.update(p, n, v)}
                        /></TableView.FieldView>
                        <TableView.FieldView title="Postal Code"><EditField
                            key={state.index}
                            parent={application.fields}
                            names={[
                                {src: "Agent", name: "agent_personal_billingAddress_postalcode"},
                                {src: "Purchase", name: "purchase_personal_billingAddress_postalcode"},
                                {src: "Qualify", name: "qualify_personal_billingAddress_postalcode"},
                                {src: "Trade/Sell", name: "trade_personal_billingAddress_postalcode"}
                            ]}
                            type="string"
                            onChange={(p, n, v) => props.routines.update(p, n, v)}
                        /></TableView.FieldView>
                    </TableView.Column>
                </TableView.Table>
            </TableView.Body>
        </TableView.Column>
        <TableView.Column colSpan="6">
            <TableView.Header><Icon name="map-pin" />Delivery Address</TableView.Header>
            <TableView.Body>
                <TableView.Table>
                    <TableView.Column colSpan="6">
                        <TableView.FieldView title="Same as Personal Address"><EditField
                            key={state.index}
                            parent={application.fields}
                            names={[
                                {src: "Agent", name: "agent_personal_deliveryAddress"},
                                {src: "Purchase", name: "purchase_personal_deliveryAddress"},
                                {src: "Qualify", name: "qualify_personal_deliveryAddress"},
                                {src: "Trade/Sell", name: "trade_personal_deliveryAddress"}
                            ]}
                            type="boolean"
                            onChange={(p, n, v) => props.routines.update(p, n, v)}
                        /></TableView.FieldView>
                        <TableView.FieldView title="Unit"><EditField
                            key={state.index}
                            parent={application.fields}
                            names={[
                                {src: "Agent", name: "agent_personal_deliveryAddress_unit"},
                                {src: "Purchase", name: "purchase_personal_deliveryAddress_unit"},
                                {src: "Qualify", name: "qualify_personal_deliveryAddress_unit"},
                                {src: "Trade/Sell", name: "trade_personal_deliveryAddress_unit"}
                            ]}
                            type="string"
                            onChange={(p, n, v) => props.routines.update(p, n, v)}
                        /></TableView.FieldView>
                        <TableView.FieldView title="Street"><EditField
                            key={state.index}
                            parent={application.fields}
                            names={[
                                {src: "Agent", name: "agent_personal_deliveryAddress_street"},
                                {src: "Purchase", name: "purchase_personal_deliveryAddress_street"},
                                {src: "Qualify", name: "qualify_personal_deliveryAddress_street"},
                                {src: "Trade/Sell", name: "trade_personal_deliveryAddress_street"}
                            ]}
                            type="string"
                            onChange={(p, n, v) => props.routines.update(p, n, v)}
                        /></TableView.FieldView>
                    </TableView.Column>
                    <TableView.Column colSpan="6">
                        <TableView.FieldView title="City"><EditField
                            key={state.index}
                            parent={application.fields}
                            names={[
                                {src: "Agent", name: "agent_personal_deliveryAddress_city"},
                                {src: "Purchase", name: "purchase_personal_deliveryAddress_city"},
                                {src: "Qualify", name: "qualify_personal_deliveryAddress_city"},
                                {src: "Trade/Sell", name: "trade_personal_deliveryAddress_city"}
                            ]}
                            type="string"
                            onChange={(p, n, v) => props.routines.update(p, n, v)}
                        /></TableView.FieldView>
                        <TableView.FieldView title="Province"><EditField
                            key={state.index}
                            parent={application.fields}
                            names={[
                                {src: "Agent", name: "agent_personal_deliveryAddress_province"},
                                {src: "Purchase", name: "purchase_personal_deliveryAddress_province"},
                                {src: "Qualify", name: "qualify_personal_deliveryAddress_province"},
                                {src: "Trade/Sell", name: "trade_personal_deliveryAddress_province"}
                            ]}
                            type="string"
                            onChange={(p, n, v) => props.routines.update(p, n, v)}
                        /></TableView.FieldView>
                        <TableView.FieldView title="Postal Code"><EditField
                            key={state.index}
                            parent={application.fields}
                            names={[
                                {src: "Agent", name: "agent_personal_deliveryAddress_postalcode"},
                                {src: "Purchase", name: "purchase_personal_deliveryAddress_postalcode"},
                                {src: "Qualify", name: "qualify_personal_deliveryAddress_postalcode"},
                                {src: "Trade/Sell", name: "trade_personal_deliveryAddress_postalcode"}
                            ]}
                            type="string"
                            onChange={(p, n, v) => props.routines.update(p, n, v)}
                        /></TableView.FieldView>
                    </TableView.Column>
                </TableView.Table>
            </TableView.Body>
        </TableView.Column>
    </TableView.Table>
</div>;

const creditElements = (props, application, state) => <div>
    <TableView.Table>
        <TableView.Column colSpan="6">
            <TableView.Header>Equifax{application.equifax && <Icon name="delete" className="link" title="Clear" style={{float: "right"}} onClick={() => props.routines.equifaxClear()} />}</TableView.Header>
            {
                application.equifax
                ? <TableView.Body style={{padding: "1px", height: "300px", overflow: "auto"}}><div className="reportHtml" style={{padding: "5px"}} dangerouslySetInnerHTML={{ __html: application.equifax?.data }}></div></TableView.Body>
                : <TableView.Body style={{padding: "1px"}}><textarea style={{width: "100%", height: "300px", resize: "none", borderStyle: "none"}} onPaste={e => props.routines.equifaxPaste(e)} placeholder="Paste Report Here"></textarea></TableView.Body>
            }
        </TableView.Column>
        <TableView.Column colSpan="6">
            <TableView.Header>TransUnion{application.transunion && <Icon name="delete" className="link" title="Clear" style={{float: "right"}} onClick={() => props.routines.transUnionClear()} />}</TableView.Header>
            {
                application.transunion
                ? <TableView.Body style={{padding: "1px", height: "300px", overflow: "auto"}}><div className="reportHtml" style={{padding: "5px"}} dangerouslySetInnerHTML={{ __html: application.transunion?.data }}></div></TableView.Body>
                : <TableView.Body style={{padding: "1px"}}><textarea style={{width: "100%", height: "300px", resize: "none", borderStyle: "none"}} onPaste={e => props.routines.transUnionPaste(e)} placeholder="Paste Report Here"></textarea></TableView.Body>
            }
        </TableView.Column>
    </TableView.Table>
</div>;

const ApplicationDesktop = props => ((application, state) => <div style={{paddingBottom: "10px"}}>
    {optionsBar(props, application, state)}
    {applicationDetails(props, application, state)}
    {applicantDetails(props, application, state)}
    <TableView.Info><div className="h2">Qualify</div></TableView.Info>
    {incomeExpense(props, application, state)}
    <TableView.Info><div className="h2">Sell/Trade</div></TableView.Info>
    {sellTrade(props, application, state)}
    <TableView.Info><div className="h2">Purchase</div></TableView.Info>
    {(application.fields.purchase_vehicleId ? true : false) && <VehiclePanelElement title="Vehicle For Purchase" key={application.fields.purchase_vehicleId} vehicleId={application.fields.purchase_vehicleId} isMobile={true} />}
    {addressFields(props, application, state)}
    {creditElements(props, application, state)}
    <TableView.Table>
        <TableView.Column colSpan="5">
            <TableView.Header><Icon name="note" />Notes<Icon name="add" className="link" title="Add Note" style={{float: "right"}} onClick={() => props.routines.editNote()} /></TableView.Header>
            <TableView.Body style={{height: "300px", overflow: "auto"}} ref={props.refs.notes}>{
                (application.notes || []).map((note, index) => <div key={index} style={{cursor: "pointer"}} onClick={() => props.routines.editNote(note)}>
                    <TableView.FieldView title={Spikes.date.lhDateTime(note.stamp) + " [" + BaseApi.data.usersNameById(note.userId) + "]"} content={note.note.split("\n").map((n, i) => <div key={i}>{n}</div>)} />
                </div>)
            }</TableView.Body>
        </TableView.Column>
        <TableView.Column colSpan="4">
            <DocumentElement profileId={props.profileId} />
        </TableView.Column>
        <TableView.Column colSpan="3">
            <TableView.Header><Icon name="history" />History</TableView.Header>
            <TableView.Body style={{height: "300px", overflow: "auto"}} ref={props.refs.history}></TableView.Body>
        </TableView.Column>
    </TableView.Table>
    <LikedVehiclesElement profileId={props.profileId} />
</div>)(props.application, props.state);
export default ApplicationDesktop;