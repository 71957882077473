import React from "react";
import BaseApi from "../../services/BaseApi";
import Spikes from "../../services/Spikes";
import Icon from "../../directives/Icon";
import siteState from '../../services/siteState';
import CommFrame from "../comms/CommFrame";

export default class HeaderFrame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pathname: window.location.pathname,
            active: this.routines.getActive(window.location.pathname),
            title: "",
            icon: "",
            info: "",
            alertMenu: false,
            alertMenuFlash: false,
            isMobile: this.props.isMobile
        };
        this.elemId = 0;
        this.alertMenuInFlash = false;
    }

    ref = {
        alertMenu: React.createRef()    
    };

    active = {
        overview: ["/overview", "/"],
        applications: ["/applications", "/application"],
        customers: ["/customers", "/customer"],
        vehicles: ["/vehicles", "/vehicle"],
        inventory: ["/inventory", "/import", "/validate", "/blackbook"],
        reports: ["/reports"],
        myaccount: ["/myAccount"]
    };

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    routines = {
        getActive: pathname => Object.keys(this.active).map(key => this.active[key].includes(pathname) ? key : "").join("")
    };

    componentDidMount() {
        this.wHolder(Spikes.watch("isMobile")(isMobile => this.setVal({isMobile: isMobile})));
        if (siteState.icon || siteState.title) {
            this.setVal({
                icon: siteState.headerIcon || "",
                title: siteState.headerTitle || "",
                info: siteState.headerInfo || ""
            });
        }
        this.wHolder(Spikes.watch("pathname")(pathname => {
            if (this.state.pathname !== pathname) {
                this.setVal({
                    pathname: pathname,
                    active: this.routines.getActive(pathname)
                });                
            }
        }));
        this.wHolder(Spikes.watch("title")((icon, title, info) => {
            this.setVal({
                icon: icon || "",
                title: title || "",
                info: info || ""
            });
        }));
        this.wHolder(Spikes.watch("alertMenuChange")(alertState => this.setVal({alertMenu: alertState})));
        this.wHolder(Spikes.watch("alertMenuFlash")(on => this.setVal({alertMenuFlash: on})));
        this.wHolder(Spikes.cycle(() => {
            if (this.state.alertMenuFlash && !this.state.alertMenu && this.ref.alertMenu.current) {
                this.alertMenuInFlash = !this.alertMenuInFlash;
                this.ref.alertMenu.current.className = this.alertMenuInFlash ? "headerbar-button-red" : "headerbar-button";
            } else if (!this.state.alertMenuFlash && !this.state.alertMenu && this.alertMenuInFlash && this.ref.alertMenu.current) {
                this.alertMenuInFlash = false;
                this.ref.alertMenu.current.className = "headerbar-button";
            }
        }, 1));
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    desktop = () => <div className="header-container">
        <div className="headerbar-main">
            <span style={{position: "fixed", top: "0px", left: "0px", whiteSpace: "nowrap", zIndex: 5}}>
                <button className={this.state.alertMenu ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("alertMenuShow")} ref={this.ref.alertMenu}><Icon size="20px" margin="0" name="menu" title="Main Menu" /></button>
                <button className={this.state.active === "overview" ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("navigate", "/overview")}><Icon size="20px" margin="0" name="chart" title="Overview" /></button>
                <button className={this.state.active === "applications" ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("navigate", "/applications")}><Icon size="20px" margin="0" name="form" title="Applications" /></button>
                <button className={this.state.active === "customers" ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("navigate", "/customers")}><Icon size="20px" margin="0" name="customer" title="Customers" /></button>
                <button className={this.state.active === "vehicles" ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("navigate", "/vehicles")}><Icon size="20px" margin="0" name="car" title="Vehicles" /></button>
                <button className={this.state.active === "reports" ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("navigate", "/reports")}><Icon size="18px" margin="0" name="document" title="Reports" /></button>
                <button className={this.state.active === "inventory" ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("navigate", "/inventory")}><Icon size="20px" margin="0" name="checklist" title="Inventory Management" /></button>
                <CommFrame isMobile={false} />
            </span>
            <span style={{position: "fixed", top: "0px", right: "0px", whiteSpace: "nowrap", zIndex: 4}}>
                <img src="/images/logo-white.png" style={{height: "25px", width: "auto", margin: "8px 8px 0px 8px"}} alt=""/>
                <button className={this.state.active === "settings" ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("navigate", "/settings")}><Icon size="20px" margin="0" name="settings" title="System Settings" /></button>
                <button className={this.state.active === "myaccount" ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("navigate", "/myAccount")}><Icon size="18px" margin="0" name="user-settings" title="Account Settings" /></button>
                <button className={"headerbar-button"} onClick={() => BaseApi.login.logout()}><Icon size="20px" margin="0" name="close" title="Log Out" /></button>
            </span>
        </div>
        <div className="header-details">
            <span className="header-title"><Icon name={this.state.icon} size="15px" key={this.state.icon} />{this.state.title}</span>
            <span className="header-title" style={{float: "right", textAlign: "right"}}>{this.state.info}</span>
        </div>
    </div>;

    mobile = () => <div className="header-container">
        <CommFrame isMobile={true} />
        <div className="mobile-headerbar-main">
            <span style={{whiteSpace: "nowrap"}}>
                <button className={this.state.alertMenu ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("alertMenuShow")} ref={this.ref.alertMenu}><Icon size="20px" margin="0" name="menu" title="Main Menu" /></button>
                <button className={this.state.active === "overview" ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("navigate", "/overview")}><Icon size="20px" margin="0" name="chart" title="Overview" /></button>
                <button className={this.state.active === "applications" ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("navigate", "/applications")}><Icon size="20px" margin="0" name="form" title="Applications" /></button>
                <button className={this.state.active === "customers" ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("navigate", "/customers")}><Icon size="20px" margin="0" name="customer" title="Customers" /></button>
                <button className={this.state.active === "vehicles" ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("navigate", "/vehicles")}><Icon size="20px" margin="0" name="car" title="Vehicles" /></button>
                <button className={this.state.active === "reports" ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("navigate", "/reports")}><Icon size="18px" margin="0" name="document" title="Reports" /></button>
            </span>
            <span style={{position: "absolute", right: "0px"}}>
                <button className={this.state.active === "settings" ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("navigate", "/settings")}><Icon size="20px" margin="0" name="settings" title="System Settings" /></button>
                <button className={this.state.active === "myaccount" ? "headerbar-button-active" : "headerbar-button"} onClick={() => Spikes.notify("navigate", "/myAccount")}><Icon size="18px" margin="0" name="user-settings" title="Account Settings" /></button>
                <button className={"headerbar-button"} onClick={() => BaseApi.login.logout()}><Icon size="20px" margin="0" name="close" title="Log Out" /></button>
            </span>            
        </div>
        <div className="header-details">
            <span className="header-title"><Icon name={this.state.icon} size="15px" key={this.state.icon} />{this.state.title}</span>
            <span className="header-title" style={{float: "right", textAlign: "right"}}>{this.state.info}</span>
        </div>
    </div>;

    render = () => (this.state.isMobile ? this.mobile : this.desktop)();
}
