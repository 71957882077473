import React from "react";
import Spikes from "../../services/Spikes";
import Icon from "../../directives/Icon";
import BaseApi from "../../services/BaseApi";
import BaseData from "../../services/BaseData";

export default class AlertMenuFrame extends React.Component {
    constructor(props) {
        super(props);
        this.alerts = {
            apps: 0,
            callbacks: 0,
            voicemail: 0,
            sms: 0
        };
        this.state = {
            show: false,
            buttons: this.routines.buttons(),
            isMobile: props.isMobile
        };
        this.mouseOver = false;
        this.routines.show(this.state.show);
        this.cValues = {
            voicemail: BaseApi.config("CALL_POST", "VOICEMAIL").value,
            callback: BaseApi.config("CALL_POST", "CALLBACK").value
        };
    }

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();
    menuRef = React.createRef();

    routines = {
        buttons: () => ([
            {index: 0, caption: "Applications", icon: "form", value: this.alerts.apps},
            {index: 1, caption: "Voicemail", icon: "voicemail", value: this.alerts.voicemail},
            {index: 2, caption: "Callbacks", icon: "enter", value: this.alerts.callbacks},
            {index: 5, caption: "All Call Logs", icon: "phone", value: 0},
            {index: 3, caption: "Unread SMS", icon: "sms", value: this.alerts.sms},
            {index: 4, caption: "Alert Settings", icon: "settings", value: 0}
        ]),
        processAlerts: () => setTimeout(() => {
            if (Object.keys(this.alerts).filter(key => (Array.isArray(this.alerts[key]) ? this.alerts[key].length : this.alerts[key]) > 0).length) {
                Spikes.notify("alertMenuFlash", true);
            } else {
                Spikes.notify("alertMenuFlash", false);
            }
            this.setVal({buttons: this.routines.buttons()});
        }),
        show: show => {
            this.setVal({show: typeof(show) === "boolean" ? show : !this.state.show}, () => {
                if (this.state.show) {
                    this.mouseOver = false;
                    window.addEventListener("mousemove", this.routines.mouseTrack);
                } else {
                    window.removeEventListener("mousemove", this.routines.mouseTrack);
                }
                Spikes.notify("alertMenuChange", this.state.show);
            });
        },
        mouseTrack: event => {
            if (this.menuRef.current) {
                const isOver = () => (
                    event.clientX > this.menuRef.current.offsetLeft &&
                    event.clientX < this.menuRef.current.offsetLeft + this.menuRef.current.offsetWidth && 
                    event.clientY > this.menuRef.current.offsetTop && 
                    event.clientY < this.menuRef.current.offsetTop + this.menuRef.current.offsetHeight
                );
                if (this.mouseOver) {
                    if (!isOver()) {this.routines.show(false);}
                } else {
                    if (isOver()) {this.mouseOver = true;}
                }
            }
        },
        click: index => {
            switch (index) {
                case 0: Spikes.notify("navigate", "/applications"); break;
                case 1: Spikes.notify("navigate", "/voicemail"); break;
                case 2: Spikes.notify("navigate", "/callbacks"); break;
                case 5: Spikes.notify("navigate", "/callLogs"); break;
                case 3: Spikes.notify("navigate", "/unreadsms"); break;
                case 4: Spikes.notify("navigate", "/alertSettings"); break;
                default:
            }
            if (this.state.isMobile) {this.setVal({show: false});}
        }
    };

    componentDidMount() {
        this.wHolder(Spikes.watch("isMobile")(isMobile => this.setVal({isMobile: isMobile})));
        this.wHolder(Spikes.watch("alertMenuShow")(show => this.routines.show(show)));
        this.wHolder(BaseData.profiles(profiles => {
            this.alerts.apps = profiles.filter(s => s.applications && s.applications.forms.filter(f => f.complete).length && (s.applications.dateModified || 0) > (s.applications.dateReviewed || 0)).length;
            this.alerts.sms = profiles.filter(s => s.index && (s.index.smsLast || 0) > (s.index.smsRead || 0)).length;
            this.routines.processAlerts();
        }));
        this.wHolder(BaseData.calls(calls => {
            this.alerts.callbacks = calls.filter(c => c.post === this.cValues.callback).length;
            this.alerts.voicemail = calls.filter(c => c.post === this.cValues.voicemail).length;
            this.routines.processAlerts();
        }));
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    render = () => !this.state.show ? null : <div
        ref={this.menuRef}
        className="alert-menu"
        style={{
            width: "240px",
            minHeight: "30px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "var(--color-1)",
            backgroundColor: "var(--color-1)",
            position: "fixed",
            top: this.state.isMobile ? "122px" : "41px",
            left: "0px",
            zIndex: 10,
            boxShadow: "3px 3px 3px var(--theme-lt-shadow-color)"
        }}
    >{this.state.buttons.map(b => <button
        key={b.index}
        className="menu-button"
        onClick={() => this.routines.click(b.index)}
    ><Icon name={b.icon} />{b.caption} {b.value > 0 && <span className="a-count">{b.value}</span>}</button>)}</div>;
    
}