import { Device } from "@twilio/voice-sdk";
import Spikes from "./Spikes";
import BaseApi from "./BaseApi";

let device = false;
let eventWatchers = [];

const notify = (event, ...data) => {
    eventWatchers.filter(watcher => watcher.event === "all" || watcher.event === event).forEach(watcher => {
        watcher.callback(event, ...data);
    });
};
const getToken = callback => {
    BaseApi.comm.getAccessToken(data => {
        if (data.success) {
            callback(data.token);
        } else {
            console.log("device_token_error", data);
            notify("device_token_error");
        }
    });
};

const Twilio = {
    isOnline: () => device ? true : false,
    online: () => {
        if (!device) {
            getToken(token => {
                device = new Device(token, {
                    appName: "CarMonk",
                    appVersion: "1.0.1",
                    codecPreferences: ["opus", "pcmu"],
                    closeProtection: "true",
                    fakeLocalDTMF: true,
                    enableRingingState: true,
                    answerOnBridge: true,
                    debug: true
                });
                ([
                    "registering",
                    "registered",
                    "unregistered",
                    "tokenWillExpire",
                    "error",
                    "ringing",
                    "closed",
                    "incoming",
                    "connect"
                ]).forEach(key => device.on(key, (...data) => notify("device_" + key, ...data)));
                device.register();
            });
        }
    },
    offline: () => {
        if (device) {
            device.disconnectAll();
            device.destroy();
            device = false;
            notify("device_destroy");
        }
    },
    updateToken: callback => {
        if (device) {
            getToken(token => {
                device.updateToken(token);
                if (typeof(callback) === "function") {callback();}
            });
        }
    },
    watch: (event, callback) => {
        if (typeof(callback) === "function") {
            let watcher = {event: event, callback: callback};
            eventWatchers.push(watcher);
            return () => {Spikes.remove(eventWatchers, watcher);};
        }
    },
    dial: (to, callback) => {
        if (device && to) {
            BaseApi.comm.dial(to)(data => {
                if (data.success) {notify("call_dial", data.record);}
                if (typeof(callback) === "function") {callback(data);}
            });
        }
    },
    answer: (callId, callback) => {
        BaseApi.comm.answer(callId)(data => {
            if (typeof(callback) === "function") {callback(data);}
        });
    },
    hangup: () => {
        if (device) {
            device.disconnectAll();
            notify("call_hangup");
        }
    },
    hold: (callId, callback) => {
        BaseApi.comm.hold(callId)(data => {
            if (typeof(callback) === "function") {callback(data);}
        });
    }
};
export default Twilio;