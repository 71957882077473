import React from "react";
import BaseData from "../../services/BaseData";
import Spikes from "../../services/Spikes";
import Icon from "../../directives/Icon";
import BaseApi from "../../services/BaseApi";
import TableView from "../../directives/TableView";

export default class CallsElement extends React.Component {
    state = {
        calls: []
    };
    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();
    ref = {
        calls: React.createRef(),
    };
    componentDidMount() {
        this.wHolder(BaseData.calls(calls => {
            this.setVal({calls: calls.filter(c => c.number === this.props.profileId)}, () => {
                if (this.ref.calls.current) {
                    this.ref.calls.current.scrollTop = this.ref.calls.current.scrollHeight;
                }
            });
        }));
    }
    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }
    render = () => <>
        <TableView.Header><Icon name="phone" />Calls</TableView.Header>
        <TableView.Body style={{height: "300px", overflow: "auto"}} ref={this.ref.calls}>
            <table className="wide">
                <thead>
                    <tr>
                        <th>Date/Time</th>
                        <th>Source</th>
                        <th>Answered By</th>
                        <th>Current State</th>
                        <th>Post State</th>
                        <th></th>
                        <th>Duration</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.calls.map(c => (
                        <tr
                            className={[c.dateModified > c.dateReviewed ? "hover-green" : "hover"]}
                            key={c.id}
                            onClick={() => Spikes.notify("callModal", c)}
                        >
                            <td style={{whiteSpace: "nowrap"}}>{Spikes.date.lhDateTime(c.dateStarted)}</td>
                            <td style={{whiteSpace: "nowrap"}}>{BaseApi.config("CALL_SOURCE", c.source).caption}</td>
                            <td style={{whiteSpace: "nowrap"}}>{BaseApi.data.usersNameById(c.agent)}</td>
                            <td style={{whiteSpace: "nowrap"}}>{BaseApi.config("CALL_STATE", c.state).caption}</td>
                            <td style={{whiteSpace: "nowrap"}}>{BaseApi.config("CALL_POST", c.post).caption}</td>
                            <td style={{whiteSpace: "nowrap"}}>{c.voicemailFile && <Icon name="voicemail" margin="0" title="Voicemail File" />}{c.recordingFile && <Icon name="mic" margin="0" title="Recording File" />}</td>
                            <td style={{whiteSpace: "nowrap"}}>{c.dateStarted && c.dateEnded ? ((c.dateEnded - c.dateStarted) / 1000).toFixed(0) : "0"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </TableView.Body>
    </>;   
}