import React from "react";
import Spikes from "./Spikes";
import siteState from "./siteState";
import BaseApi from "./BaseApi";

export default class ActiveLink extends React.Component {
    wHolder = Spikes.watchHolder();
    componentDidMount() {
        this.lastId = siteState.lastActiveId || false;
        this.cycleWatcher = Spikes.cycle(() => {
            if (this.lastId === false) {
                BaseApi.user.lastActive(data => {if (data.success) {this.lastId = data.lastId;}});
            } else {
                BaseApi.user.getActive(this.lastId)(data => {
                    if (data.success) {
                        data.records.forEach(record => {
                            if (record.id > this.lastId) {
                                this.lastId = record.id;
                                if (record.name === "serverLog") {console.info("Server Log:", ...record.data);} 
                                else {Spikes.notify(record.name, record.data);}
                            }
                        });
                    }
                });
            }
        }, 2);
        this.wHolder(this.cycleWatcher);
        this.wHolder(Spikes.watch("updateActive")(() => this.cycleWatcher({now: true})));
    }
    componentWillUnmount() {
        this.wHolder("end");
        siteState.lastActiveId = this.lastId;
    }
    render = () => null;
}