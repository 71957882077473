/* eslint eqeqeq: 0 */
import React from "react";
import Spikes from "../services/Spikes";
import styles from "./breakSearch.module.css";
import Icon from "./Icon";

class BreakSearch extends React.Component {
    constructor(props) {
        super(props);
        if (!Spikes.state.breakSearch) {Spikes.state.breakSearch = {};}
        if (props.name && !Spikes.state.breakSearch[props.name]) {Spikes.state.breakSearch[this.props.name] = {
            active: [],
            text: ""
        };} 
        this.activeFilters = props.name ? Spikes.state.breakSearch[props.name].active : [];
        this.state = {
            menu: false,
            filters: props.filters || false,
            active: this.activeFilters
        };
        this.inputRef = React.createRef();

        props.searchBack(item => {
            if ([null, undefined, ""].includes(this.inputRef.current?.value)) {return true;}
            const search = keys => {
                let sText = keys.map(key => {
                    if (!Array.isArray(key)) {
                        return item[key];
                    } else {
                        let result = item;
                        for (let value of key) {
                            if (result && typeof(result) === "object") {
                                result = result[value];
                            } else {
                                break;
                            }
                        }
                        return result;
                    }       
                }).filter(value => ![null, undefined, ""].includes(value)).join(" ").toLowerCase();
                let failed = false;
                for (let sOr of this.inputRef.current.value.split("|")) {
                    failed = false;
                    for (let sItem of sOr.trim().split(" ")) {
                        if (sItem.slice(0, 1) == "-") {
                            if (sItem.length > 1 && sText.includes(sItem.slice(1).toLowerCase())) {
                                failed = true;
                                break;
                            }
                        } else if (!sText.includes(sItem.toLowerCase())) {
                            failed = true;
                            break;
                        }
                    }
                    if (!failed) {break;}
                }
                return !failed;
            };
            if (this.activeFilters.length) {
                return search(Object.keys(props.filters).filter(key => this.activeFilters.includes(key)).map(key => props.filters[key]));
            } else if (props.filters) {
                return search(Object.keys(props.filters).map(key => props.filters[key]));
            } else {
                return search(Object.keys(item));
            }
        });
        this.keyTimer = null;
    }

    setVal = Spikes.stateStream(this);

    routines = {
        add: key => {
            if (!this.activeFilters.includes(key)) {
                this.activeFilters.push(key);
                if (this.props.name) {Spikes.state.breakSearch[this.props.name].active = this.activeFilters;}
                this.setVal({
                    active: this.activeFilters
                });
                if (![null, undefined, ""].includes(this.inputRef.current?.value)) {this.keyTimer = setTimeout(this.props.processSearch, 50);}
            }
        },
        remove: key => {
            let i = this.activeFilters.indexOf(key);
            if (i > -1) {
                this.activeFilters.splice(i, 1);
                if (this.props.name) {Spikes.state.breakSearch[this.props.name].active = this.activeFilters;}
                this.setVal({
                    active: this.activeFilters
                });
                if (![null, undefined, ""].includes(this.inputRef.current?.value)) {this.keyTimer = setTimeout(this.props.processSearch, 50);}
            }
        },
        clear: () => {
            if (this.inputRef.current.value) {
                this.inputRef.current.value = "";
                Spikes.state.breakSearch[this.props.name].text = "";
            } else {
                this.activeFilters = [];
                if (this.props.name) {
                    Spikes.state.breakSearch[this.props.name].active = [];
                }
                this.setVal({
                    active: this.activeFilters
                });   
            }
            clearTimeout(this.keyTimer);
            this.keyTimer = setTimeout(this.props.processSearch, 50);
        },
        keyUp: event => {
            if (this.props.name) {Spikes.state.breakSearch[this.props.name].text = event.target.value;}
            clearTimeout(this.keyTimer);
            this.keyTimer = setTimeout(this.props.processSearch, 500);
        }
    };

    componentDidMount() {
        if (this.props.name && !["", null, undefined].includes(Spikes.state.breakSearch[this.props.name].text)) {
            this.inputRef.current.value = Spikes.state.breakSearch[this.props.name].text;
            this.props.processSearch();
        }
    }

    render () {
        return (
            <span
                className={styles.outer}
            ><button
                className={styles.left}
            ><Icon name="search" margin="0"/></button><input
                ref={this.inputRef}
                className={styles.inputbox}
                onKeyUp={e => this.routines.keyUp(e)}
            /><span  onMouseLeave={() => this.setVal({menu: false})}>{this.state.filters && <button
                className={styles.mid}
                onClick={() => this.setVal({menu: true})}
                title="Refine Search"
            ><Icon name="caretbox-down" margin="0" /></button>}{this.state.menu && <span className={styles.menu}>{
                Object.keys(this.state.filters).filter(key => !this.state.active.includes(key)).map(key => <div key={key} className={styles.listitem} onClick={() => this.routines.add(key)}>{key}</div>)
            }</span>}{
                Object.keys(this.state.filters).filter(key => this.state.active.includes(key)).map(key => <button key={key} className={styles.mid} onClick={() => this.routines.remove(key)} title={"Remove: " + key}>{key}</button>)
            }</span><button
                onClick={() => this.routines.clear()}
                className={styles.right}
                title="Clear Search"
            ><i className="fas fa-times"></i></button></span>
        );
    }
}

export default BreakSearch;