import React from "react";
import Icon from "../../directives/Icon";
import Spikes from "../../services/Spikes";

import ModalFrame from "../../directives/ModalFrame";

class ClipBoardModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dragElems: [],
            dragBox: false,
        };
    }

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    routines = {
        cancelDrag: () => {
            this.setVal({
                dragElems: [],
                dragBox: false
            });
        },
        removeDragElem: src => {
            let media = this.state.dragElems;
            Spikes.first(media.filter(m => m === src))(m => {
                Spikes.remove(media, m);
                this.setVal({
                    dragElems: media,
                    dragBox: media.length > 0 ? true : false
                });
            });
        }
    };

    componentDidMount() {
        this.wHolder(Spikes.drag.watch()((action, drag) => {
            switch (action) {
                case "drag":
                    this.setVal({dragBox: true});
                break;
                case "drop": 
                    if (drag.source !== "dragBox" && drag.dest === "dragBox" && drag.elem.tagName === "IMG" && !this.state.dragElems.filter(de => de.src === drag.elem.src).length) {
                        let newElems = this.state.dragElems;
                        newElems.push({id: ++this.elemId, src: drag.elem.src});
                        this.setVal({dragElems: newElems}, () => {
                            this.setVal({dragBox: this.state.dragElems.length > 0 ? true : false});
                        });
                    }
                break;
                case "end":
                    this.setVal({dragBox: this.state.dragElems.length > 0 ? true : false});
                break;
                default:
            }
        }));
    }
    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }
    render() {
        return !this.state.dragBox ? null : <ModalFrame onClose={() => this.routines.cancelDrag()} top="200px" left="200px" width="330px">
            <span><Icon name="clipboard" size="20px" />Image Clipboard</span>
            <div
                style={{minHeight: "60px", maxWidth: "330px", padding: "0px", display: "block"}}
                onDragOver={e => Spikes.drag.onOver(e)}
                onDrop={e => Spikes.drag.onDrop(e)}
                dropname="dragBox"
            >{this.state.dragElems.map(elem => <img
                key={elem.src}
                src={elem.src}
                style={{maxHeight: "60px", lineHeight: "60px", cursor: "pointer", margin: "0px 0px 0px 0px", padding: "0px", display: "inline"}}
                alt=""
                draggable="true"
                onDragStart={e => Spikes.drag.onDrag(e, "dragBox")}
                onDragEnd={e => Spikes.drag.onEnd(e)}
                onClick={() => Spikes.notify("preview", elem.src)}
                onContextMenu={e => {e.preventDefault(); this.routines.removeDragElem(elem);}}
            />)}</div>
        </ModalFrame>;
    }
}
export default ClipBoardModal;