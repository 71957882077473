import React from "react";
import Spikes from "../../services/Spikes";
import BaseData from "../../services/BaseData";
import BaseApi from "../../services/BaseApi";
// import Icon from "../../directives/Icon";

export default class SmsPage extends React.Component {
    state = {
        isMobile: this.props.isMobile,
        profiles: []
    };

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    routines = {
        setTitle: () => setTimeout(() => {
            switch (this.props.filter) {
                case "sms": Spikes.notify("title", "sms", "SMS Logs", `Total: ${this.state.profiles.length}`); break;
                case "unreadsms": Spikes.notify("title", "sms", "Unread SMS", `Total: ${this.state.profiles.length}`); break;
                default:
            }
        }),
    };

    componentDidMount() {
        this.wHolder(Spikes.watch("isMobile")(isMobile => this.setVal({isMobile: isMobile})));
        setTimeout(() => window.scrollTo(0, 0));
        this.routines.setTitle();
        this.wHolder(BaseData.profiles(profiles => this.setVal({
            profiles: profiles.filter(s => s.index && (s.index.smsLast || 0) > (s.index.smsRead || 0)).map(s => ({
                id: s.id,
                firstname: s.global ? s.global.firstname : null,
                lastname: s.global ? s.global.lastname : null,
                smsLast: s.index.smsLast,
                smsRead: s.index.smsRead,
                body: Spikes.last(s.sms.filter(m => !m.userId))(m => m.body),
                agent: s.applications?.userId
            }))
        }, () => this.routines.setTitle())));
    }
    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    desktop = () => <div>
        <div className="optionsbar">

        </div>
        <table className="wide">
            <thead>
                <tr>
                    <th>Date/Time</th>
                    <th>Number</th>
                    <th>Name</th>
                    <th>Message</th>
                    <th>Agent</th>
                </tr>
            </thead>
            {
                this.state.profiles.map(c => <tbody
                    className={"hover"}
                    key={c.id}
                    onClick={() => Spikes.notify("callById", c.id, true)}
                >
                    <tr>
                        <td style={{whiteSpace: "nowrap"}}>{Spikes.date.lhDateTime(c.smsLast)}</td>
                        <td style={{whiteSpace: "nowrap"}}>{Spikes.asPhone(c.id)}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.firstname} {c.lastname}</td>
                        <td style={{whiteSpace: "nowrap"}}>{c.body}</td>
                        <td style={{whiteSpace: "nowrap"}}>{BaseApi.data.usersNameById(c.agent)}</td>
                    </tr>
                </tbody>)
            }
        </table>
    </div>;

    mobile = () => <div>
        <div className="optionsbar">
            
        </div>
    </div>;

    render = () => (this.state.isMobile ? this.mobile : this.desktop)();
}