import React from "react";
import BaseApi from "../../services/BaseApi";
import Icon from "../../directives/Icon";
import Spikes from "../../services/Spikes";
import Twilio from "../../services/TwilioRoutines";
import BaseData from "../../services/BaseData";

export default class CommFrame extends React.Component {
    state = {
        isProfile: false,
        status: "Offline",
        display: "No Calls",
        sms: [],
        profileId: false,
        callLogs: [],
        voicemail: [],
        activeCalls: [],
        alert: false,
        width: 0,
        activeCall: false,
        screenColor: "#cccccc",
        lowBytes: false,
        showSms: false,
        isMobile: this.props.isMobile
    }
    ref = {
        callLog: React.createRef()
    };
    calls = [];
    device = false;
    call = false;
    userId = typeof(BaseApi.user.info().id) === "number" ? BaseApi.user.info().id.toString() : BaseApi.user.info().id;
    profileWatcher = false;
    ringTone = new Audio("https://dsa.carmonk.ca/audio/realmeOriginal.mp3");
    isRinging = false;

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    routines = {
        clearProfileWatcher: () => {
            if (typeof(this.profileWatcher) === "function") {
                this.profileWatcher();
                this.profileWatcher = false;
            }
        },
        screenColor: () => {
            if (this.state.status !== "Online") {return "#cccccc";}
            if (!this.state.activeCall) {return "#fad660";}
            if (this.state.lowBytes) {return "#db3737";}
            return "#93ed93";
        },
        call: {
            answer: ac => {
                if (this.isRinging) {
                    this.ringTone.pause();
                    this.ringTone.currentTime = 0;
                    this.isRinging = false;
                }
                Twilio.answer(ac.id, data => {
                    if (data.success) {
                        this.setVal({activeCall: data.record}, () => {
                            if (this.state.profileId !== data.record.number) {
                                this.routines.loadProfile(data.record.number);
                            }
                        });
                    }
                });
            },
            dial: () => {
                if (this.state.profileId && !this.state.activeCall) {
                    Twilio.dial(this.state.profileId, data => {
                        if (data.success) {
                            this.setVal({activeCall: data.record}, () => {
                                if (this.state.profileId !== data.record.number) {
                                    this.routines.loadProfile(data.record.number);
                                }
                            });
                        }
                    });
                } 
            },
            hold: () => {
                if (this.state.activeCall) {
                    Twilio.hold(this.state.activeCall.id, data => {
                        if (data.success) {
                            this.setVal({activeCall: false}, () => {
                                Twilio.hangup();
                            });
                        }
                    });
                }
            },
            active: calls => calls.filter(c => (
                c.state === BaseApi.config("CALL_STATE", "WAITING").value ||
                (
                    c.state === BaseApi.config("CALL_STATE", "HOLD").value &&
                    c.agent === this.userId
                ) ||
                (
                    c.state === BaseApi.config("CALL_STATE", "FORWARD").value &&
                    c.agent === this.userId
                )
            )),
            manual: () => {
                Spikes.notify("requestNumber", <><Icon name="pound" />Enter Phone Number</>, number => {
                    this.routines.loadProfile(number);
                });
            }
        },
        loadProfile: profileId => {
            this.routines.clearProfileWatcher();
            this.profileWatcher = BaseData.profile(profileId, profile => {
                if (!profile) {
                    this.setVal({
                        display: Spikes.asPhone(profileId) + (this.state.activeCall ? " : Connected" : ""),
                        profileId: profileId,
                        sms: [],
                        callLogs: [],
                        voicemail: [],
                        isProfile: window.location.pathname === "/customer" && Spikes.getQueryParam("profileId") === profile?.id
                    });
                } else {
                    this.setVal({
                        display: Spikes.asPhone(profile?.id) + (this.state.activeCall ? " : Connected" : ""),
                        profileId: profile?.id || false,
                        sms: Array.isArray(profile?.sms) ? profile?.sms : [],
                        callLogs: profile?.calls?.calls || [],
                        voicemail: profile?.calls?.voicemail || [],
                        isProfile: window.location.pathname === "/customer" && Spikes.getQueryParam("profileId") === profile?.id
                    });
                }
            });
            Spikes.notify("smsProfile", profileId, false);
        }
    };

    componentDidMount() {
        this.wHolder(Spikes.watch("isMobile")(isMobile => this.setVal({isMobile: isMobile})));
        this.ringTone.loop = true;
        if (Twilio.isOnline) {Twilio.offline();}
        this.wHolder(Spikes.watch("pathname")(pathname => {
            if (pathname === "/customer" && Spikes.getQueryParam("profileId") === this.state.profileId) {
                if (!this.state.isProfile) {this.setVal({isProfile: true});}
            } else {
                if (this.state.isProfile) {this.setVal({isProfile: false});}
            }
        }));
        this.wHolder(Spikes.cycle(() => {
            let newState = {};
            newState.activeCalls = this.routines.call.active(this.calls);
            let screenColor = this.routines.screenColor();
            if (this.state.screenColor !== screenColor) {this.setVal({screenColor: screenColor});}
            if (this.state.activeCalls.length) {this.setVal({alert: !this.state.alert});}
        }, 1));
        this.wHolder(Spikes.watch("callProfile")(profile => {if (!this.state.activeCall) {this.routines.loadProfile(profile.id);}}));
        this.wHolder(Spikes.watch("callById")(
            (profileId, popSms) => {
                if (!this.state.activeCall) {
                    this.routines.loadProfile(profileId);
                    if (popSms) {Spikes.notify("smsProfile", this.state.profileId, true);}
                }
            }
        ));
        this.wHolder(BaseData.calls(calls => {
            this.calls = calls;
            this.setVal({activeCalls: this.routines.call.active(this.calls)}, () => {
                if (this.state.status === "Online" && !this.isRinging && !this.state.activeCall && this.state.activeCalls.filter(c => c.state === BaseApi.config("CALL_STATE", "WAITING").value).length) {
                    this.ringTone.play();
                    this.isRinging = true;
                } else if (this.isRinging && !this.state.activeCalls.filter(c => c.state === BaseApi.config("CALL_STATE", "WAITING").value).length) {
                    this.ringTone.pause();
                    this.ringTone.currentTime = 0;
                    this.isRinging = false;
                }
            });
            if (this.state.activeCall) {
                this.setVal({activeCall: calls.find(c => c.id === this.state.activeCall.id) || false}, () => {
                    if (this.state.activeCall && this.state.activeCall.state !== BaseApi.config("CALL_STATE", "ACTIVE").value) {Twilio.hangup();}
                });
            }
        }));
        this.wHolder(Twilio.watch("all", (event, ...data) => {
            if (event !== "call_sample") {console.log("Twilio Event:", event, data);}
            switch (event) {
                case "device_registering": this.setVal({status: "Connecting"}); break;
                case "device_registered": this.setVal({status: "Online"}); break;
                case "device_unregistered": this.setVal({status: "Offline"}); break;
                case "device_tokenWillExpire": Twilio.updateToken(); break;
                case "device_incoming":
                    data[0].accept();
                    break;
                case "call_accept":
                    break;
                case "call_disconnect":
                    this.setVal({activeCall: false, lowBytes: false, display: Spikes.asPhone(this.state.profileId)}); 
                    break;
                case "call_hangup":
                    this.setVal({activeCall: false, lowBytes: false, display: Spikes.asPhone(this.state.profileId)}); 
                    break;
                case "call_warning":
                    if (data[0] === "low-bytes-received") {this.setVal({lowBytes: true});}
                    break;
                case "call_warning-cleared":
                    if (data[0] === "low-bytes-received") {this.setVal({lowBytes: false});}
                    break;
                default: 
            }
        }));
    }

    componentWillUnmount() {
        this.setVal("end");
        this.routines.clearProfileWatcher();
        this.wHolder("end");
    }

    desktop = () => <>
        <button className={this.state.status === "Online" ? "headerbar-button-green" : "headerbar-button-red"} title="Set Comms Status" disabled={this.state.activeCall} onClick={() => this.state.status === "Online" ? Twilio.offline() : Twilio.online()}><Icon name="signal" size="20px" margin="0" /></button>
        <span className="commframe-display-desktop" style={{display: "inline-block", backgroundColor: this.state.screenColor}}>
            <div style={{fontSize: "9px"}}>
                <span style={{marginLeft: "3px"}}><Icon name="phone" />{this.state.callLogs.length}</span>
                <span style={{marginLeft: "7px"}}><Icon name="sms" />{this.state.sms.length}</span>
                <span style={{marginLeft: "7px"}}><Icon name="voicemail" />{this.state.voicemail.length}</span>
                <span style={{marginRight: "3px", float: "right"}}>Status: {this.state.status}</span></div>
            <div style={{fontSize: "14px", textAlign: "center"}}>{this.state.display}</div>
        </span>
        <button className="headerbar-button" disabled={this.state.activeCall} title="Manual" onClick={() => this.routines.call.manual()}><Icon name="pound" size="20px" margin="0" /></button>
        {(() => {
            if (this.state.activeCall) {return <button className="headerbar-button-active" title="Disconnect" onClick={() => Twilio.hangup()}><Icon name="stop" size="20px" margin="0" /></button>;}
            else {return <button className="headerbar-button" disabled={!(this.state.profileId && this.state.status === "Online")} title="Connect Call" onClick={() => this.routines.call.dial()}><Icon name="phone" size="20px" margin="0" /></button>;}
        })()}
        <button className="headerbar-button" title="Place On Hold" disabled={!this.state.activeCall} onClick={() => this.routines.call.hold()}><Icon name="pause" size="20px" margin="0" /></button>
        <button className="headerbar-button" title="Forward Call" disabled={!this.state.activeCall}><Icon name="share" size="20px" margin="0" /></button>
        <button className="headerbar-button" disabled={!this.state.profileId} title="SMS" onClick={() => Spikes.notify("smsProfile", this.state.profileId, true)}><Icon name="sms" size="20px" margin="0" /></button>
        <button className={this.state.isProfile ? "headerbar-button-active" : "headerbar-button"} title="Display Profile" disabled={!this.state.profileId} onClick={() => Spikes.notify("navigate", `/customer?profileId=${this.state.profileId}`)}><Icon name="arrow-down" size="20px" margin="0" /></button>
        {this.state.activeCalls.map(ac => (
            <button
                key={ac.id}
                onClick={() => this.routines.call.answer(ac)}
                className={this.state.alert ? "headerbar-button-red" : "headerbar-button-green"}
                disabled={this.state.activeCall || this.state.status !== "Online"}
            >
                <Icon 
                    size="15"
                    name={(ac.state === BaseApi.config("CALL_STATE", "HOLD").value && "pause") || "in"} 
                    title={(ac.state === BaseApi.config("CALL_STATE", "HOLD").value && "On Hold") || "Inbound Call"}
                />{Spikes.asPhone(ac.number)}:{parseInt((Spikes.date.stamp() - ac.dateStarted) / 1000)}
            </button>
        ))}
    </>; 

    mobile = () => <>
        <div className="commframe-display-mobile" style={{backgroundColor: this.state.screenColor}}>
            <div style={{fontSize: "9px"}}>
                <span style={{marginLeft: "3px"}}><Icon name="phone" />{this.state.callLogs.length}</span>
                <span style={{marginLeft: "7px"}}><Icon name="sms" />{this.state.sms.length}</span>
                <span style={{marginLeft: "7px"}}><Icon name="voicemail" />{this.state.voicemail.length}</span>
                <span style={{marginRight: "3px", float: "right"}}>Status: {this.state.status}</span></div>
            <div style={{fontSize: "14px", textAlign: "center"}}>{this.state.display}</div>
        </div>
        <div className="mobile-headerbar-main">
            <button className={this.state.status === "Online" ? "headerbar-button-green" : "headerbar-button-red"} title="Set Comms Status" disabled={this.state.activeCall} onClick={() => this.state.status === "Online" ? Twilio.offline() : Twilio.online()}><Icon name="signal" size="20px" margin="0" /></button>
            {(() => {
                if (this.state.activeCall) {return <button className="headerbar-button-active" title="Disconnect" onClick={() => Twilio.hangup()}><Icon name="stop" size="20px" margin="0" /></button>;}
                else {return <button className="headerbar-button" disabled={!(this.state.profileId && this.state.status === "Online")} title="Connect Call" onClick={() => this.routines.call.dial()}><Icon name="phone" size="20px" margin="0" /></button>;}
            })()}
            <button className="headerbar-button" title="Place On Hold" disabled={!this.state.activeCall} onClick={() => this.routines.call.hold()}><Icon name="pause" size="20px" margin="0" /></button>
            <button className="headerbar-button" title="Forward Call" disabled={!this.state.activeCall}><Icon name="share" size="20px" margin="0" /></button>
            <span style={{position: "absolute", right: "0px"}}>
                <button className="headerbar-button" disabled={!this.state.profileId} title="SMS" onClick={() => Spikes.notify("smsProfile", this.state.profileId, true)}><Icon name="sms" size="20px" margin="0" /></button>
                <button className={this.state.isProfile ? "headerbar-button-active" : "headerbar-button"} title="Display Profile" disabled={!this.state.profileId} onClick={() => Spikes.notify("navigate", `/customer?profileId=${this.state.profileId}`)}><Icon name="arrow-down" size="20px" margin="0" /></button>
            </span>
            {this.state.activeCalls.map(ac => (
                <button
                    key={ac.id}
                    onClick={() => this.routines.call.answer(ac)}
                    className={this.state.alert ? "headerbar-button-red" : "headerbar-button-green"}
                    disabled={this.state.activeCall || this.state.status !== "Online"}
                >
                    <Icon 
                        size="15"
                        name={(ac.state === BaseApi.config("CALL_STATE", "HOLD").value && "pause") || "in"} 
                        title={(ac.state === BaseApi.config("CALL_STATE", "HOLD").value && "On Hold") || "Inbound Call"}
                    />{Spikes.asPhone(ac.number)}:{parseInt((Spikes.date.stamp() - ac.dateStarted) / 1000)}
                </button>
            ))}
        </div>
    </>;

    render = () => this.state.isMobile ? this.mobile() : this.desktop();
}