import React from "react";
import Icon from "../../directives/Icon";
import Spikes from "../../services/Spikes";
import CsvHandler from "../../services/CsvHandler";
import BaseApi from "../../services/BaseApi";
import BaseData from "../../services/BaseData";
import GoBack from "../../directives/GoBack";
import financing from "../../services/financing";

export default class ImportPage extends React.Component {
    state = {
        importData: false,
        vehicles: [],
        loading: false
    };
    imported = [];

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    routines = {
        process: () => {
            this.setVal({loading: true});
            let existing = this.state.vehicles;
            const getVin = vehicle => {
                if (vehicle.dealer && typeof(vehicle.dealer.vin) === "string") {return vehicle.dealer.vin.toUpperCase();}
                return "";
            };
            this.imported.forEach(iv => {
                Spikes.first(existing.filter(ev => !ev.matched && getVin(ev.vehicle) === iv.vin.toUpperCase()))(
                    ev => {
                        ev.matched = true;
                        iv.existing = true;
                        iv.cmId = ev.vehicle.id;
                    },
                    () => {
                        iv.existing = false;
                    }
                );
            });
            this.setVal({
                importData: this.imported,
                toRemove: existing.filter(ev => !ev.matched),
                loading: false
            }, () => {
                setTimeout(() => Spikes.notify("title", "upload", "Import Updated Inventory List", `New: ${this.state.importData?.filter(v => !v.existing).length || 0} | Existing: ${this.state.importData?.filter(v => v.existing).length || 0} | Removing: ${existing?.filter(ev => !ev.matched).length} || 0`));
            });
        },
        upload: () => {
            CsvHandler.upload((success, file) => {
                if (success && file.data) {
                    this.imported = CsvHandler.fromCSV(file.data).map(li => {
                        li.cost = Spikes.toNumber(li.cost) || null;
                        li.odometer = Spikes.toNumber(li.odometer) || null;
                        return li;
                    });
                    console.log("Import Raw:", this.imported);
                    this.routines.process();
                }
            });
        },
        confirm: () => {
            let nRecords = this.state.importData.filter(v => !v.existing).map(t => {
                let f = financing.calculateTermRate(t.year, t.cost + 3000);
                return {
                    dealerId: t.id,
                    body: t.body,
                    colour: t.colour,
                    cost: t.cost,
                    name: t.name,
                    odometer: t.odometer,
                    vin: t.vin,
                    year: t.year,
                    rate: f.rate,
                    term: f.term
                };
            });
            let eRecords = this.state.importData.filter(v => v.existing).map(t => ({
                id: t.cmId,
                dealer: {
                    dealerId: t.id,
                    body: t.body,
                    colour: t.colour,
                    cost: t.cost,
                    name: t.name,
                    odometer: t.odometer,
                    vin: t.vin,
                    year: t.year
                }
            }));
            let aRecords = this.state.toRemove.map(t => t.vehicle.id);
            let q = Spikes.queue();
            q.add("import", cb => BaseApi.vehicles.importVehicles(nRecords)(data => cb(data)));
            q.add("existing", cb => BaseApi.vehicles.updateDealer(eRecords)(data => cb(data)));
            q.add("archive", cb => BaseApi.vehicles.archiveVehicles(aRecords)(data => cb(data)));
            q.execute(results => {
                console.log("Import Success:", results);
                this.routines.process();
            });
        }
    };

    componentDidMount() {
        setTimeout(() => Spikes.notify("title", "upload", "Import Updated Inventory List"));
        setTimeout(() => window.scrollTo(0, 0));
        this.wHolder(BaseData.vehicles(vehicles => this.setVal({vehicles: vehicles.map(v => ({matched: false, vehicle: v}))}, () => {
            if (this.state.importData) {this.routines.process();}
        })));
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    content = {
        menu: <>
            <div className="optionsbar">
                <GoBack className="solo"><Icon name="backward" />Back</GoBack>
                <button className="solo" onClick={() => this.routines.upload()}><Icon name="upload" />Upload Vehicle List</button>
                <span className="right">
                    <button className="solo" onClick={() => this.routines.confirm()}><Icon name="check" />Confirm & Update</button>
                </span>
            </div>
        </>
    };

    render() {
        if (this.state.loading) {return <img src="/images/loading_rs2.gif" className="center-screen" alt="Loading..." />;}
        return <div style={{paddingBottom: "10px"}}>
            {this.content.menu}
            {!this.state.importData && <div style={{padding: "5px"}}>
                <h2>Import Current Vehicle List</h2>
                <p>Edit field headers to:</p>
                <p>id, vin, body, year, name, colour, cost, odometer</p>
                <p>Then Save As ".csv" format.</p>
                <p>Then click [Upload Vehicle List] and select the CSV file that you have saved to your hard drive.</p>
            </div>}
            {this.state.importData && <div>
                <table className="wide">
                    <thead>
                        <tr>
                            <th>CM ID</th>
                            <th>Dealer ID</th>
                            <th>VIN</th>
                            <th>Status</th>
                            <th>Body</th>
                            <th>Year</th>
                            <th>Name</th>
                            <th>Colour</th>
                            <th>Cost</th>
                            <th>Odometer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.importData.filter(v => !v.existing).map((v, i) => (
                            <tr
                                className="hover"
                                key={i}
                                // onClick={() => this.setVal({navigate: `/vehicle?vehicleId=${v.id}`})}
                            >
                                <td></td>
                                <td style={{whiteSpace: "nowrap"}}>{v.id}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.vin}</td>
                                <td style={{whiteSpace: "nowrap"}}>{"Importing"}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.body}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.year}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.name}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.colour}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.cost ? "$" + Spikes.numberCS(v.cost, 2) : "N/A"}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.odometer ? Spikes.numberCS(v.odometer) : "N/A"}</td>
                            </tr>
                        ))}
                        {this.state.importData.filter(v => v.existing).map((v, i) => (
                            <tr
                                className="hover"
                                key={i}
                                // onClick={() => this.setVal({navigate: `/vehicle?vehicleId=${v.id}`})}
                            >
                                <td style={{whiteSpace: "nowrap"}}>{v.cmId}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.id}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.vin}</td>
                                <td style={{whiteSpace: "nowrap"}}>{"Existing"}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.body}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.year}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.name}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.colour}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.cost ? "$" + Spikes.numberCS(v.cost, 2) : "N/A"}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.odometer ? Spikes.numberCS(v.odometer) : "N/A"}</td>
                            </tr>
                        ))}
                        {this.state.toRemove.map((v, i) => (
                            <tr
                                className="hover"
                                key={i}
                                // onClick={() => this.setVal({navigate: `/vehicle?vehicleId=${v.id}`})}
                            >
                                <td style={{whiteSpace: "nowrap"}}>{v.vehicle.id}</td>
                                <td></td>
                                <td style={{whiteSpace: "nowrap"}}>{v.vehicle.details?.vin || v.vehicle.dealer?.vin}</td>
                                <td style={{whiteSpace: "nowrap"}}>{"Archiving"}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.vehicle.features?.body_type || v.vehicle.dealer?.body_type}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.vehicle.features?.year}</td>
                                <td style={{whiteSpace: "nowrap"}}>{
                                    ([
                                        v.vehicle.features?.year,
                                        v.vehicle.features?.make,
                                        v.vehicle.features?.model,
                                        v.vehicle.features?.trim
                                    ].map(v => ["string", "number"].includes(typeof(v)) ? v : "").filter(v => v !== "").join(" "))
                                }</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.vehicle.details?.exterior_color}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.vehicle.details?.price ? "$" + Spikes.numberCS(v.vehicle.details?.price, 2) : "N/A"}</td>
                                <td style={{whiteSpace: "nowrap"}}>{v.vehicle.details?.kilometers ? Spikes.numberCS(v.vehicle.details?.kilometers) : "N/A"}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
        </div>;
    }
}