import React from "react";
import Spikes from "../../services/Spikes";
import ModalFrame from "../../directives/ModalFrame";
import BaseApi from "../../services/BaseApi";
import Icon from "../../directives/Icon";

export default class BlackBookModal extends React.Component {
    state = {callback: false};
    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();
    
    close = () => this.setVal({callback: false});

    submit = (condition, value) => {
        if (typeof(this.state.callback) === "function") {
            this.state.callback({
                year: this.state.year,
                make: this.state.make,
                model: this.state.model,
                series: this.state.serie,
                style: this.state.style,
                kilometers: this.state.kilometers,
                uvc: this.state.uvc,
                condition: condition,
                value: value
            });
        }
        this.close();
    };

    byDrill = (name, event) => {
        let newState = this.state;
        newState[name] = event.target.value;
        let fieldIndex = [
            "year",
            "make",
            "model",
            "serie",
            "style"
        ].indexOf(name);
        if (fieldIndex < 1) {newState.make = "";}
        if (fieldIndex < 2) {newState.model = "";}
        if (fieldIndex < 3) {newState.serie = "";}
        if (fieldIndex < 4) {newState.style = "";}
        BaseApi.blackBook.drillUvc(
            newState.year || null,
            newState.make || null,
            newState.model || null,
            newState.serie || null,
            newState.style || null
        )(data => {
            if (newState.make !== data.selected.make) {newState.make = data.selected.make || "";}
            if (newState.model !== data.selected.model) {newState.model = data.selected.model || "";}
            if (newState.serie !== data.selected.serie) {newState.serie = data.selected.series || "";}
            if (newState.style !== data.selected.style) {newState.style = data.selected.style || "";}
            if (fieldIndex < 1) {newState.makes = data.lists.makes;}
            if (fieldIndex < 2) {newState.models = data.lists.models;}
            if (fieldIndex < 3) {newState.series = data.lists.series;}
            if (fieldIndex < 4) {newState.styles = data.lists.styles;}
            newState.uvc = data.uvc || false;
            newState.values = {};
            this.setVal(newState);
        });
    };

    getValues = () => {
        if (this.state.uvc && this.state.kilometers) {
            BaseApi.blackBook.usedByUvc(this.state.uvc, this.state.kilometers)(data2 => {
                this.setVal({values: data2.values});
            });
        }
    };

    reset = cb => this.setVal({
        year: "",
        make: "",
        modal: "",
        serie: "",
        style: "",
        uvc: "",
        values: {},
        kilometers: ""
    }, () => {if (typeof(cb) === "function") {cb();}});

    componentDidMount = () => {
        this.wHolder(Spikes.watch("blackbookModal")(callback => {
            this.reset();
            this.setVal({
                addType: 0,
                makes: [],
                models: [],
                series: [],
                styles: [],
                uvc: null,
                equipment: false,
                callback: callback
            })
        }));
    };

    componentWillUnmount = () => {
        this.setVal("end");
        this.wHolder("end");
    };

    render = () => !this.state.callback ? null : <ModalFrame width="500px" onClose={() => this.close()}>
        <span><Icon name="book" />Blackbook Selector</span>
        <table style={{width: "100%"}}>
            <tbody>
                {this.state.equipment && this.state.equipment.map((eq, i) => <tr key={i}><td>{eq.category}</td><td>{eq.equipment}</td><td>{eq.value}</td></tr>)}
                <tr>
                    <td style={{textAlign: "right"}}>Year:</td>
                    <td colSpan={2}><select onChange={e => this.byDrill("year", e)}><option value="">Select Year</option>{
                        (() => {
                            let years = [];
                            for (let i = 2022; i >= 2002; i--) {years.push(i.toString());}
                            return years;
                        })().map(year => <option key={year} value={year}>{year}</option>)
                    }</select></td>
                </tr>
                <tr>
                    <td style={{textAlign: "right"}}>Make:</td>
                    <td colSpan={2}><select defaultValue={this.state.make || ""} onChange={e => this.byDrill("make", e)}><option value="">Select Make</option>{
                        this.state.makes.map(make => <option key={make} value={make}>{make}</option>)
                    }</select></td>
                </tr>
                <tr>
                    <td style={{textAlign: "right"}}>Models:</td>
                    <td colSpan={2}><select defaultValue={this.state.model || ""} onChange={e => this.byDrill("model", e)}><option value="">Select Model</option>{
                        this.state.models.map(model => <option key={model} value={model}>{model}</option>)
                    }</select></td>
                </tr>
                <tr>
                    <td style={{textAlign: "right"}}>Series:</td>
                    <td colSpan={2}><select defaultValue={this.state.serie || ""} onChange={e => this.byDrill("serie", e)}><option value="">Select Series</option>{
                        this.state.series.map(series => <option key={series} value={series}>{series}</option>)
                    }</select></td>
                </tr>
                <tr>
                    <td style={{textAlign: "right"}}>Styles:</td>
                    <td colSpan={2}><select defaultValue={this.state.style || ""} onChange={e => this.byDrill("style", e)}><option value="">Select Style</option>{
                        this.state.styles.map(style => <option key={style} value={style}>{style}</option>)
                    }</select></td>
                </tr>
                <tr>
                    <td style={{textAlign: "right"}}>Odometer:</td>
                    <td colSpan={2}><input defaultValue={this.state.kilometers || ""} onChange={e => {e.target.value = e.target.value.replace(/\D/g, ""); this.setVal({kilometers: e.target.value});}} /></td>
                </tr>
                <tr>
                    <td style={{textAlign: "right"}}>UVC:</td>
                    <td colSpan={2}>{this.state.uvc}</td>
                </tr>
                <tr>
                    <td style={{textAlign: "right"}}>Values By Condition:</td>
                    <td style={{textAlign: "right"}}>Rough: $</td>
                    <td><button disabled={!this.state.values?.rough} onClick={() => this.submit(3, this.state.values?.rough)}>{this.state.values?.rough ? "$" + Spikes.numberCS(this.state.values.rough, 2) : "Pending"}</button></td>
                </tr>
                <tr>
                    <td></td>
                    <td style={{textAlign: "right"}}>Average: $</td>
                    <td><button disabled={!this.state.values?.avg} onClick={() => this.submit(2, this.state.values?.avg)}>{this.state.values?.avg ? "$" + Spikes.numberCS(this.state.values.avg, 2) : "Pending"}</button></td>
                </tr>
                <tr>
                    <td></td>
                    <td style={{textAlign: "right"}}>Clean: $</td>
                    <td><button disabled={!this.state.values?.clean} onClick={() => this.submit(1, this.state.values?.clean)}>{this.state.values?.clean ? "$" + Spikes.numberCS(this.state.values.clean, 2) : "Pending"}</button></td>
                </tr>
                <tr>
                    <td colSpan={3} style={{textAlign: "right"}}><button onClick={() => this.reset()}>Reset</button><button disabled={!this.state.uvc || !this.state.kilometers} onClick={() => this.getValues()}>Submit</button></td>
                </tr>
            </tbody>
        </table>
    </ModalFrame>;
    
}