/* eslint eqeqeq: 0 */
import React from "react";
import BaseApi from "../../services/BaseApi";
import Spikes from "../../services/Spikes";
import Icon from "../../directives/Icon";
import BaseData from "../../services/BaseData";
import ModalFrame from "../../directives/ModalFrame";
import FieldView2 from "./FieldView2";
import TableView from "../../directives/TableView";
import GoBack from "../../directives/GoBack";
import financing from "../../services/financing";

export default class VehiclePage extends React.Component {
    state = {
        vehicle: false,
        likedProfiles: [],
        navigate: false,
        preview: false,
        changed: false,
        imageChanged: false
    };
    setDataWatch = [];
    listingUpdates = {};
    
    ref = {
        tag: React.createRef(),
        notes: React.createRef(),
        history: React.createRef()
    };

    updates = {
        details: {},
        features: {},
        options: {}
    };
    blackbookData = {};
    vehicleId = Spikes.getQueryParam("vehicleId");

    setVal = Spikes.stateStream(this);
    wHolder = Spikes.watchHolder();

    inSave = false;
    controls = [];
    cross = {
        scroll: elem => setTimeout(() => {
            if (!elem || elem === "notes") {this.ref.notes.current.scrollTop = this.ref.notes.current.scrollHeight;}
            if (!elem || elem === "history") {this.ref.history.current.scrollTop = this.ref.history.current.scrollHeight;}
        }),
        controls: controls => {
            Spikes.first(this.controls.filter(c => c.group === controls.group && c.name === controls.name))(
                c => {
                    Object.keys(c).forEach(key => delete(c[key]));
                    Object.keys(controls).forEach(key => {c[key] = controls[key];});
                }, () => this.controls.push(controls)
            );
        },
        save: (parent, name, value) => {
            this.updates[parent][name] = value;
            if (!this.inSave) {
                this.inSave = true;
                setTimeout(() => {
                    let isChanged = false;
                    (["details", "features"]).forEach(cat => {
                        Object.keys(this.updates[cat]).forEach(key => {
                            if (this.updates[cat][key] === this.state.vehicle[cat][key]) {delete(this.updates[cat][key]);}
                        });
                    });
                    console.log("Updates:", this.updates);
                    Object.keys(this.updates).forEach(key => {if (Object.keys(this.updates[key]).length) {isChanged = true;}});
                    if (isChanged !== this.state.changed) {this.setVal({changed: isChanged});}
                    this.inSave = false;
                }, 100);
            }
        }
    };
    
    format = {
        money: v => [undefined, null, ""].includes(v) ? "N/A" : "$" + Spikes.numberCS(v, 2),
        percent: v => [undefined, null, ""].includes(v) ? "N/A" : Spikes.numberCS(v, 2) + "%",
        string: v => v || "N/A",
        boolean: v => {switch (v) {case true: return "True"; case false: return "False"; default: return "N/A";}},
        number: v => [undefined, null, ""].includes(v) ? "N/A" : Spikes.numberCS(v, 0),
        bodyType: [
            {caption: "N/A", value: ""},
            {caption: "Coupe", value: "Coupe"},
            {caption: "Sedan", value: "Sedan"},
            {caption: "Hatchback", value: "Hatchback"},
            {caption: "Station Wagon", value: "Station Wagon"},
            {caption: "Convertible", value: "Convertible"},
            {caption: "SUV", value: "SUV"},
            {caption: "Crossover", value: "Crossover"},
            {caption: "Pickup", value: "Pickup"},
            {caption: "Van", value: "Van"}
        ],
        vehicleType: [
            {caption: "N/A", value: ""},
            {caption: "Car", value: "Car"},
            {caption: "Truck", value: "Truck"},
            {caption: "Van", value: "Van"},
            {caption: "SUV", value: "SUV"}
        ],
        driveType: [
            {caption: "N/A", value: ""},
            {caption: "FWD", value: "FWD"},
            {caption: "RWD", value: "RWD"},
            {caption: "AWD", value: "AWD"},
            {caption: "4x4", value: "4x4"}
        ],
        colour: [
            {caption: "N/A", value: ""},
            {caption: "White", value: "White"},
            {caption: "Grey", value: "Grey"},
            {caption: "Silver", value: "Silver"},
            {caption: "Black", value: "Black"},
            {caption: "Brown", value: "Brown"},
            {caption: "Gold", value: "Gold"},
            {caption: "Red", value: "Red"},
            {caption: "Dark Red", value: "Dark Red"},
            {caption: "Blue", value: "Blue"},
            {caption: "Dark Blue", value: "Dark Blue"},
            {caption: "Green", value: "Green"},
            {caption: "Dark Green", value: "Dark Green"},
            {caption: "Orange", value: "Orange"},
            {caption: "Yellow", value: "Yellow"},
            {caption: "Pink", value: "Pink"},
            {caption: "Purple", value: "Purple"}
        ],
        certification: [
            {caption: "N/A", value: ""},
            {caption: "Certified", value: "Certified"},
            {caption: "As Is (Not Certified)", value: "As Is (Not Certified)"}
        ]
    };

    routines = {
        syncSetData: group => {
            this.controls.filter(c => c.group === group).forEach(c => c.sync());
            let isChanged = false;
            (["details", "features"]).forEach(cat => {
                Object.keys(this.updates[cat]).forEach(key => {
                    if (this.updates[cat][key] === this.state.vehicle[cat][key]) {delete(this.updates[cat][key]);}
                });
            });
            Object.keys(this.updates).forEach(key => {if (Object.keys(this.updates[key]).length) {isChanged = true;}});
            if (isChanged !== this.state.changed) {this.setVal({changed: isChanged});}
        },
        resetData: () => {
            this.controls.forEach(c => c.reset());
            this.updates = {
                details: {},
                features: {},
                options: {}
            };
            if (this.state.changed) {this.setVal({changed: false});}
        },
        setVisible: () => BaseApi.vehicles.setVisible(this.vehicleId, this.state.vehicle.visible ? false : true)(data => {
            Spikes.notify("pop", {
                group: "default",
                message: this.state.vehicle.visible ? "Vehicle Profile Set to Visible" : "Vehicle Profile Set to Invisible",
                icon: this.state.vehicle.visible ? "visible" : "invisible",
                timeout: 10000
            });
        }),
        sendSmsLink: () => {
            Spikes.notify("remoteSms", {
                body: `${([this.state.vehicle.features?.year, this.state.vehicle.features?.make, this.state.vehicle.features?.model, this.state.vehicle.features?.trim]).filter(vf => vf).join(" ")}\r\nhttps://www.carmonk.ca/vehicle?vehicleId=${this.vehicleId}`,
                media: this.state.vehicle.images.length ? [this.state.vehicle.images[0].url] : []
            });
        },
        confirmSchema: source => {
            if (!source.details) {source.details = {};}
            if (!source.features) {source.features = {};}
            if (!source.dealer) {source.dealer = {};}
            if (!source.images) {source.images = [];}
            if (!source.featureSource) {source.featureSource = {};}
            if (!source.liked) {source.liked = {};}
            return source;
        },
        addImage: img => {
            let vehicle = this.state.vehicle;
            if (!vehicle.images) {vehicle.images = [];}
            vehicle.images.push(img);
            this.setVal({
                vehicle: vehicle,
                imageChanged: true
            });
        },
        removeImage: img => {
            let vehicle = this.state.vehicle;
            if (!vehicle.images) {vehicle.images = [];}
            Spikes.remove(vehicle.images, Spikes.first(vehicle.images.filter(f => f.url === img.url))());
            this.setVal({
                vehicle: vehicle,
                imageChanged: true
            });
        },
        moveLeft: img => {
            let vehicle = this.state.vehicle;
            if (!vehicle.images) {vehicle.images = [];}
            Spikes.first(vehicle.images.filter(f => f.url === img.url))(elem => {
                let index = vehicle.images.indexOf(elem);
                if (index > 0) {
                    vehicle.images.splice(index, 1);
                    vehicle.images.splice(index - 1, 0, elem);
                    this.setVal({
                        vehicle: vehicle,
                        changed: true
                    });
                }
            });
        },
        moveRight: img => {
            let vehicle = this.state.vehicle;
            if (!vehicle.images) {vehicle.images = [];}
            Spikes.first(vehicle.images.filter(f => f.url === img.url))(elem => {
                let index = vehicle.images.indexOf(elem);
                if (index < vehicle.images.length - 1) {
                    vehicle.images.splice(index, 1);
                    vehicle.images.splice(index + 1, 0, elem);
                    this.setVal({
                        vehicle: vehicle,
                        changed: true
                    });
                }
            });
        },
        getFromBlackBook: () => BaseApi.blackBook.basicByVin(this.state.vehicle?.source?.vin || this.state.vehicle?.details?.vin || this.state.vehicle?.dealer?.vin)(rx => {
            this.blackbookData = {};
            console.log("BlackBook Data:", (this.state.vehicle?.source?.vin || this.state.vehicle?.details?.vin || this.state.vehicle?.dealer?.vin), rx);
            if (rx.success) {
                console.log("Blackbook:", rx);
                if (rx.vehicles.length === 1) {
                    this.blackbookData = {
                        year: rx.vehicles[0].year,
                        make: rx.vehicles[0].make,
                        model: rx.vehicles[0].model,
                        trim: rx.vehicles[0].series,
                        uvc: rx.vehicles[0].uvc,
                        style: rx.vehicles[0].style,
                        drive_type: rx.vehicles[0].drive_type
                    };
                    BaseApi.blackBook.equipmentByUvc(rx.vehicles[0].uvc)(rx2 => {
                        console.log("equipmentByUvc",rx.vehicles[0].uvc, rx2);
                        if (rx2.success) {
                            Object.keys(rx2.equipment).forEach(key => {
                                this.blackbookData[key] = rx2.equipment[key];
                            });
                            BaseApi.vehicles.saveFeatureSource(this.vehicleId, this.blackbookData)(nd => {
                                console.log("saveSource:", nd);
                            });
                        }
                    });
                } else {
                    Spikes.notify("modal", {
                        title: "Select Vehicle Style",
                        content: [
                            {
                                name: "vehicleStyle",
                                type: "select",
                                caption: "Style",
                                options: rx.vehicles.map(s => ({label: `${s.year} ${s.make} ${s.model} ${s.series} ${s.style}`, value: s.uvc})),
                                width: "300px"
                            }
                        ],
                        buttons: [
                            {
                                icon: "cancel",
                                caption: "Cancel",
                                routine: options => {options.close();}
                            },
                            {
                                icon: "save",
                                caption: "Save",
                                routine: options => {
                                    let uvc = options.valueOf("vehicleStyle");
                                    Spikes.first(rx.vehicles.filter(v => v.uvc == uvc))(v => {
                                        this.blackbookData = {
                                            year: v.year,
                                            make: v.make,
                                            model: v.model,
                                            trim: v.series,
                                            uvc: v.uvc,
                                            style: v.style,
                                            drive_type: v.drive_type
                                        };
                                    });
                                    BaseApi.blackBook.equipmentByUvc(uvc)(rx3 => {
                                        console.log("equipmentByUvc:", uvc, rx3);
                                        if (rx3.success) {
                                            Object.keys(rx3.equipment).forEach(key => {
                                                this.blackbookData[key] = rx3.equipment[key];
                                            });
                                            BaseApi.vehicles.saveFeatureSource(this.vehicleId, this.blackbookData)(nd => {
                                                if (nd.success) {
                                                    Spikes.notify("pop", {
                                                        group: "default",
                                                        message: `Vehicle Feature Source Data Updated`,
                                                        icon: "notify",
                                                        timeout: 10000
                                                    });
                                                }
                                            });
                                        }
                                    });
                                    options.close();
                                }
                            }
                        ]
                    });
                }
            }
        }),
        saveListing: () => {
            let detailKeys = Object.keys(this.updates.details);
            let featureKeys = Object.keys(this.updates.features);
            let optionKeys = Object.keys(this.updates.options);

            let details = detailKeys.length ? (() => {
                let source = this.state.vehicle.details || {};
                detailKeys.forEach(key => {source[key] = this.updates.details[key];});
                return source;
            })() : false;

            let features = featureKeys.length ? (() => {
                let source = this.state.vehicle.features || {};
                featureKeys.forEach(key => {source[key] = this.updates.features[key];});
                return source;
            })() : false;

            let options = optionKeys.length ? (() => {
                let source = this.state.vehicle.options || {};
                optionKeys.forEach(key => {source[key] = this.updates.options[key];});
                return source;
            })() : false;

            BaseApi.vehicles.saveVehicle(this.vehicleId, this.ref.tag.current?.value || "", details, features, options, this.state.vehicle.images)(data => {
                if (data.success) {
                    this.updates = {
                        details: {},
                        features: {},
                        options: {}
                    };
                    this.setVal({
                        changed: false,
                        imageChanged: false
                    }, () => {
                        this.routines.resetData();
                    });
                    Spikes.notify("pop", {message: `Vehicle ${this.state.vehicle.id} has been updated.`});
                }
            });
        },
        calculateFinance: provider => {
            if (provider === "nb") {
                financing.calculateTermRate(this.state.vehicle.features?.year, this.state.vehicle.details?.price, (term, rate) => BaseApi.vehicles.saveSource(this.vehicleId, {
                    finance_base: this.state.vehicle.details?.price,
                    finance_term: term,
                    finance_rate: rate,
                    finance_admin: 0
                })(rx => {
                    if (rx.success) {
                        Spikes.notify("pop", {group: "default", icon: "dollar", message: `Vehicle Finance Details has been updated.`});
                    } else {
                        Spikes.notify("pop", {group: "danger", icon: "dollar", message: `Vehicle Finance Details failed to update.`});
                    }
                }));
            }
        },
        siteLivePending: vehicle => {
            let pending = [];
            if (vehicle?.features && vehicle?.details) {
                if (!vehicle.features.year || !vehicle.features.make || !vehicle.features.model) {pending.push("Vehicle Details");}
                if (!vehicle.features.body_type) {pending.push("Body Type");}
                if (!vehicle.details.exterior_color) {pending.push("Exterior Colour");}
                if (!vehicle.details.kilometers) {pending.push("Kilometers");}
                if (!vehicle.details.price) {pending.push("Price");}
                if (!vehicle.images || !vehicle.images.length) {pending.push("Images");}
                if (!vehicle.details.finance_term || !vehicle.details.finance_rate) {pending.push("Financing");}
                if (!vehicle.features.doors) {pending.push("Doors");}
                if (!vehicle.details.certification) {pending.push("Certification");}
            } else {
                pending.push("Vehicle Details");
            }
            return pending.length ? `Required: ${pending.join(", ")}` : false;
        },
        editNote: note => Spikes.notify("VehicleNoteModal", this.vehicleId, note),
    };

    componentDidMount() {
        setTimeout(() => window.scrollTo(0, 0));
        setTimeout(() => Spikes.notify("title", "car", "Vehicle - Loading..."));
        if (this.state.vehicle) {
            this.ref.tag.current.value = this.state.vehicle.tag || "";
        }
        this.wHolder(BaseData.vehicle(this.vehicleId, vehicle => {
            if (vehicle) {
                this.routines.confirmSchema(vehicle);
                this.setVal({
                    vehicle: vehicle,
                    changed: false,
                    required: this.routines.siteLivePending(vehicle)
                }, () => {
                    setTimeout(() => Spikes.notify("title", "car", `Vehicle - ${this.state.vehicle.id}`, `Visible: ${this.state.vehicle.visible ? "True" : "False"} |
                    Views: ${this.state.vehicle.stats?.views || 0} | 
                    Likes: ${this.state.vehicle.liked ? Object.keys(this.state.vehicle.liked).length : 0} |
                    Notes: ${(this.state.vehicle.notes || []).length} |
                    History: ${(this.state.vehicle.history || []).length}`));
                    this.ref.tag.current.value = this.state.vehicle.tag || "";
                    this.routines.resetData();
                });
            } else {
                Spikes.notify("navigate", "/vehicles");
            }
        }));
        this.wHolder(BaseData.profiles(profiles => {
            this.setVal({likedProfiles: profiles.filter(p => p.liked && Object.keys(p.liked).includes(this.vehicleId))});
        }));
    }

    componentWillUnmount() {
        this.setVal("end");
        this.wHolder("end");
    }

    render() {
        let { vehicle } = this.state;
        if (!vehicle) {return <img src="/images/loading_rs2.gif" className="center-screen" alt="Loading..." />;}
        return (
            <div style={{backgroundColor: !this.state.vehicle?.visible ? "var(--color-3)" : "var(--color-6)", paddingBottom: "10px"}}>
                <div className="optionsbar">
                    <GoBack className="solo"><Icon name="backward" />Back</GoBack>
                    <button className="solo" onClick={() => this.routines.sendSmsLink()}><Icon name="send" margin="0" /><Icon name="sms" />Send To SMS</button>
                    <a href={`https://www.carmonk.ca/vehicle?vehicleId=${this.vehicleId}`} target="_new"><button className="solo" disabled={!this.state.vehicle.visible}><Icon name="link" />View On Site</button></a>
                    <span className="right">
                        {/* {!(this.state.changed || this.state.imageChanged) && (vehicle?.source?.vin || vehicle?.details?.vin || vehicle?.dealer?.vin) && <button className="solo" onClick={() => this.routines.getFromDataOne()}><Icon name="fromCloud" />Pull DataOne Data</button>} */}
                        {!(this.state.changed || this.state.imageChanged) && (vehicle?.source?.vin || vehicle?.details?.vin || vehicle?.dealer?.vin) && <button className="solo" onClick={() => this.routines.getFromBlackBook()}><Icon name="book" />Pull BlackBook Data</button>}
                        {!(this.state.changed || this.state.imageChanged) && <button className="solo" onClick={() => Spikes.notify("navigate", `/evoxLibrary?vehicleId=${this.vehicleId}`)}><Icon name="images" />Pull Evox Library</button>}

                        {(this.state.changed || this.state.imageChanged) && <button className="solo" onClick={() => this.routines.saveListing()}><Icon name="save" />Save Changes</button>}
                        {(this.state.changed || this.state.imageChanged) && <button className="solo" onClick={() => this.routines.resetData()}><Icon name="undo" />Undo Changes</button>}
                        {!(this.state.changed || this.state.imageChanged) && !this.state.vehicle.visible && <button className="solo" onClick={() => this.routines.setVisible()}><Icon name="visible" />Set Visible</button>}
                        {!(this.state.changed || this.state.imageChanged) && this.state.vehicle.visible && <button className="solo" onClick={() => this.routines.setVisible()}><Icon name="invisible" />Set Invisible</button>}
                    </span>
                </div>
                <TableView.Info>
                    <div className="h2">
                        {([vehicle.features?.year, vehicle.features?.make, vehicle.features?.model, vehicle.features?.trim]).filter(vf => vf).join(" ") || "Please populate vehicle features"}{vehicle.features?.body_type ? ` (${vehicle.features.body_type})` : ""}
                        {this.state.required && <span style={{float: "right"}}>{this.state.required}</span>}
                    </div>
                </TableView.Info>
                
                <TableView.Header><Icon name="tag" />Tag (Short Description CRM Only)</TableView.Header>
                <TableView.Body><input type="text" style={{display: "block", margin: "0px", width: "100%", boxSizing: "border-box", outline: "none", borderRadius: "10px", borderWidth: "0px"}} ref={this.ref.tag} placeholder="Short Description" onChange={() => this.setVal({changed: true})} /></TableView.Body>

                {vehicle.dealer && <div>
                    <TableView.Header><Icon name="star" />Dealer Info</TableView.Header>
                    <TableView.Body>
                        <TableView.Table>
                            <TableView.Column colSpan="3">
                                <TableView.FieldView title="Vehicle ID" content={vehicle.dealer.dealerId} />
                                <TableView.FieldView title="VIN" content={vehicle.dealer.vin} />
                            </TableView.Column>
                            <TableView.Column colSpan="3">
                                <TableView.FieldView title="Year" content={vehicle.dealer.year} />
                                <TableView.FieldView title="Odometer" content={this.format.number(vehicle.dealer.odometer)} />
                            </TableView.Column>
                            <TableView.Column colSpan="3">
                                <TableView.FieldView title="Name">{vehicle.dealer.name}{vehicle.dealer.body ? ` (${vehicle.dealer.body})` : ""}</TableView.FieldView>
                                <TableView.FieldView title="Colour" content={vehicle.dealer.colour} />
                            </TableView.Column>
                            <TableView.Column colSpan="3">
                                <TableView.FieldView title="Cost" content={this.format.money(vehicle.dealer.cost)} />
                                <TableView.FieldView title="Price" content={this.format.money(vehicle.dealer.price)} />
                            </TableView.Column>
                        </TableView.Table>
                    </TableView.Body>
                </div>}

                <TableView.Header><Icon name="images" />Images</TableView.Header>
                <TableView.Body>
                    <TableView.FieldView title="Used" style={{whiteSpace: "nowrap", overflow: "auto"}}>
                        {(vehicle.images || []).map(a => <img
                            key={a.url}
                            src={a.url}
                            alt={a.filename}
                            title={a.caption}
                            style={{height: "200px", width: "auto", cursor: "pointer"}}
                            onClick={() => this.setVal({preview: a})}
                            onContextMenu={e => {e.preventDefault(); this.routines.removeImage(a);}}
                            draggable="true"
                            onDragStart={e => Spikes.drag.onDrag(e, "usedVehicleImage")}
                            onDragEnd={e => Spikes.drag.onEnd(e)}
                        />)}
                    </TableView.FieldView>
                    <TableView.FieldView title="Available" style={{whiteSpace: "nowrap", overflow: "auto"}}>
                        {(vehicle?.evoxSource || []).filter(a => a && a.type === "image" && !(vehicle?.images || []).filter(l => l.url === a.url).length).map(a => <img
                            key={a.url}
                            src={a.url}
                            alt={a.filename}
                            title={a.caption}
                            style={{height: "100px", width: "auto", cursor: "pointer"}}
                            onClick={() => this.setVal({preview: a})}
                            onContextMenu={e => {e.preventDefault(); this.routines.addImage(a);}}
                        />)}
                    </TableView.FieldView>
                </TableView.Body>

                <TableView.Header><Icon name="heart" />Liked By</TableView.Header>
                <div style={{paddingLeft: "10px", paddingRight: "10px"}}>
                    <table className="wide">
                        <thead>
                            <tr>
                                <th>Phone</th>
                                <th>Likes</th>
                                <th>Date Created</th>
                                <th>Date Modified</th>
                                <th>Date Liked</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.likedProfiles?.map(c => (
                                <tr
                                    className="hover"
                                    key={c.id}
                                    onClick={() => Spikes.notify("navigate", `/customer?profileId=${c.id}`)}
                                >
                                    <td style={{whiteSpace: "nowrap"}}>{Spikes.asPhone(c.id)}</td>
                                    <td style={{whiteSpace: "nowrap"}}>{c.liked ? Object.keys(c.liked).length : ""}</td>
                                    <td style={{whiteSpace: "nowrap"}}>{Spikes.date.lDateTime(c.dateCreated)}</td>
                                    <td style={{whiteSpace: "nowrap"}}>{Spikes.date.lDateTime(c.dateModified)}</td>
                                    <td style={{whiteSpace: "nowrap"}}>{Spikes.date.lDateTime(vehicle.liked[c.id])}</td>
                                    <td style={{whiteSpace: "nowrap"}}>{""}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <TableView.Table>
                    <TableView.Column colSpan="6">
                        <TableView.Header><Icon name="note" />Notes<Icon name="add" className="link" title="Add Note" style={{float: "right"}} onClick={() => this.routines.editNote()} /></TableView.Header>
                        <TableView.Body style={{height: "300px", overflow: "auto"}} ref={this.ref.notes}>{
                            (vehicle.notes || []).map((note, index) => <div key={index} style={{cursor: "pointer"}} onClick={() => this.routines.editNote(note)}>
                                <TableView.FieldView title={Spikes.date.lhDateTime(note.stamp) + " [" + BaseApi.data.usersNameById(note.userId) + "]"} content={note.note.split("\n").map((n, i) => <div key={i}>{n}</div>)} />
                            </div>)
                        }</TableView.Body>
                    </TableView.Column>
                    <TableView.Column colSpan="3">
                        <TableView.Header><Icon name="document" />Documents<Icon name="upload" className="link" title="Upload Document" style={{float: "right"}} onClick={() => this.routines.uploadDoc()} /></TableView.Header>
                        <TableView.Body style={{height: "300px"}}></TableView.Body>
                    </TableView.Column>
                    <TableView.Column colSpan="3">
                        <TableView.Header><Icon name="history" />History</TableView.Header>
                        <TableView.Body style={{height: "300px"}} ref={this.ref.history}>{
                            (vehicle.history || []).map((history, index) => <div key={index}>
                                <TableView.FieldView title={Spikes.date.lDateTime(history.stamp)} content={history?.description?.split("\n").map((l, i) => <div key={i}>{l}</div>)} />
                            </div>)
                        }</TableView.Body>
                    </TableView.Column>
                </TableView.Table>

                <TableView.Header><Icon name="star" />Vehicle Status <Icon name="copy" title="Copy All" style={{float: "right"}} className="link" onClick={() => this.routines.syncSetData("status")} /></TableView.Header>
                <TableView.Body>
                    <TableView.Table>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.money, "Price", "status", "price", "number", () => vehicle, "source", "details"]} />
                            <FieldView2 params={[this.cross, this.format.number, "Kilometers", "status", "kilometers", "number", () => vehicle, "source", "details"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Condition", "status", "condition", "string", () => vehicle, "source", "details"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Condition Rating", "status", "condition_rating", "string", () => vehicle, "source", "details"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Certification", "status", "certification", "select", () => vehicle, "source", "details", this.format.certification]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Warranty", "status", "warranty", "boolean", () => vehicle, "source", "details"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Status", "status", "status", "string", () => vehicle, "source", "details"]} />
                        </TableView.Column>
                    </TableView.Table>
                </TableView.Body>

                <TableView.Header><Icon name="notify" />Vehicle Details <Icon name="copy" title="Copy All" style={{float: "right"}} className="link" onClick={() => this.routines.syncSetData("vDetails")} /></TableView.Header>
                <TableView.Body>
                    <TableView.Table>
                        <TableView.Column colSpan="6">
                            <FieldView2 params={[this.cross, this.format.string, "VIN", "vDetails", "vin", "string", () => vehicle, "source", "details"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Exterior Colour", "vDetails", "exterior_color", "select", () => vehicle, "source", "details", this.format.colour]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Interior Colour", "vDetails", "interior_color", "string", () => vehicle, "source", "details"]} />
                        </TableView.Column>
                    </TableView.Table>
                </TableView.Body>

                {vehicle.features?.year && vehicle.details?.price && <div>
                    <TableView.Header><Icon name="dollar" />Finance Details <Icon name="copy" title="Copy All" style={{float: "right"}} className="link" onClick={() => this.routines.syncSetData("finance")} /></TableView.Header>
                    <TableView.Body>
                        <TableView.Table>
                            <TableView.Column colSpan="12"><div style={{padding: "5px"}}><button style={{cursor: "pointer"}} onClick={() => this.routines.calculateFinance("nb")}><Icon name="calculator" />Calculate - National Bank</button></div></TableView.Column>
                        </TableView.Table>
                        <TableView.Table>
                            <TableView.Column colSpan="3">
                                <FieldView2 params={[this.cross, this.format.number, "Term (Months)", "finance", "finance_term", "number", () => vehicle, "source", "details"]} />
                            </TableView.Column>
                            <TableView.Column colSpan="3">
                                <FieldView2 params={[this.cross, this.format.percent, "Interest Rate", "finance", "finance_rate", "number", () => vehicle, "source", "details"]} />
                            </TableView.Column>
                            <TableView.Column colSpan="3">
                                <FieldView2 params={[this.cross, this.format.money, "Administration Fees", "finance", "finance_admin", "number", () => vehicle, "source", "details"]} />
                            </TableView.Column>
                            <TableView.Column colSpan="3">
                                <TableView.FieldView title={"Biweekly Payments [" + this.format.money(vehicle.source?.finance_biweekly_payment) + "]"} content={this.format.money(
                                    financing.calculateFinancing(
                                        this.updates.details.price || vehicle.details?.price,
                                        this.updates.details.finance_term || vehicle.details.finance_term,
                                        this.updates.details.finance_rate || vehicle.details.finance_rate,
                                        this.updates.details.finance_admin || vehicle.details.finance_admin
                                    )
                                )} />
                            </TableView.Column>
                        </TableView.Table>
                    </TableView.Body>
                </div>}

                <TableView.Header><Icon name="checklist" />Vehicle Features <Icon name="copy" title="Copy All" style={{float: "right"}} className="link" onClick={() => this.routines.syncSetData("vFeatures")} /></TableView.Header>
                <TableView.Body>
                    <TableView.Table>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Year", "vFeatures", "year", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Make", "vFeatures", "make", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Model", "vFeatures", "model", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Trim", "vFeatures", "trim", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Body Type", "vFeatures", "body_type", "select", () => vehicle, "featureSource", "features", this.format.bodyType]} />
                            <FieldView2 params={[this.cross, this.format.string, "Vehicle Type", "vFeatures", "vehicle_type", "select", () => vehicle, "featureSource", "features", this.format.vehicleType]} />
                            <FieldView2 params={[this.cross, this.format.string, "Drive Type", "vFeatures", "drive_type", "select", () => vehicle, "featureSource", "features", this.format.driveType]} />
                            <FieldView2 params={[this.cross, this.format.string, "# Of Doors", "vFeatures", "doors", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Market", "vFeatures", "market", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Country Of Manufacture", "vFeatures", "country_of_manufacture", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Plant", "vFeatures", "plant", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "OnStar", "vFeatures", "OnStar", "boolean", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.boolean, "Air Conditioning", "vFeatures", "air_conditioning", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Power Windows", "vFeatures", "power_windows", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Power Doorlocks", "vFeatures", "power_doorlocks", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Power Mirrors", "vFeatures", "power_mirrors", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Power Trunk Hatch", "vFeatures", "power_trunk_hatch", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Camera (Rearview)", "vFeatures", "camera_rearview", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Hill Hold", "vFeatures", "control_hill_hold", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Stability Control", "vFeatures", "control_stability", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Traction Control", "vFeatures", "control_traction", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Cruise Control", "vFeatures", "cruise_control", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Power Steering", "vFeatures", "power_steering", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Remote Start", "vFeatures", "remote_start", "boolean", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.boolean, "Rear Window Defogger", "vFeatures", "rear_window_defogger", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Tire Pressure Monitoring", "vFeatures", "tire_pressure_monitoring", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Garage Door Opener", "vFeatures", "garage_door_opener", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Anti-Theft Alarm", "vFeatures", "anti_theft_alarm", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Anti-Theft Audio", "vFeatures", "anti_theft_audio", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Anti-Theft Immobilizer", "vFeatures", "anti_theft_immobilizer", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Breaks (ABS)", "vFeatures", "breaks_abs", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Breaks (Front Diameter)", "vFeatures", "breaks_front_diameter", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Breaks (Rear Diameter)", "vFeatures", "breaks_rear_diameter", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Breaks (Front Type)", "vFeatures", "breaks_type_front", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Breaks (Rear Type)", "vFeatures", "breaks_type_rear", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Gauges (External Temperature)", "vFeatures", "external_temperature_display", "boolean", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Seat (Front Type)", "vFeatures", "seat_type_front", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Seat (Rear Type)", "vFeatures", "seat_type_rear", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Seat (Upholstery)", "vFeatures", "seat_upholstery", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Seat (Standard Passengers)", "vFeatures", "seating_standard", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Seat (Max Passengers)", "vFeatures", "seating_max", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Seat (Rows)", "vFeatures", "seating_rows", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Solar Tinted Glass", "vFeatures", "solar_tinted_glass", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Speed Sensitive Wipers", "vFeatures", "speed_sensitive_wipers", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Steering (Mounted Controls)", "vFeatures", "steering_mounted_controls", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Steering (Telescopic Adjustment)", "vFeatures", "steering_telescopic", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Steering (Tilt Adjustment)", "vFeatures", "steering_tilt", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Max Towing Capacity", "vFeatures", "towing_max_capacity", "string", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                    </TableView.Table>
                </TableView.Body>

                <TableView.Header><Icon name="notify" />Engine <Icon name="copy" title="Copy All" style={{float: "right"}} className="link" onClick={() => this.routines.syncSetData("engine")} /></TableView.Header>
                <TableView.Body>
                    <TableView.Table>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Engine (Name)", "engine", "engine_name", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Engine (Block Type)", "engine", "engine_block_type", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Engine (Cylinders)", "engine", "engine_cylinders", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Engine (Displacement)", "engine", "engine_displacement", "number", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Engine (Aspiration)", "engine", "engine_aspiration", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Engine (Brand)", "engine", "engine_brand", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Engine (Cam Type)", "engine", "engine_cam_type", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Engine (Compression)", "engine", "engine_compression", "string", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Engine (Max Horse Power)", "engine", "engine_max_hp", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Engine (Max Horse Power @ RPM)", "engine", "engine_max_hp_at", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Engine (Max Torque)", "engine", "engine_max_torque", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Engine (Max Torque @ RPM)", "engine", "engine_max_torque_at", "number", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Engine (Redline)", "engine", "engine_redline", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Engine (Valve Timing Type)", "engine", "engine_valve_timing", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Engine (# Of Valves)", "engine", "engine_valves", "number", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                    </TableView.Table>
                </TableView.Body>

                <TableView.Header><Icon name="notify" />Transmission <Icon name="copy" title="Copy All" style={{float: "right"}} className="link" onClick={() => this.routines.syncSetData("transmission")} /></TableView.Header>
                <TableView.Body>
                    <TableView.Table>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Transmission (Name)", "transmission", "transmission_name", "string", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Transmission (Gears)", "transmission", "transmission_gears", "number", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Transmission (Type)", "transmission", "transmission_type", "string", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Transmission (Brand)", "transmission", "transmission_brand", "string", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                    </TableView.Table>
                </TableView.Body>

                <TableView.Header><Icon name="notify" />Safety <Icon name="copy" title="Copy All" style={{float: "right"}} className="link" onClick={() => this.routines.syncSetData("safety")} /></TableView.Header>
                <TableView.Body>
                    <TableView.Table>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.boolean, "Airbags", "safety", "airbags", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Airbag Passenger Sensing", "safety", "airbag_passenger_sensing", "boolean", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Airbags Front", "safety", "airbags_front", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Airbags Side", "safety", "airbags_side", "string", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Airbags Front Side Curtain", "safety", "airbags_side_curtain_font", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Airbags Rear Side Curtain", "safety", "airbags_side_curtain_rear", "boolean", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.boolean, "Child Safety Doorlocks", "safety", "child_safety_doorlocks", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Emergency Interior Trunk Release", "safety", "emergency_interior_trunk_release", "boolean", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                    </TableView.Table>
                </TableView.Body>

                <TableView.Header><Icon name="notify" />Fuel <Icon name="copy" title="Copy All" style={{float: "right"}} className="link" onClick={() => this.routines.syncSetData("fuel")} /></TableView.Header>
                <TableView.Body>
                    <TableView.Table>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "EPA Fuel Efficiency (City)", "fuel", "fuel_efficiency_epa_city", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Fuel (Type)", "fuel", "fuel_type", "string", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "EPA Fuel Efficiency (Highway)", "fuel", "fuel_efficiency_epa_highway", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Fuel (Capacity)", "fuel", "fuel_tank_capacity", "number", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "EPA Fuel Efficiency (Combined)", "fuel", "fuel_efficiency_epa_combined", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Fuel (Quality)", "fuel", "fuel_quality", "string", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3"></TableView.Column>
                    </TableView.Table>
                </TableView.Body>

                <TableView.Header><Icon name="notify" />Wheels <Icon name="copy" title="Copy All" style={{float: "right"}} className="link" onClick={() => this.routines.syncSetData("wheels")} /></TableView.Header>
                <TableView.Body>
                    <TableView.Table>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Wheels (Front Diameter)", "wheels", "wheel_front_diameter", "number", () => vehicle, "featureSource", "features"]} />    
                            <FieldView2 params={[this.cross, this.format.string, "Wheels (Front Width)", "wheels", "wheel_front_width", "number", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Wheels (Rear Diameter)", "wheels", "wheel_rear_diameter", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Wheels (Rear Width)", "wheels", "wheel_rear_width", "number", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Tires (Front Size)", "wheels", "tire_size_front", "string", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Tires (Rear Size)", "wheels", "tire_size_rear", "string", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            
                        </TableView.Column>
                    </TableView.Table>
                </TableView.Body>

                <TableView.Header><Icon name="notify" />Dimensions <Icon name="copy" title="Copy All" style={{float: "right"}} className="link" onClick={() => this.routines.syncSetData("dimensions")} /></TableView.Header>
                <TableView.Body>
                    <TableView.Table>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Dimensions (Height)", "dimensions", "vehicle_height", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Dimensions (Wheel Base)", "dimensions", "wheel_base", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Dimensions (Curb Weight)", "dimensions", "weight_curb", "number", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Dimensions (Length)", "dimensions", "vehicle_length", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Track Width (Front)", "dimensions", "track_width_front", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Interior Cargo Volume", "dimensions", "interior_volume_cargo", "number", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Dimensions (Width)", "dimensions", "vehicle_width", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Track Width (Rear)", "dimensions", "track_width_rear", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Interior Passenger Volume", "dimensions", "interior_volume_passenger", "number", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.string, "Ground Clearance", "dimensions", "ground_clearance", "number", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                    </TableView.Table>
                </TableView.Body>

                <TableView.Header><Icon name="volume-up" />Entertainment <Icon name="copy" title="Copy All" style={{float: "right"}} className="link" onClick={() => this.routines.syncSetData("entertainment")} /></TableView.Header>
                <TableView.Body>
                    <TableView.Table>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.boolean, "Radio (Touchscreen)", "entertainment", "radio_touchscreen", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Radio (Touchscreen Size)", "entertainment", "radio_touchscreen_size", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Radio (AM/FM)", "entertainment", "radio_amfm", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Radio (SiriusXM)", "entertainment", "radio_SiriusXM", "boolean", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.boolean, "Radio (CD)", "entertainment", "radio_cd", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Radio (CD With MP3)", "entertainment", "radio_cd_mp3", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Radio (Bluetooth)", "entertainment", "radio_input_bluetooth", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Radio (iPod Support)", "entertainment", "radio_input_ipod", "boolean", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.boolean, "Radio (1/8inch Input)", "entertainment", "radio_input_jack", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Radio (USB)", "entertainment", "radio_input_usb", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.string, "Radio (# Of Speakers)", "entertainment", "radio_speakers_total", "number", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Radio (Subwoofer)", "entertainment", "radio_subwoofer", "boolean", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                        <TableView.Column colSpan="3">
                            <FieldView2 params={[this.cross, this.format.boolean, "Radio (Speed Sense Volume)", "entertainment", "radio_speed_sense_volume", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Android Auto", "entertainment", "integration_android_auto", "boolean", () => vehicle, "featureSource", "features"]} />
                            <FieldView2 params={[this.cross, this.format.boolean, "Apple CarPlay", "entertainment", "integration_apple_carplay", "boolean", () => vehicle, "featureSource", "features"]} />
                        </TableView.Column>
                    </TableView.Table>
                </TableView.Body>
                {this.state.preview && <ModalFrame
                    onClose={() => this.setVal({preview: false})}
                    self={this}
                    width="auto"
                >
                    <span><Icon name="car" /><Icon name="images" />Preview</span>
                    <div style={{width: "600px", maxHeight: "600px"}}>
                        <img
                            src={this.state.preview.url}
                            alt={this.state.filename}
                            title={this.state.preview.caption}
                            style={{margin: "auto", maxHeight: "600px", maxWidth: "600px", width: "auto", height: "auto"}}
                        />
                    </div>
                    <div>
                        {(vehicle?.images || []).filter(a => a.url === this.state.preview.url).length ? (
                            <div style={{textAlign: "center"}}>
                                <button onClick={() => this.routines.moveLeft(this.state.preview)}><Icon name="arrow-left" />Move Left</button>
                                <button onClick={() => this.routines.removeImage(this.state.preview)}><Icon name="unlink" />Remove</button>
                                <button onClick={() => this.routines.moveRight(this.state.preview)}><Icon name="arrow-right" />Move Right</button>
                            </div>
                        ) : (
                            <div style={{textAlign: "center"}}>
                                <button onClick={() => this.routines.addImage(this.state.preview)}><Icon name="link" />Add</button>
                            </div>
                        )}
                    </div>
                    <div style={{whiteSpace: "nowrap", overflow: "auto", textAlign: "center", width: "600px"}}>
                        {(vehicle?.images || []).filter(a => a && a.type === "image").map(a => <img
                            key={a.url}
                            src={a.url}
                            alt={a.filename}
                            title={a.caption}
                            style={{height: "80px", width: "auto", borderStyle: "solid", borderWidth: "1px", borderColor: a.url === this.state.preview.url ? "var(--theme-lt-border-color)" : "#ffffff", cursor: "pointer"}}
                            onClick={() => this.setVal({preview: a})}
                        />)}
                    </div>
                </ModalFrame>}
            </div>
        );
    }
}